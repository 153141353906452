import axios from 'axios';

/**
 * Gets the product quantity available
 * @param {string} storeUrl store url
 * @param {string} productId product id
 */

export const getProductInventory = (storeUrl, productId) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/store/${storeUrl}/product-inventory/${productId}`);
};

/**
 * Gets the product quantity available for cart
 * @param {string} storeUrl store url
 * @param {array} cart cart inventory
 */

export const getProductCartInventory = (storeUrl, cart) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/store/${storeUrl}/cart-inventory`, cart);
};