import React from 'react'
/*import {
  Button,
} from '@material-ui/core'*/

import ProductMediaStyles from './ProductMedia.module.scss';
import LazyImage from '../LazyImage/LazyImage';


const ProductMedia = ({ image, alt }) => {
  const image_src = JSON.stringify({
    "bucket": "wa10x.v2",
    "key": `${image}`,
    "edits": {
      "resize": {
        "width": 1080,
        //"height": 600,
        "fit": "cover"
      },
      "rotate": null,
    }
  })

  const productImage = image ? `${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(image_src).toString('base64')}` : `/10x-product.jpg`;

  return (
    <div className={ProductMediaStyles.postWrapper}>
      <div>
        <figure className={ProductMediaStyles.productImage}>
          <LazyImage image={productImage} alt={alt} />
        </figure>
        {/*<Button
          className={ProductMediaStyles.viewItemsBtn}
          color="default"
          size="small"
          onClick={moreInfo}
        >
          Más información
        </Button> */}
      </div>
    </div>
  )
}

export default ProductMedia
