import React, { useEffect, useState } from "react"
import { Link as RouterLink } from 'react-router-dom'

import SearchDialogStyles from './SearchDialog.module.scss'
import { Close } from 'mdi-material-ui'

import axios from 'axios'

const SearchDialog = ({ close, storeId, image, productSubUrl }) => {
    const [inputValue, setInputValue] = useState('')
    const [options, setOptions] = useState([])
    const [out, setOut] = useState(false)

    const fadeOut = () => {
        setOut(true)
        setTimeout(() => {
            setOut(false)
            close()
        }, 1000);
    }
    useEffect( () => {
        if(inputValue.length >= 3) {
            axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/products/public-search?term=${inputValue}`)
            .then(res => setOptions(res.data))
        }
        if(inputValue.length === 0) {
            setOptions([])
        }
    },[inputValue] )
    console.log(options)
    return(
        <>
            <div className={out ? SearchDialogStyles.bgDialogOut : SearchDialogStyles.bgDialog} />
            <section className={ out ? SearchDialogStyles.sectionDialogOut :  SearchDialogStyles.sectionDialog}>
                <div className={SearchDialogStyles.inputContainer}>
                    <input className={SearchDialogStyles.input} placeholder="Buscar..." onChange={ (e) => setInputValue(e.target.value) }/>
                    <Close style={{ width: '27px', height: '27px' }} className={SearchDialogStyles.closeBtn} onClick={fadeOut} />
                </div>
                <div className={SearchDialogStyles.sectionResults}>
                    { options === null &&
                        <p className={SearchDialogStyles.text}>No hay resultados</p>
                    }
                    { options?.length === 0 
                        ? <img  src={image} className={SearchDialogStyles.imagePreview} alt="logo Image"/> 
                        : options?.map( (opt, index) => (
                            <RouterLink 
                                className={SearchDialogStyles.card} 
                                style={{ backgroundColor: index % 2 === 0 ?  '#f9f9f9' : '#f3f3f3'}}
                                to={`${productSubUrl}/${opt.url}`}
                            >
                                { opt.picture_url 
                                    ? <img src={`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/48x48/${opt.picture_url}`} className={SearchDialogStyles.cardImage} alt={opt.name}/>
                                    : <img  src={`/10x-product.jpg`} className={SearchDialogStyles.cardImage} alt={opt.name}/>
                                }
                               
                                <p className={SearchDialogStyles.text}>{opt.name}</p>
                            </RouterLink>
                        )
                        )
                    }
                </div>
               
            </section>
        </>
    )
}

export default SearchDialog 