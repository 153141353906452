import { getLocalData, setLocalData } from "../utils/constants";

export const SET_CART = "cart/SET_CART";
export const SET_STORE = "cart/SET_STORE";
export const SET_HOME_SCROLL_POSITION = "cart/SET_HOME_SCROLL_POSITION";
export const SET_STORE_LIMIT = "cart/SET_STORE_LIMIT";
export const SET_STORE_RESET = "cart/SET_STORE_RESET";
export const SET_STORE_PRODUCT = "cart/SET_STORE_PRODUCT";
export const SET_STORE_CURRENT_CATEGORY_ID = "cart/SET_STORE_CATEGORY_ID";
export const SET_STORE_TOTAL_PRODUCTS = "cart/SET_STORE_TOTAL_PRODUCTS";

let defaultData = {
  items: [],
  store: {},
};

defaultData = getLocalData();

const initialState = {
  items: defaultData.items,
  store: defaultData.store,
  limit: 0,
  products: [],
  totalProducts: 0,
  currentCategoryId: "",
  homeScrollPosition: 200,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return {
        ...state,
        items: action.payload,
      };

    case SET_STORE:
      return {
        ...state,
        store: action.payload,
      };

    case SET_STORE_LIMIT:
      return {
        ...state,
        limit: state.limit + action.payload,
      };

    case SET_STORE_RESET:
      return {
        ...state,
        limit: 0,
        products: [],
        currentCategoryId: "",
      };

    case SET_STORE_PRODUCT:
      return {
        ...state,
        products: [...state.products, ...action.payload],
      };

    case SET_STORE_CURRENT_CATEGORY_ID:
      return {
        ...state,
        currentCategoryId: action.payload,
      };

    case SET_HOME_SCROLL_POSITION:
      return {
        ...state,
        homeScrollPosition: action.payload,
      };

    case SET_STORE_TOTAL_PRODUCTS:
      return {
        ...state,
        totalProducts: action.payload,
      };

    default:
      return state;
  }
};

const setData = (actionName, payload) => {
  return (dispatch) => {
    dispatch({
      type: actionName,
      payload: payload,
    });
  };
};

export const setCartState = (storeUrl, phoneNumber, cartData, store) => {
  setLocalData(storeUrl, phoneNumber, {
    items: cartData,
    store: store,
  });
  return setData(SET_CART, cartData);
};

export const setStoreState = (storeUrl, phoneNumber, cartData, store) => {
  setLocalData(storeUrl, phoneNumber, {
    items: cartData,
    store: store,
  });
  return setData(SET_STORE, store);
};

export const setStoreLimit = (limit) => {
  return setData(SET_STORE_LIMIT, limit);
};

export const setStoreReset = () => {
  return setData(SET_STORE_RESET);
};

export const setStoreProduct = (payload) => {
  return setData(SET_STORE_PRODUCT, payload);
};

export const setStoreCategoryId = (payload) => {
  return setData(SET_STORE_CURRENT_CATEGORY_ID, payload);
};

export const setHomeScrollPosition = (position) =>
  setData(SET_HOME_SCROLL_POSITION, position);

export const setStoreTotalProducts = (payload) => {
  return setData(SET_STORE_TOTAL_PRODUCTS, payload);
};
