import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Minus = props => {
  return (
    <SvgIcon {...props}>
      <path d="M 22.988281 13.011719 L 1.011719 13.011719 C 0.414062 13.011719 0 12.601562 0 12 C 0 11.398438 0.414062 10.988281 1.011719 10.988281 L 23.023438 10.988281 C 23.625 10.988281 24.039062 11.398438 24.039062 12 C 24 12.601562 23.585938 13.011719 22.988281 13.011719 Z M 22.988281 13.011719 "/>
    </SvgIcon>
  )
}

export default Minus
