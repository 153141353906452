// react dependencies
import React, { forwardRef } from 'react';

// material dependencies
import {
  AppBar,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core';
import {
  WindowClose
} from 'mdi-material-ui';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const StoreTermsAndConditions = ({ open, openHandler, closeHandler, storeToS }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={closeHandler}
      TransitionComponent={Transition}
    >
      <AppBar color="inherit">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeHandler}
            aria-label="cerrar"
          >
            <WindowClose />
          </IconButton>
          <Typography variant="h6">
            Políticas de tratamiento de la información
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container style={{ paddingTop: 40 }}>
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: storeToS }}></Typography>
      </Container>
    </Dialog>
  )
}

export default StoreTermsAndConditions
