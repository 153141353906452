/* eslint-disable import/first */
// react dependencies
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';

// external dependencies
import { useSnackbar } from 'notistack';
import difference from 'lodash/difference';
import { hotjar } from 'react-hotjar';
import axios from 'axios';
import classNames from 'classnames/bind';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel';

//Google Analitycs
import ReactGA from 'react-ga';

import { countries } from '../../utils/constants';

// material dependencies
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

// assets
import ChevronLeft from '../Icons/ChevronLeft';
import Minus from '../Icons/Minus';
import Plus from '../Icons/Plus';
import ShoppingBag from '../Icons/ShoppingBag';
import ShoppingBagOutline from '../Icons/ShoppingBagOutline';
import Trash from '../Icons/Trash';
import Whatsapp from '../Icons/Whatsapp';
import CreditCard from '../Icons/CreditCard'

// styles
import CartStyles from './Cart.module.scss';
import SendOrderStyles from '../SendOrder/SendOrder.module.scss'

// state
import { setCartState } from '../../modules/main';
import { cleanShoppingCart, formatNumber } from '../../utils/constants';
import Axios from 'axios';
import { useQuery } from '@apollo/react-hooks';
import { PRODUCT_BY_SLUG_AND_PHONE_QUERY } from '../../qgl-queries/product';
import { getProductCartInventory, getProductInventory } from './../../services/inventory';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
}

/**
 * 
 * @param {Array} items cart items from redux
 * @param {Object} store store data
 * @param {Function} setCartState function to save the cart in localStorage
 */
const Cart = ({ items, store, setCartState }) => {
  const history = useHistory();
  const { /* storeUrl, */ phoneNumber } = useParams();
  const storeUrl = window.location.hostname.split(".")[0]
  const { enqueueSnackbar } = useSnackbar();

  // state variables
  const [beforeSendOrderLoading, setBeforeSendOrderLoading] = useState(false);
  const [newOptionQuantity, setNewOptionQuantity] = useState(1);
  const [showMinimumCartValue, setShowMinimumCartValue] = useState(false)
  const [open, setOpen] = useState(false)
  const [focusInputState, setFocusInputState] = useState(false)
  const [code, setCode] = useState('57')
  const [name, setName] = useState('')
  const [phoneClient, setPhoneClient] = useState('')
  const [errorPopUp, setErrorPopUp] = useState(false)
  const [symbolsArr] = useState(["e", "E", "+", "-", "."]);

  // extendedItems is a copy of the items array
  const [extendedItems, setExtendedItems] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([])
  const [isWhatsApp, setIsWhatsApp] = useState([])

  const userStore = store?.userStores?.find(uStore => uStore.mobilePhoneNumber === phoneNumber)
  const onlinePaymentMethods = paymentMethods?.filter( method => method.payment_channel_id !== '7' )
 
  

  const minimumCartValueRule = store.wholesaleRules.find(rule => rule.wholesaleRuleType.id == 1);
  const minimumCartValue = minimumCartValueRule ? minimumCartValueRule.minimumCartValue : -1;

  // ref for the new option form
  const formRef = useRef(null);

  /**
   * redirects to the store page
   */
  const nameCollection = history.location.search.slice(5)
  const goBack = () => history.push(`/${phoneNumber}/collections/${nameCollection ? nameCollection : ''}`);

  /**
   * clean shopping cart
   */
  const deleteAllCart = () => {
    cleanShoppingCart()
    setExtendedItems([])
  };

  /**
   * Shows/Hides the more options form for a product
   * @param {boolean} visible whether or not to show the new option form
   * @param {number} index item index
   */
  const toggleItemOptionsVisibility = (visible, index) => {
    setExtendedItems(extendedItems.map((item, itemIndex) => ({
      ...item,
      showMoreOptions: index === itemIndex ? visible : item.showMoreOptions
    })))
  }

  /**
   * updates the amount of a product
   * @param {number} index product index
   * @param {boolean} add increase/decrease amount
   */
  const updateProductAmount = (index, add = true) => {
    setExtendedItems(extendedItems.map((item, itemIndex) => {
      const productListDiscountPrice = calculateDiscount({
        ...item,
        amount: add ? item.amount + 1 : item.amount - 1
      });

      if (index === itemIndex) {
        return {
          ...item,
          amount: add ? item.amount + 1 : item.amount - 1,
          productListDiscountPrice: productListDiscountPrice ? productListDiscountPrice : undefined
        }
      }

      return { ...item }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  /**
   * Removes a product from the cart
   * @param {number} index product index
   */
  const deleteCartItem = (index) => setExtendedItems(
    extendedItems.filter((itemExtra, itemIndex) => itemIndex !== index)
  );

  /**
   * Sets the amount for the new variant to add inside the more options form
   * @param {boolean} add increase/decrease amount
   */
  const updateNewItemQuantity = add => {
    const newQuantity = add ? newOptionQuantity + 1 : newOptionQuantity - 1;
    if (newQuantity >= 1) setNewOptionQuantity(newQuantity);
  }

  /**
   * Adds a new variant to a product
   * @param {event} e form event
   * @param {object} item product data object 
   * @param {number} index product index 
   */
  const addNewVariant = (e, item, index) => {
    e.preventDefault(); // prevent for submition
    // selects inside the form are uncontrolled, get the values
    const selects = Array.from(formRef.current.querySelectorAll('select'));
    // force the user to set a value for all selects inside the form
    if (selects.filter(select => select.value).length !== selects.length) {

      return enqueueSnackbar('Selecciona todas las opciones', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }
    // find selected variant
    const selectedVariant = item.variants.find(variant => {

      return difference(selects.map(s => s.value), variant.optionValues.map(value => value.id)).length === 0;
    });

    // push new variant to the extraVariants array 
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === index) {

        // check if variant is the one the user selected on the product page
        const isInitialVariant = extendedItem.amount > 0 &&
          JSON.stringify(extendedItem.productOptions.map(o => o.selected)) ===
          JSON.stringify(selects.map(s => s.value));

        // check if variant is already pushed
        const alreadyPushedIndex = extendedItem.extraVariants.findIndex(extraVariant => {
          return JSON.stringify(selects.map(s => s.value)) === JSON.stringify(extraVariant.options);
        });

        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, extraVariantIndex) => {
          if (alreadyPushedIndex === extraVariantIndex) {
            return {
              ...extraVariant,
              amount: extraVariant.amount + newOptionQuantity,
              active: 1
            }
          }

          return { ...extraVariant }
        });

        const extraVariantsWithNewItem = [...extendedItem.extraVariants, {
          sku: selectedVariant ? selectedVariant.sku || extendedItem.sku : extendedItem.sku,
          variantId: selectedVariant ? selectedVariant.id : '',
          price: selectedVariant ? item.productListDiscountPrice || selectedVariant.discountPrice || selectedVariant.price || item.discountPrice || item.originalPrice : item.productListDiscountPrice || item.discountPrice || item.originalPrice,
          amount: newOptionQuantity,
          active: 1,
          options: selects.map(s => s.value)
        }];

        const newVariants = isInitialVariant ? extendedItem.extraVariants : (alreadyPushedIndex >= 0 ? updatedExtraVariants : extraVariantsWithNewItem);
        const variantsPrice = newVariants.reduce((prev, current) => prev + (current.price * current.amount), 0);

        return {
          ...extendedItem,
          amount: isInitialVariant ? extendedItem.amount + newOptionQuantity : extendedItem.amount,
          extraVariants: newVariants,
          variantsPrice,
          showMoreOptions: false,
          active: 1,
        }
      }
      return {
        ...extendedItem
      }
    }));
  }

  /**
   * Updates the amount of an extra variant
   * @param {number} productIndex product index
   * @param {number} extraVariantIndex extra variant index
   * @param {boolean} add increase/decrease extra variant amount
   */
  const updateExtraVariantAmount = (productIndex, extraVariantIndex, add) => {
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === productIndex) {
        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, variantIndex) => {
          if (variantIndex === extraVariantIndex) {

            return {
              ...extraVariant,
              amount: add ? extraVariant.amount + 1 : extraVariant.amount - 1
            }
          }
          return { ...extraVariant }
        }).filter(extraVariant => extraVariant.amount > 0);

        const variantsPrice = updatedExtraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0);

        return {
          ...extendedItem,
          extraVariants: updatedExtraVariants,
          variantsPrice
        }
      }

      return {
        ...extendedItem
      }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  const arrayOfVariants = () => {
    let variants = [];
    let totalVariants = [];
    extendedItems.forEach(item => {
      const { amount, extraVariants, variantId, id } = item;

      const selectedId = variantId !== null ? { id: variantId, quantity: amount, is_variant: true } : { id, quantity: amount, is_variant: false };

      variants = extraVariants.length > 0
        ? [selectedId, ...extraVariants?.map(extV => ({ id: extV.variantId, quantity: extV.amount, is_variant: true }))]
        : [selectedId];

      totalVariants = [...totalVariants, ...variants];
      return variants
    });

    return totalVariants;
  }

  const getNameVariant = (idV, isVariant) => {
    let variantsArr = [];
    let nameVariant = '';
    let names = null;

    extendedItems.forEach(item => {
      const { variants, variantId, id, name } = item;
      isVariant ? variantsArr = variants : variantsArr = [{ id, name }];

      if (isVariant) {
        variantsArr.forEach(variant => {
          const { optionValues, id } = variant;
          if (id === idV) {
            nameVariant = optionValues.map(value => value.name).join(' ');
            names = { nameProduct: name, nameVariant }
          }
        });
      } else {
        nameVariant = variantsArr[0].name;
        names = { nameProduct: name, nameVariant }
      }
    });

    return names || { nameProduct: '', nameVariant: '' };
  }

  const goToSendOrder = () => {
    if (extendedItems.length === 0) {
      return enqueueSnackbar('Agrega productos al carrito', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }

    const variantsToCheck = arrayOfVariants()
      getProductCartInventory(store.url, variantsToCheck)
      .then(res =>  history.push(`/${phoneNumber}/order/create`))
      .catch(err => {
        if (err.response.status === 400) {
          // Handle 400 error
          if (err.response.data.length > 0) {
            const data = err.response.data;
            data.forEach(product => {
              const { id, quantity, is_valid, is_variant } = product;
              if (!is_valid) {
                const namesProduct = getNameVariant(id, is_variant);
                const message = is_variant
                  ? `Lo sentimos, no tenemos disponible la cantidad de la variación ${namesProduct?.nameVariant} del producto ${namesProduct?.nameProduct}`
                  : `Lo sentimos, no tenemos disponible la cantidad del producto ${namesProduct?.nameProduct}`;
                return enqueueSnackbar(message, {
                  variant: 'error',
                  autoHideDuration: 3000
                });
              }
            });
          }
        }
      });
      ReactPixel.track('Lead', {
        content_name: 'terminando carrito',
        value: totalForProps,
        currency: store.currency,
      });
      ReactPixel.track('CompleteRegistration')
    //  const totalOrder = extendedItems.reduce((prev, current) => prev + (current.price * current.amount) + current.variantsPrice, 0);
    // const totalTooLow = totalOrder < minimumCartValue;
    // setShowMinimumCartValue(totalTooLow);
    // if (!totalTooLow) history.push(`/${phoneNumber}/order/create`); 
  }
  
  const calculateDiscount = product => {
    const volumeDiscountRule = store.wholesaleRules.find(rule => rule.wholesaleRuleType.id == 2);
    let productListDiscountPrice;

    if (volumeDiscountRule) {
      const productRules = volumeDiscountRule.wholesaleRuleProducts.filter(rule => rule.product.id === product.id);
      if (productRules) {
        // check if the rule is fulfilled
        const totalVariantsAmount = product.extraVariants.reduce((prev, current) => prev + current.amount, 0);
        const totalProductAmount = product.amount + totalVariantsAmount;
        const totalAmount = totalVariantsAmount + totalProductAmount;
        const totalPrice = product.originalPrice * totalAmount;

        const compare = (a, b) => {
          if (a.min < b.min) return -1
          if (a.min > b.min) return 1
          return 0;
        }

        // sort product rules
        const sortedRules = productRules.map(rule => ({ ...rule })).sort(compare);

        sortedRules.forEach(rule => {
          if (rule.type === "Units") {
            if (totalAmount >= rule.min) {
              productListDiscountPrice = rule.percentage ? product.originalPrice - (product.originalPrice * rule.value / 100) : rule.value;
            }
          }

          if (rule.type === "Total") {
            if (totalPrice >= rule.min) {
              productListDiscountPrice = rule.percentage ? product.originalPrice - (product.originalPrice * rule.value / 100) : rule.value;
            }
          }
        })

      }
    }

    return productListDiscountPrice;
  }

  const handleChange = (e, index) => {
    e.preventDefault()
    setExtendedItems(extendedItems.map((item, itemIndex) => {
      const productListDiscountPrice = calculateDiscount({
        ...item,
        amount: Number(e.target.value)
      });
      if (index === itemIndex) {
        return {
          ...item,
          amount: Number(e.target.value),
          productListDiscountPrice: productListDiscountPrice ? productListDiscountPrice : undefined
        }
      }

      return { ...item }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  const handleChangeExtraOptions = (e, productIndex, extraVariantIndex) => {
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === productIndex) {
        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, variantIndex) => {
          if (variantIndex === extraVariantIndex) {

            return {
              ...extraVariant,
              amount: Number(e.target.value),
            }
          }
          return { ...extraVariant }
        }).filter(extraVariant => extraVariant.amount > 0);

        const variantsPrice = updatedExtraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0);

        return {
          ...extendedItem,
          extraVariants: updatedExtraVariants,
          variantsPrice
        }
      }

      return {
        ...extendedItem
      }
    })
      .filter(item => item.amount > 0 || item.extraVariants.length > 0));
  }

  const validateItem = (index, add = true) => {
    setExtendedItems(extendedItems.map((item, itemIndex) => {
      if (index === itemIndex) {
        return {
          ...item,
          active: add ? item.active + 1 : item.active - 1,
        }
      }
      return { ...item }
    }))
  }

  const validateExtraVariants = (productIndex, extraVariantIndex, add) => {
    setExtendedItems(extendedItems.map((extendedItem, extendedItemIndex) => {
      if (extendedItemIndex === productIndex) {
        const updatedExtraVariants = extendedItem.extraVariants.map((extraVariant, variantIndex) => {
          if (variantIndex === extraVariantIndex) {
            return {
              ...extraVariant,
              active: add ? extraVariant.active + 1 : extraVariant.active - 1
            }
          }
          return { ...extraVariant }
        })
        return {
          ...extendedItem,
          extraVariants: updatedExtraVariants,
        }
      }
      return { ...extendedItem }
    }))
  }

  const totalWithShipping = () => {
    return (isNaN(store.deliveryCostDetails) || !store.deliveryCostEnabled) ? 0 : parseInt(store.deliveryCostDetails);
  }
  const totalForProps = formatNumber(extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0) + totalWithShipping(), store.currency)
  
    // Online payment
  const handleDialog = () => {
    const variantsToCheck = arrayOfVariants()
    getProductCartInventory(store.url, variantsToCheck)
      .then(res => {
        setOpen(prev => !prev)
        if (!open) {
          setName('')
          setPhoneClient('')
        }
      }).catch(err => {
        if (err.response.status === 400) {
          // Handle 400 error
          if (err.response.data.length > 0) {
            const data = err.response.data;
            data.forEach(product => {
              const { id, quantity, is_valid, is_variant } = product
              if (!is_valid) {
                const namesProduct = getNameVariant(id, is_variant);
                const message = is_variant
                  ? `Lo sentimos, no tenemos disponible la cantidad de la variación ${namesProduct?.nameVariant} del producto ${namesProduct?.nameProduct}`
                  : `Lo sentimos, no tenemos disponible la cantidad del producto ${namesProduct?.nameProduct}`;
                return enqueueSnackbar(message, {
                  variant: 'error',
                  autoHideDuration: 3000
                });
              }
            });
          }
        }
      })
  }

  const GoToCheckout = () => {
    if (name.length > 2 || phoneClient.length > 5) {
      axios.post(`${process.env.REACT_APP_API_URL}/wa10x/${store.id}/${phoneNumber}/order`, {
      order_details: extendedItems.map(item => ({
          product_id: item.id,
          price: item.productListDiscountPrice || item.price,
          quantity: item.amount,
          options: item.productOptions.map(option => +option.selected),
          ...item.variantId && { product_variant_id: +item.variantId }
        })),
        comments: 'Carrito abandonado',//TODO: validations
        customer: {
          first_name: name.split(' ').shift(),
          last_name: name.split(' ').pop(),
          phone_number: `${parseInt(code)}${phoneClient}`,
        },
        user_store_id: +userStore.id,
        direct_sale: userStore.directSaleEnabled,
        order_method_id: 2
      })
      .then((orderRes) => {
        localStorage.setItem('Step', 1)
        window.location.href = `/checkout/${store.id}/${orderRes.data.id}`
        localStorage.setItem('StepData', JSON.stringify({
          name: name,
          code: code,
          phone: phoneClient,
          comments: '',
        }))
        ReactPixel.track('Lead', {
          content_name: 'pago online',
          value: totalForProps,
          currency: store.currency,
        })
        ReactPixel.track('CompleteRegistration')
      })
    }else{
      setErrorPopUp(true)
      setTimeout(() => {
        setErrorPopUp(false)
      }, 2000);
    }
  }

  const handleCodeChange = newValue => setCode(newValue)
  const toggleFocusInput = state => setFocusInputState(state);
  const whatsAppFieldClass = classNames({
    [SendOrderStyles.numberField]: true,
    [SendOrderStyles.numberFieldFocus]: focusInputState
  })
  /* EFFECTS HERE ⬇️ */

  useEffect(() => {
    setCartState(storeUrl, phoneNumber, extendedItems, store);
    const totalOrder = extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0);
    const totalTooLow = totalOrder < minimumCartValue;
    setShowMinimumCartValue(totalTooLow);
    localStorage.setItem('totalForProps', totalForProps)
  }, [extendedItems])

  useEffect(() => setExtendedItems(items.map(item => {
    localStorage.removeItem("StepData")
    const productListDiscountPrice = calculateDiscount(item);
    let variantsPrice = item.extraVariants.reduce((prev, current) => prev + (current.price * current.amount), 0)

    return {
      ...item,
      variantsPrice: variantsPrice,
      price: item.price,
      ...productListDiscountPrice && {
        productListDiscountPrice
      }
    }
  })), []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store?.id}/payment-channels-store`)
      .then((response) => {
        setPaymentMethods(response?.data)
        setIsWhatsApp(response?.data.filter(item => item?.payment_channel?.name === 'Whatsapp'))
      })
      .catch((error) => {
        console.log(error);
      })
  }, [store])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1702922, 6);
      /* Google Analytics */
      if (store?.googleAnalitycsTrackingId) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId
          },
          {
            trackingId: 'UA-163783687-1'
          }
        ]);
      }
      else {
        ReactGA.initialize([
          {
            trackingId: 'UA-163783687-1'
          }
        ])
      }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      // store?.googleAnalitycsTrackingId && ReactGA.initialize(store?.googleAnalitycsTrackingId);
      // store?.googleAnalitycsTrackingId && ReactGA.send(window.location.pathname + window.location.search);

      /* Facebook Pixel */
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.pageView()
      ReactPixel.track('AddToCart', {
        content_name: 'iniciando carrito',
        value: totalForProps,
        currency: store.currency,
      })
    }
  }, [])
  
  /* EFFECTS HERE ⬆️ */
  const base64image = (image) => {
    const image_src = JSON.stringify({
      "bucket": "wa10x.v2",
      "key": `${image.split('/')[5]}`,
      "edits": {
        "resize": {
          "width": 96,
          "height": 96,
          "fit": "cover"
        },
        "rotate": null,
      }
    })
    return image_src;
  }
 
  const base64image2 = (image) => {
    const image_src = JSON.stringify({
      "bucket": "wa10x.v2",
      "key": `${image.split('/')[5]}`,
      "edits": {
        "resize": {
          "width": 192,
          "height": 192,
          "fit": "cover"
        },
        "rotate": null,
      }
    })
    return image_src;
  }

  const base64image3 = (image) => {
    const image_src = JSON.stringify({
      "bucket": "wa10x.v2",
      "key": `${image.split('/')[5]}`,
      "edits": {
        "resize": {
          "width": 288,
          "height": 288,
          "fit": "cover"
        },
        "rotate": null,
      }
    })
    return image_src;
  }

  return (
    <>
      <Container
        className={CartStyles.cartContainer}
        maxWidth="sm"
        disableGutters
      >
        <header className={CartStyles.topHeader} translate='no'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IconButton
              onClick={goBack}
              color="inherit"
              aria-label="regresar">
              <ChevronLeft fontSize="small" />
            </IconButton>

            {extendedItems.length !== 0 && <Button
              style={{ border: '1px solid #0f2930' }}
              onClick={deleteAllCart}
              size="small"
            >
              <Trash fontSize="small" style={{ marginRight: '2px' }} /> Limpiar carrito
            </Button>}
          </div>
          <Typography
            className={CartStyles.topHeaderTitle}
            variant="h3"
            align="center"
            display="block">
            Resumen de tu compra
          </Typography>

        </header>
        <Container>
          <div className={CartStyles.productList} translate='no'>
            {extendedItems.length === 0 && (
              <div className={CartStyles.cartEmpty}>
                <ShoppingBagOutline className={CartStyles.cartEmptyIcon} />
                <Typography variant="body1">
                  {`El carrito está vacío`}
                </Typography>
              </div>
            )}

            {extendedItems.map((item, index) => (
              <Card
                key={item.id + index}
                className={CartStyles.card}>
                <CardContent className={CartStyles.cardContent}>
                  <Grid
                    className={CartStyles.productContainer}
                    container
                    wrap="nowrap"
                    spacing={1}>
                    <div style={{ display: 'flex', padding: '16px' }}>
                      <div className={CartStyles.productImage}>
                        {item?.image &&
                          <img
                            src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item.image)).toString('base64')}`}
                            srcSet={(`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item.image)).toString('base64')} 1x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image2(item.image)).toString('base64')} 2x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image3(item.image)).toString('base64')} 3x`)}
                            alt={item.name}
                          />
                        }
                      </div>
                      <div className={CartStyles.productDetailsWrapper}>
                        <div className={CartStyles.productDetails}>
                          <Typography
                            color="textSecondary"
                            gutterBottom
                          >
                            {item.name}
                          </Typography>
                        </div>
                        <IconButton
                          onClick={() => deleteCartItem(index)}
                          className={CartStyles.removeProduct}
                          size="small"
                          aria-label="remove">
                          <Trash fontSize="small" />
                        </IconButton>
                      </div>
                    </div>

                    <div className={CartStyles.productOptionsContainer}>
                      <div
                        className={`${CartStyles.option}`}
                        style={{ justifyContent: 'flex-end', padding: '0 36px' }}>
                        <Typography
                          className={CartStyles.optionTitle}
                          variant="body2">
                          Cantidad
                        </Typography>
                      </div>
                    </div>
                    
                    <div className={CartStyles.optionsContainer}>
                      {item.amount > 0 &&
                        <div
                          style={{
                            justifyContent: item.productOptions.length > 0 ? 'space-between' : 'flex-end',
                            marginBottom: item.extraVariants.length > 0 ? 0 : '16px'
                          }}
                          className={CartStyles.defaultOption}>
                          <div>
                            <span>
                              {item.productOptions.map(productOption => <span className={CartStyles.variantOption}>
                                <div className={CartStyles.nameAndVariant}>
                                  {productOption.name} &nbsp;
                                  <b>{productOption.productOptionValues
                                    .find(option => option.id === productOption.selected)
                                    .name
                                  }</b>
                                </div>
                              </span>)}
                            </span>
                            <br />
                            <br />
                          </div>
                          {/* {renderVariants(item)} */}
                          <div>
                            <div
                              className={CartStyles.optionButtonGroup}
                              data-tooltip={item.active > 0 ? "Puedes escribir la cantidad que deseas aquí" : "¿Deseas eliminar el producto?"}
                            >
                              <span className={CartStyles.priceSpan}>
                                {item.originalPrice > 0 && formatNumber(item.productListDiscountPrice * item.amount || item.price * item.amount, store.currency)}
                              </span>
                              {item.active === 0 ?
                                <div className={CartStyles.removeRowContainer}>
                                  <button
                                    onClick={() => validateItem(index, true, item.id)}
                                    className={CartStyles.CancelRemoveRowBtn}
                                    size="small"
                                    aria-label="remove">
                                    <Typography style={{ fontSize: '14px', fontWeight: '700' }}>Cancelar</Typography>
                                  </button>
                                  <button
                                    className={CartStyles.RemoveRowBtn}
                                    onClick={() => updateProductAmount(index, false, item.id)}
                                  >
                                    <Trash fontSize="small" />
                                    <Typography style={{ fontSize: '14px', fontWeight: '700', marginLeft: '5px' }}>Eliminar</Typography>
                                  </button>
                                </div> :
                                <>
                                  <IconButton
                                    className={CartStyles.optionButtonIcon}
                                    onClick={() => {
                                      if (item.amount > 1) {
                                        updateProductAmount(index, false, item.id)
                                      } else {
                                        validateItem(index, false, item.id)
                                      }
                                    }}
                                    variant="outlined"
                                    edge="start"
                                    size="small"
                                    aria-label="Disminuir una unidad">
                                    <Minus className={CartStyles.optionButtonIconSvg} />
                                  </IconButton>
                                  <input
                                    className={CartStyles.inputAmount}
                                    onChange={(e) => {
                                      if (e.target.value == 0 || e.target.value == 'e') {
                                        e.target.value = 1
                                      } else {
                                        e.target.value = e.target.value
                                      }
                                      handleChange(e, index)
                                    }}
                                    type="text"
                                    placeholder={item.amount}
                                    value={item.amount}
                                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                  />
                                  <IconButton
                                    className={CartStyles.optionButtonIcon}
                                    onClick={() => updateProductAmount(index, true, item.id)}
                                    variant="outlined"
                                    edge="start"
                                    size="small"
                                    aria-label="Aumentar una unidad">
                                    <Plus className={CartStyles.optionButtonIconSvg} />
                                  </IconButton>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      }

                      {item.extraVariants.map((extraOption, extraOptionIndex) => (
                        (
                          <div style={{ width: '100%' }}>
                            <div className={CartStyles.variant}>
                              <div>
                                <span>
                                  {item.productOptions.map((productOption, productOptionIndex) =>
                                    <span className={CartStyles.variantOption}>
                                      <div className={CartStyles.nameAndVariant}>
                                        {productOption.name}
                                        <b> {productOption.productOptionValues
                                          .find(option => option.id === extraOption.options[productOptionIndex])
                                          .name
                                        }</b>
                                      </div>
                                    </span>)}
                                </span>
                                <br />
                                <br />
                              </div>
                              <div>
                                <div
                                  className={CartStyles.optionButtonGroup}
                                  data-tooltip={extraOption.active > 0 ? "Puedes escribir la cantidad que deseas aquí" : "¿Deseas eliminar el producto?"}
                                >
                                  <span className={CartStyles.priceSpan}>
                                    {formatNumber(extraOption.price * extraOption.amount)}
                                  </span>
                                  {extraOption.active === 0 ?
                                    <div className={CartStyles.removeRowContainer}>
                                      <button
                                        onClick={() => validateExtraVariants(index, extraOptionIndex, true)}
                                        className={CartStyles.CancelRemoveRowBtn}
                                        size="small"
                                        aria-label="remove">
                                        <Typography style={{ fontSize: '14px', fontWeight: '700' }}>Cancelar</Typography>
                                      </button>
                                      <button
                                        className={CartStyles.RemoveRowBtn}
                                        onClick={() => updateExtraVariantAmount(index, extraOptionIndex, false)}
                                      >
                                        <Trash fontSize="small" />
                                        <Typography style={{ fontSize: '14px', fontWeight: '700', marginLeft: '5px' }}>Eliminar</Typography>
                                      </button>
                                    </div>
                                    :
                                    <>
                                      <IconButton
                                        className={CartStyles.optionButtonIcon}
                                        onClick={() => {
                                          if (extraOption.amount > 1) {
                                            updateExtraVariantAmount(index, extraOptionIndex, false)
                                          } else {
                                            validateExtraVariants(index, extraOptionIndex, false)
                                          }
                                        }}
                                        variant="outlined"
                                        edge="start"
                                        size="small"
                                        aria-label="Disminuir una unidad">
                                        <Minus className={CartStyles.optionButtonIconSvg} />
                                      </IconButton>
                                      <input
                                        className={CartStyles.inputAmount}
                                        onChange={(e) => {
                                          if (e.target.value == 0) {
                                            e.target.value = 1
                                          } else {
                                            e.target.value = e.target.value
                                          }
                                          handleChangeExtraOptions(e, index, extraOptionIndex)
                                        }}
                                        type="number"
                                        placeholder={extraOption.amount}
                                        value={extraOption.amount}
                                        onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}
                                      />
                                      <IconButton
                                        className={CartStyles.optionButtonIcon}
                                        onClick={() => updateExtraVariantAmount(index, extraOptionIndex, true)}
                                        variant="outlined"
                                        edge="start"
                                        size="small"
                                        aria-label="Aumentar una unidad">
                                        <Plus className={CartStyles.optionButtonIconSvg} />
                                      </IconButton>
                                    </>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ))}

                      {(item.productOptions.length > 0 && !item.showMoreOptions) && (
                        <Button
                          className={CartStyles.addVariationsButton}
                          onClick={() => toggleItemOptionsVisibility(true, index)}
                          color="primary"
                          size="small"
                          variant="outlined">
                          <Plus className={CartStyles.addVariationsButtonIcon} />
                          <Typography
                            className={CartStyles.addVariationsButtonCaption}
                            variant="button">
                            Añadir más opciones
                          </Typography>
                        </Button>
                      )}

                      {item.showMoreOptions &&
                        <form
                          ref={formRef}
                          className={CartStyles.moreOptionsContainer}
                          onSubmit={e => addNewVariant(e, item, index)}>
                          <div className={CartStyles.CurrentOptions}>
                            {item.productOptions.map(productOption =>
                              <select className={CartStyles.optionsSelect}>
                                <option value="">{productOption.name}</option>
                                {productOption.productOptionValues.map(poValue =>
                                  <option value={poValue.id}>{poValue.name}</option>
                                )}
                              </select>
                            )}
                            <div className={CartStyles.optionButtonGroup}>
                              <IconButton
                                className={CartStyles.optionButtonIcon}
                                onClick={() => updateNewItemQuantity(false)}
                                variant="outlined"
                                edge="start"
                                size="small"
                                aria-label="Disminuir una unidad">
                                <Minus className={CartStyles.optionButtonIconSvg} />
                              </IconButton>
                              <Typography
                                className={CartStyles.optionQuantity}
                                variant="body2"
                                color={`${(newOptionQuantity > 0 ? 'initial' : 'textSecondary')}`}>
                                {newOptionQuantity}
                              </Typography>
                              <IconButton
                                className={CartStyles.optionButtonIcon}
                                onClick={() => updateNewItemQuantity(true)}
                                variant="outlined"
                                edge="start"
                                size="small"
                                aria-label="Aumentar una unidad">
                                <Plus className={CartStyles.optionButtonIconSvg} />
                              </IconButton>
                            </div>
                          </div>
                          <div className={CartStyles.moreOptionsButtons}>
                            <Button
                              style={{ marginRight: '.5rem' }}
                              type="button"
                              size="small"
                              onClick={() => toggleItemOptionsVisibility(false, index)}>Cancelar</Button>
                            <Button
                              type="submit"
                              color="primary"
                              size="small"
                              variant="outlined">Añadir opcion</Button>
                          </div>
                        </form>
                      }
                    </div>
                    <span className={CartStyles.subTotalProduct}>
                      <Typography
                        variant="body2"
                        color="textSecondary">
                        <p>SubTotal producto:</p>
                        {item.originalPrice > 0 && formatNumber(((item.productListDiscountPrice || item.price) * item.amount) + item.variantsPrice, store.currency)}
                      </Typography>
                    </span>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </div>

          <Button
            style={{ border: '1px solid #48ac98' }}
            className={CartStyles.addMoreProductsBtn}
            onClick={goBack}
            size="small"
            color="primary"
            fullWidth>
            Añadir más productos
          </Button>

          <Card className={CartStyles.card} style={{ padding: '16px' }}>
            <CardContent className={CartStyles.cardContent}>
              {extendedItems.reduce((prev, current) => current.originalPrice + prev, 0) > 0 &&
                <>
                  <Typography color="textSecondary" gutterBottom>
                    SubTotal
                  </Typography>
                  <Typography>
                    {formatNumber(extendedItems.reduce((prev, current) => prev + ((current.productListDiscountPrice || current.price) * current.amount) + current.variantsPrice, 0), store.currency)}
                  </Typography>
                </>
              }
              {store.deliveryCostEnabled && (
                <Typography color="textSecondary">
                  Envío: {store.deliveryCostDetails}
                </Typography>
              )}
              {store.shippingTimeEnabled && (
                <Grid
                  container
                  justify="space-between">
                  <Grid item>
                    <Typography color="textSecondary">
                      Entrega estimada
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {store.shippingDetails}
                    </Typography>
                  </Grid>
                </Grid>)}
              <Grid>
                <Typography variant="h4" component="p" translate='no'>
                  TOTAL:&nbsp;
                  {totalForProps}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
          {showMinimumCartValue &&
            <div className={CartStyles.minimumAmountError}>
              <p>
                La compra mínima en {store.name} es de <strong>{formatNumber(minimumCartValue)}</strong> para continuar, agrega más productos hasta completar tu orden.
              </p>
            </div>
          }
          {onlinePaymentMethods.length > 0 &&
              <Button
                disabled={showMinimumCartValue || extendedItems.length === 0}
                onClick={handleDialog}
                className={SendOrderStyles.sendOrderBtnOnline}
                variant="contained"
                startIcon={<CreditCard fontSize="small" />}
                fullWidth
              >
                Pagar en línea
              </Button>
            }

            {isWhatsApp[0]?.active &&
              <Button
                disabled={showMinimumCartValue || extendedItems.length === 0}
                onClick={goToSendOrder}
                className={CartStyles.cartShopBtn}
                variant="contained"
                startIcon={paymentMethods.length > 0 ? (<ShoppingBag />) : (<Whatsapp />)}
                fullWidth
              >
                {store.callToActionButton || `Ordenar por WhatsApp`}
              </Button>
            }
          <Backdrop style={{ zIndex: 1400 }} open={beforeSendOrderLoading}>
            <CircularProgress />
          </Backdrop>
        </Container>
      </Container >
      <Dialog  aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          Ingresa tus datos
        </DialogTitle>
        <DialogContent dividers>
        <FormControl
              variant="outlined"
              margin="normal"
              fullWidth>
              <FormLabel htmlFor='name'>Ingresa tu nombre y tu apellido</FormLabel>
              <OutlinedInput
                placeholder="Ingresa tu nombre completo"
                id='name' 
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value) }
              />
              { errorPopUp &&
                <div style={{ position: 'absolute', color:'red', backgroundColor: 'white', width: '100%' }}>
                  <Typography >Ingresa un nombre válido</Typography>
                </div>
              }
            </FormControl>
            <div className={SendOrderStyles.numberFieldWrapper}>
              <FormControl
                variant="outlined"
                margin="normal"
                fullWidth>
                <FormLabel focused={focusInputState}>Ingresa tu Whatsapp</FormLabel>
                <div
                  className={whatsAppFieldClass}>
                  <div className={SendOrderStyles.numberFieldIcon}>
                    <Whatsapp fontSize="small" />
                  </div>
                  <div className={SendOrderStyles.numberFieldCode}>
                    <CountryAutocomplete
                      id="customerWhatsappCountry"
                      style={{ width: "100%" }}
                      options={countries}
                      size="small"
                      disableClearable
                      disableListWrap
                      noOptionsText="No hay resultados"
                      value={code}
                      onChange={(event, value) => handleCodeChange(value.phone)}
                      getOptionLabel={option => option.phone || code}
                      renderOption={option => (
                        <>
                          <span>{countryToFlag(option.code)}</span>{`${option.label} +${option.phone}`}
                        </>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="(+00)"
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable browser autocomplete and autofill
                          }}
                        />
                      )} />
                  </div>
                  <FormControl className={SendOrderStyles.numberFieldWhatsapp}>
                    <Input
                      required
                      onKeyDown={e => symbolsArr.includes(e.key)&& e.preventDefault()}
                      id="customerWhatsapp"
                      placeholder="Ingresa tu número de WhatsApp"
                      type="number"
                      onFocus={() => toggleFocusInput(true)}
                      onBlur={() => toggleFocusInput(false)}
                      value={phoneClient}
                      onChange={(e) => setPhoneClient(e.target.value) }
                      disableUnderline
                      fullWidth />
                      { errorPopUp &&
                        <div style={{ position: 'absolute', color:'red', backgroundColor: 'white', width: '100%' }}>
                          <Typography>Ingresa un número de teléfono válido</Typography>
                        </div>
                      }
                   </FormControl>
                </div>
              </FormControl>
            </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDialog} style={{ color:'#E04F4F' }}>
            Cancelar
          </Button>
          <Button autoFocus onClick={GoToCheckout} color="primary">
            Pagar en línea
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

// Customize the Autocomplete Component
function CountryAutocomplete(props) {
  const classes = countryAutoCompleteStyles()
  return (
    <Autocomplete classes={classes} {...props} />
  )
}
// Material-UI Styles
const countryAutoCompleteStyles = makeStyles(theme => ({
  popper: {
    width: '300px !important',
    left: '16px !important'
  },
  option: {
    fontSize: 13,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    padding: '6px 0 !important',
  }
}))

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store
});

const mapDispatchToProps = dispatch => bindActionCreators({ setCartState }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);