import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Share = props => {
  return (
    <SvgIcon {...props}>
      <path d="M18.75,2.25A3.761,3.761,0,0,0,15,6a3.9684,3.9684,0,0,0,.0762.75L8.2793,9.8086a3.7507,3.7507,0,1,0,.0117,4.3652l6.8028,3.0235A3.6246,3.6246,0,0,0,15,18a3.7806,3.7806,0,1,0,.709-2.1738L8.9062,12.8086A3.72,3.72,0,0,0,9,12a3.785,3.785,0,0,0-.0938-.82L15.668,8.1211A3.7464,3.7464,0,1,0,18.75,2.25Zm0,1.5A2.25,2.25,0,1,1,16.5,6,2.2357,2.2357,0,0,1,18.75,3.75Zm-13.5,6A2.25,2.25,0,1,1,3,12,2.2357,2.2357,0,0,1,5.25,9.75Zm13.5,6A2.25,2.25,0,1,1,16.5,18,2.2357,2.2357,0,0,1,18.75,15.75Z"/>
    </SvgIcon>
  )
}

export default Share
