import React from 'react'
import { SvgIcon } from '@material-ui/core'

const CreditCart = props => {
  return (
    <SvgIcon {...props}>
      <path d="M20 8H4V6h16m0 12H4v-6h16m0-8H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2z"/>
    </SvgIcon>
  )
}

export default CreditCart