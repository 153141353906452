// ReactJS
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router'

// External Dependencies
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

// Icons [PNG]
import Security from '../../../assets/images/security.png';

// External dependencies
import { makeStyles, Typography, Card, OutlinedInput, Button, Select, MenuItem, FormControl, FormLabel, CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { countries } from '../../../utils/constants';
import { getCities } from '../../../services/payments';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'


const fieldsSchema = yup.object({
    country: yup.string(),
    city: yup.string(),
    address: yup.string().required('Se requiere una dirección'),
    detailAddress: yup.string(),
    comments: yup.string(),
});

const useStyles = makeStyles({
    cardList: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 8,
        width: '100%',
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '1rem',
        width: '100%',
    },
    typography: {
        fontSize: 22,
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalPay: {
        fontSize: 20,
        fontWeight: 700,
    },
    labels: {
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'left',
        color: '#0F2930',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        color: '#ffffff',
        background: '#21c59f',
        boxShadow: '5px 5px 9px - 2px rgba(0, 205, 172,0.2), - 5px - 5px 9px 0px rgba(0, 205, 172, 0.14)',
        marginTop: 16,
    }
});

const StepDelivery = ({ setStep, store }) => {
    const { orderId } = useParams()
    const classes = useStyles();
    const [getCountry, setGetCountry] = useState()
    const [cities, setCities] = useState();
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [departament, setDepartament] = useState([])
    const [departamentSel, setDepartamentSel] = useState('')
    const [citiesToSel, setCitiesToSel] = useState([]);


    useEffect(() => {
        setLoading(true)
        getCities()
            .then(res => {
                setCities(res.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            });
        const countryInCode = countries.find( country => country.phone === localStorage.getItem("PaymentIndexPhone"))
        setGetCountry( countryInCode?.label ? countryInCode?.label :  'Colombia'  )
    }, []);

    useEffect( ()=> {
        if (cities?.length > 0) {
            const dptos = cities.map(city => city.state)
            const uniqDep = [...new Set(dptos)]
            setDepartament(uniqDep.sort())
        }
    },[cities]) 

    useEffect( ()=> {
        const city = cities?.filter( city => city.state === departamentSel)
        setCitiesToSel(city)
    },[departamentSel]) 

    const handleChangeCountry = (e) => {
        setGetCountry(e.target.value)
    }

    const selectedDepValue = (e) => {
        setDepartamentSel(e.target.value)
    }

    const onOpenSelectCities = () => {
        setLoading2(true)
        setTimeout(() => {
            setLoading2(false)
        }, 3000);
    }

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(fieldsSchema)
    })

    const onSubmit = (data) => {
        const { address, city, departament, comments, country, detailAddress } = data
        const nameCity = cities?.find(e => e?.id === parseInt(city))
        const nameCountry = countries?.find(e => e.label === getCountry)
        // console.log(data, getCountry, nameCountry)
        axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderId}/shipping-info`, {
            comments: comments ? comments : '',
            shipping_information: {
                address: address,
                address_detail: detailAddress,
                city: city,
                city_id: nameCountry.code === 'CO' ? parseInt(city) : 688,
                country_code: nameCountry.code,
            },
        })
        .then((res) => {
            localStorage.setItem("Step", 3);
            localStorage.setItem("StepDelivery", JSON.stringify({
                address: address,
                city: nameCity?.name,
                departament: departamentSel,
                city_id: parseInt(city),
                comments: comments,
                country: getCountry,
                detailAddress: detailAddress
            }));
            setStep(3)
            ReactPixel.track('Lead', {
                content_name: 'Continuar a pagos',
            })
        }).catch(err => console.log(err))
    };
   
    //Facebook pixel
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
            store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
                content_type: 'step delivery',
            })
        }
    }, [store])
    
    return (
        <>
            {loading && <CircularProgress style={{ margin: '1rem' }} />}
            {!loading && <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='country' className={classes.labels} style={{ paddingBottom: '7px' }}>
                            País
                        </FormLabel>
                        <Select
                            {...register('country')}
                            defaultValue={getCountry}
                            style={{ textAlign: 'initial' }}
                            id='country'
                            input={<OutlinedInput label="country" fullWidth />}
                            onChange={handleChangeCountry}
                        >
                            {countries?.map(cou => (
                                <MenuItem value={cou.label} key={cou.code}>
                                    {cou.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {errors?.country && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.country?.message}</Typography>}
                </Card>

                { getCountry === 'Colombia' && (
                    <>
                        <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                            <FormControl
                                variant="outlined"
                                margin="normal"
                                fullWidth>
                                <FormLabel htmlFor='departament' className={classes.labels}>
                                    Departamento
                                </FormLabel>
                                <Select
                                    style={{ textAlign: 'initial' }}
                                    id='departament'
                                    input={<OutlinedInput label="departament" fullWidth onChange={selectedDepValue}/>}
                                >
                                    {departament?.map(dep => (
                                        <MenuItem value={dep} key={dep}>
                                            {dep}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {errors?.departament && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.departament?.message}</Typography>}
                        </Card>

                        <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                            <FormControl
                                disabled={!departamentSel}
                                variant="outlined"
                                margin="normal"
                                onOpen={onOpenSelectCities}
                                fullWidth>
                                <FormLabel htmlFor='city' className={classes.labels}>
                                    Ciudad
                                </FormLabel>
                                <Select
                                    {...register('city')}
                                    style={{ textAlign: 'initial' }}
                                    defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.city_id}
                                    id='city'
                                    input={<OutlinedInput label="city" fullWidth input/>}
                                >
                                    {/* {colombianCities.map((item, index) => (
                                    <MenuItem value={item?.city} key={index}>
                                        {item?.city}
                                    </MenuItem>
                                ))} */}
                                    {citiesToSel?.map((city) => (
                                        <MenuItem value={city?.id} key={city?.id}>
                                            {city?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {errors?.city && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.city?.message}</Typography>}
                        </Card>
                    </>
                )}
                
                <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='address' className={classes.labels}>
                            Dirección
                        </FormLabel>
                        <OutlinedInput
                            {...register('address')}
                            id='address'
                            defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.address}
                            fullWidth
                        />
                    </FormControl>
                    {errors?.address && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.address?.message}</Typography>}
                </Card>

                <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='detailAddress' className={classes.labels}>
                            Detalles de la dirección
                        </FormLabel>
                        <OutlinedInput
                            {...register('detailAddress')}
                            id='detailAddress'
                            defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.detailAddress}
                            type='text'
                            placeholder={ getCountry === 'Colombia' ? 'Apto, Local, Oficina...' : 'Ciudad, Estado, Provincia' }
                            fullWidth
                        />
                    </FormControl>
                </Card>

                <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                    <FormControl
                        variant="outlined"
                        margin="normal"
                        fullWidth>
                        <FormLabel htmlFor='comments' className={classes.labels}>
                            Comentarios
                        </FormLabel>
                        <OutlinedInput
                            {...register('comments')}
                            id='comments'
                            defaultValue={JSON.parse(localStorage.getItem("StepDelivery"))?.comments}
                            placeholder="Notas o instrucciones de entrega"
                            rows={3}
                            fullWidth
                            multiline
                        />
                    </FormControl>
                </Card>
                <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, marginTop: 32 }}>
                    <img src={Security} alt="security" style={{}} />
                    <Typography variant="body1" style={{ color: '#53627C', fontSize: 16, }}>
                        Compra segura con proveedores de pagos certificados y verificados.
                    </Typography>
                </Card>
                <Button
                    type='submit'
                    className={classes.button}
                    variant="contained"
                    fullWidth
                >
                    Continuar a pagos
                </Button>
            </form>}
        </>
    );
}

export default StepDelivery;