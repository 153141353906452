import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ChevronDown = props => {
  return (
    <SvgIcon {...props}>
      <path d="M20.15,7l-8.1,7.7L3.95,7.1a1.2215,1.2215,0,0,0-.9-.4,1.7969,1.7969,0,0,0-.9.4,1.7969,1.7969,0,0,0-.4.9,1.0858,1.0858,0,0,0,.4.9l8.5,8a2.3893,2.3893,0,0,0,1.4.5,1.6125,1.6125,0,0,0,1.3-.6l8.5-8a1.4893,1.4893,0,0,0,.4-.9,1.2215,1.2215,0,0,0-.4-.9,1.2215,1.2215,0,0,0-.9-.4A2.01,2.01,0,0,0,20.15,7Z"/>
    </SvgIcon>
  )
}

export default ChevronDown
