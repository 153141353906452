import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  makeStyles
} from '@material-ui/core';
import Link from '@material-ui/core/Link';

import ChevronDown from '../Icons/ChevronDown';
import Donwload from '../Icons/Donwload';

const ProductExpansionPanel = ({ summary, title }) => {
  return (
    <ProductInfoExpansionPanel
      elevation={3}>
      <ProductInfoExpansionPanelSummary
        expandIcon={<ChevronDown fontSize="inherit" />}
        aria-controls="productDescriptionContent"
        id="productDescriptionHeader">
        <Typography>{title}</Typography>
      </ProductInfoExpansionPanelSummary>
      <ExpansionPanelDetails>
        {<Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{summary}</Typography>}
      </ExpansionPanelDetails>
    </ProductInfoExpansionPanel>
  )
}

// Custom ExpansionPanel component
function ProductInfoExpansionPanel(props) {
  const classes = productInfoExpansionPanelStyles()
  return <ExpansionPanel classes={classes} {...props} />
}

// Custom ExpansionPanelSummary component
function ProductInfoExpansionPanelSummary(props) {
  const classes = productInfoExpansionPanelSummaryStyles()
  return <ExpansionPanelSummary classes={classes} {...props} />
}

// Material-UI Styles
const productInfoExpansionPanelStyles = makeStyles(theme => ({
  root: {
    //margin: '16px 0',
    //borderRadius: '8px',
    '&::before': {
      display: 'none',
    },
    '&:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '&.Mui-expanded': {
      margin: 0
    },
  }
}))

const productInfoExpansionPanelSummaryStyles = makeStyles(theme => ({
  root: {
    '&.Mui-expanded': {
      minHeight: '48px',
    },
  },
  content: {
    '&.Mui-expanded': {
     // margin: '12px 0',
    },
  },
  expandIcon: {
    fontSize: '1.125rem',
  },
}))

export default ProductExpansionPanel
