import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Donwload = props => {
    return (
        <SvgIcon {...props}>
            <path d="M11 2V14.4922L7.57812 11.2109L6.54688 12.2891L11.75 17.2871L16.9531 12.2891L15.9219 11.2109L12.5 14.4922V2H11ZM2 15.5V19.25C2 20.4863 3.01367 21.5 4.25 21.5H19.25C20.4863 21.5 21.5 20.4863 21.5 19.25V15.5H20V19.25C20 19.6719 19.6719 20 19.25 20H4.25C3.82812 20 3.5 19.6719 3.5 19.25V15.5H2Z" fill="#48AC98" />
        </SvgIcon>
    )
}

export default Donwload
