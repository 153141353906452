import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ShoppingBag = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.3,6.2h-2.1C17.3,3.3,14.9,1,12,1S6.8,3.3,6.8,6.2H4.7c-1.2,0-2.1,0.9-2.1,2.1v12.6c0,1.2,0.9,2.1,2.1,2.1h14.7
      	c1.2,0,2.1-0.9,2.1-2.1V8.3C21.4,7.2,20.5,6.2,19.3,6.2 M12,3.1c1.8,0,3.1,1.4,3.1,3.1H8.9C8.9,4.5,10.2,3.1,12,3.1 M19.3,20.9H4.7
      	V8.3h14.7V20.9 M12,12.5c-1.8,0-3.1-1.4-3.1-3.1H6.8c0,2.9,2.3,5.2,5.2,5.2s5.2-2.3,5.2-5.2h-2.1C15.2,11.2,13.8,12.5,12,12.5z"/>
    </SvgIcon>
  )
}

export default ShoppingBag
