// ReactJS
import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form"
import { useParams } from 'react-router'

// External Dependencies
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios';

// Icons [PNG]
import Security from '../../../assets/images/security.png'

// Styles
import SendOrderStyles from '../../SendOrder/SendOrder.module.scss'

// External dependencies
import { makeStyles, Typography, Card, OutlinedInput, Select, MenuItem, Grid, Button, FormControl, Input, TextField, FormLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import Whatsapp from '../../Icons/Whatsapp';

import classNames from 'classnames/bind';

import { countries } from '../../../utils/constants';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'

const fieldsSchema = yup.object({
    name: yup.string().required('Se requiere el nombre y apellido'),
    code: yup.string(),
    phone: yup.number().required('Se requiere el número de teléfono'),
    email: yup.string().email('Se requiere un email valido').required('Se requiere un email'),
    typeDocument: yup.string().required('Se requiere el tipo documento'),
    document: yup.string().required('Se requiere el numero de documento'),
});

const useStyles = makeStyles({
    cardList: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 8,
        width: '100%',
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '1rem',
        width: '100%',
    },
    typography: {
        fontSize: 22,
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalPay: {
        fontSize: 20,
        fontWeight: 700,
    },
    labels: {
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'left',
        color: '#0F2930',
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        color: '#ffffff',
        background: '#21c59f',
        boxShadow: '5px 5px 9px - 2px rgba(0, 205, 172,0.2), - 5px - 5px 9px 0px rgba(0, 205, 172, 0.14)',
        marginTop: 16,
    }
});

function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
}

const StepData = ({ setStep, store }) => {
    const classes = useStyles()
    const { orderId } = useParams()
    const [focusInputState, setFocusInputState] = useState(false);
    const [code, setCode] = useState(null)

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(fieldsSchema)
    });

    const onSubmit = (data) => {
        axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderId}/customer-info`, {
        comments: 'Carrito abandonado en información del cliente',
        customer: {
            first_name: data.name.split(' ').shift(),
            last_name: data.name.split(' ').pop(),
            phone_number: `${parseInt(code)}${data.phone}`,
            dni: data.document,
            email: data.email,
            document_type: data.typeDocument
        },
        })
        .then((res) => {
            localStorage.setItem("Step", 2);
            localStorage.setItem("StepData", JSON.stringify(data))
            setStep(2);
            ReactPixel.track('Lead', {
                content_name: 'Continuar a envíos',
            })
        }).catch(err => console.log(err))
    }

    const countryAutoCompleteStyles = makeStyles(theme => ({
        popper: {
            width: '300px !important',
            left: '16px !important'
        },
        option: {
            fontSize: 13,
            '& > span': {
                marginRight: 10,
                fontSize: 18,
            },
        },
        input: {
            padding: '6px 0 !important',
        }
    }))

    const toggleFocusInput = state => setFocusInputState(state)
    const handleCodeChange = newValue =>  {
        setCode(newValue)
        setValue('code', newValue)
    }

    const whatsAppFieldClass = classNames({
        [SendOrderStyles.numberField]: true,
        [SendOrderStyles.numberFieldFocus]: focusInputState
    });

    function CountryAutocomplete(props) {
        const classes = countryAutoCompleteStyles()
        return (
            <Autocomplete classes={classes} {...props} />
        )
    }

    //Facebook pixel
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
            store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
                content_type: 'step data',
            })
        }
        handleCodeChange(JSON.parse(localStorage.getItem("StepData"))?.code || '57')
    }, [store])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>

                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth>
                    <FormLabel htmlFor='name' className={classes.labels}>Nombre y Apellido</FormLabel>
                    <OutlinedInput
                        {...register('name')}
                        defaultValue={JSON.parse(localStorage.getItem("StepData"))?.name || JSON.parse(localStorage.getItem("DataUserOrder"))?.name}
                        type="text"
                        id='name'
                        fullWidth
                    />
                </FormControl>
                {errors?.name && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.name?.message}</Typography>}

                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth>
                    <FormLabel htmlFor='customerWhatsapp' className={classes.labels}>
                        Tu WhatsApp
                    </FormLabel>
                    <div
                        style={{ width: '100%' }}
                        className={whatsAppFieldClass}>
                        <div className={SendOrderStyles.numberFieldIcon}>
                            <Whatsapp fontSize="small" />
                        </div>
                        <div className={SendOrderStyles.numberFieldCode}>
                            <CountryAutocomplete
                                id="customerWhatsappCountry"
                                style={{ width: "100%" }}
                                options={countries}
                                size="small"
                                disableClearable
                                disableListWrap
                                noOptionsText="No hay resultados"
                                value={code || ''}
                                onChange={(event, value) => handleCodeChange(value.phone)}
                                getOptionLabel={option => option.phone || code}
                                renderOption={option => (
                                    <>
                                        <span>{countryToFlag(option.code)}</span>{`${option.label} +${option.phone}`}
                                    </>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        placeholder="(+00)"
                                        fullWidth
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable browser autocomplete and autofill
                                        }}
                                    />
                                )} />
                        </div>
                        <FormControl className={SendOrderStyles.numberFieldWhatsapp}>
                            <Input
                                {...register("phone", { required: true })}
                                required
                                defaultValue={JSON.parse(localStorage.getItem("StepData"))?.phone || JSON.parse(localStorage.getItem("DataUserOrder"))?.phone}
                                id="customerWhatsapp"
                                placeholder="Ingresa tu número de WhatsApp"
                                type="number"
                                onFocus={() => toggleFocusInput(true)}
                                onBlur={() => toggleFocusInput(false)}
                                disableUnderline
                                fullWidth />
                        </FormControl>
                    </div>
                </FormControl>
                {errors?.phone && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.phone?.message}</Typography>}

                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth>
                    <FormLabel htmlFor='email' className={classes.labels}>
                        Correo electrónico
                    </FormLabel>
                    <OutlinedInput
                        {...register('email')}
                        defaultValue={JSON.parse(localStorage.getItem("StepData"))?.email}
                        type="email"
                        id='email'
                        fullWidth
                    />
                </FormControl>
                {errors?.email && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.email?.message}</Typography>}
                <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={2}>
                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='typeDocument' className={classes.labels}>
                                Documento
                            </FormLabel>
                            <Select
                                {...register('typeDocument')}
                                defaultValue={JSON.parse(localStorage.getItem("StepData"))?.typeDocument}
                                id='typeDocument'
                                input={<OutlinedInput label="typeDocument" fullWidth />}
                            >
                                <MenuItem value="CC">
                                    CC
                                </MenuItem>
                                <MenuItem value="TI">
                                    TI
                                </MenuItem>
                                <MenuItem value="Pasaporte">
                                    Pasaporte
                                </MenuItem>
                            </Select>
                        </FormControl>
                        {errors?.typeDocument && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.typeDocument?.message}</Typography>}
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='document' className={classes.labels}>
                                Número de identificación
                            </FormLabel>
                            <OutlinedInput
                                {...register('document')}
                                id='document'
                                defaultValue={JSON.parse(localStorage.getItem("StepData"))?.document}
                                fullWidth
                            />
                        </FormControl>
                        {errors?.document && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.document?.message}</Typography>}
                    </Grid>
                </Grid>
            </Card>
            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, marginTop: 32 }}>
                <img src={Security} alt="security" style={{}} />
                <Typography variant="body1" style={{ color: '#53627C', fontSize: 16, }}>
                    Compra segura con proveedores de pagos certificados y verificados.
                </Typography>
            </Card>
            <Button
                type='submit'
                className={classes.button}
                variant="contained"
                fullWidth
            >
                Continuar a envios
            </Button>
        </form>
    );
}

export default StepData;