import React, { useContext } from 'react'

import styles from './Widget.module.css'
import WidgetContext from '../WidgetContext/WidgetContext'

import Header from '../Header/Header'
import ChatSection from '../ChatSection/ChatSection'
import SendButton from '../SendButton/SendButton'

export default function App(props) {
  const { isOpen, handleOpen } = useContext(WidgetContext)

  return (
    <div className={`${styles.root} ${isOpen ? styles.open : styles.close}`}>
      <Header handleOpen={handleOpen} {...props} />
      <ChatSection {...props} />
      <SendButton {...props} />
    </div>
  )
}
