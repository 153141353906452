import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ShoppingBagOutline = props => {
  return (
    <SvgIcon {...props}>
      <path d="M 21.539062 10.183594 L 21.539062 5.578125 L 2.457031 5.578125 L 2.457031 10.183594 L 2.128906 10.183594 L 2.128906 22.441406 C 2.132812 23.300781 2.828125 24 3.6875 24 L 20.308594 24 C 21.167969 24 21.863281 23.300781 21.867188 22.441406 L 21.867188 10.183594 Z M 20.878906 6.238281 L 20.878906 10.183594 L 20.714844 10.183594 L 19.011719 7.910156 L 20.6875 6.238281 Z M 19.894531 10.183594 L 18.90625 10.183594 L 18.90625 8.867188 Z M 19.753906 6.238281 L 18.34375 7.648438 C 18.332031 7.65625 18.328125 7.675781 18.316406 7.683594 C 18.304688 7.699219 18.292969 7.714844 18.285156 7.734375 C 18.28125 7.738281 18.273438 7.746094 18.269531 7.753906 C 18.265625 7.765625 18.261719 7.78125 18.261719 7.796875 C 18.253906 7.816406 18.25 7.835938 18.25 7.859375 C 18.25 7.867188 18.25 7.875 18.25 7.882812 L 18.25 10.183594 L 5.75 10.183594 L 5.75 7.882812 C 5.75 7.875 5.746094 7.863281 5.746094 7.859375 C 5.742188 7.835938 5.738281 7.816406 5.730469 7.796875 C 5.730469 7.78125 5.726562 7.765625 5.722656 7.753906 C 5.71875 7.746094 5.710938 7.738281 5.707031 7.734375 C 5.699219 7.714844 5.6875 7.699219 5.675781 7.683594 C 5.664062 7.671875 5.664062 7.65625 5.648438 7.648438 L 4.238281 6.238281 Z M 3.117188 6.238281 L 3.3125 6.238281 L 4.984375 7.910156 L 3.28125 10.183594 L 3.117188 10.183594 Z M 5.089844 8.867188 L 5.089844 10.183594 L 4.101562 10.183594 Z M 21.207031 22.441406 C 21.207031 22.9375 20.804688 23.339844 20.308594 23.34375 L 3.6875 23.34375 C 3.191406 23.339844 2.789062 22.9375 2.789062 22.441406 L 2.789062 10.84375 L 21.207031 10.84375 Z M 21.207031 22.441406 "/>
      <path d="M 8.378906 13.144531 C 8.382812 13.027344 8.320312 12.914062 8.21875 12.855469 C 8.113281 12.796875 7.988281 12.796875 7.886719 12.855469 C 7.78125 12.914062 7.71875 13.027344 7.722656 13.144531 L 7.722656 15.117188 C 7.722656 17.480469 9.636719 19.394531 12 19.394531 C 14.359375 19.394531 16.273438 17.480469 16.273438 15.117188 L 16.273438 13.144531 C 16.277344 13.027344 16.214844 12.914062 16.113281 12.855469 C 16.007812 12.796875 15.882812 12.796875 15.78125 12.855469 C 15.675781 12.914062 15.613281 13.027344 15.617188 13.144531 L 15.617188 15.117188 C 15.617188 17.117188 13.996094 18.738281 12 18.738281 C 10 18.738281 8.378906 17.117188 8.378906 15.117188 Z M 8.378906 13.144531 "/>
      <path d="M 7.0625 13.472656 C 7.246094 13.472656 7.390625 13.328125 7.394531 13.144531 C 7.394531 12.78125 7.6875 12.488281 8.050781 12.488281 C 8.414062 12.488281 8.707031 12.78125 8.707031 13.144531 C 8.710938 13.324219 8.859375 13.46875 9.039062 13.46875 C 9.21875 13.46875 9.363281 13.324219 9.367188 13.144531 C 9.367188 12.417969 8.777344 11.828125 8.050781 11.828125 C 7.324219 11.828125 6.734375 12.417969 6.734375 13.144531 C 6.734375 13.328125 6.882812 13.472656 7.0625 13.472656 Z M 7.0625 13.472656 "/>
      <path d="M 14.957031 13.472656 C 15.140625 13.472656 15.285156 13.328125 15.289062 13.144531 C 15.285156 12.90625 15.410156 12.6875 15.613281 12.566406 C 15.820312 12.449219 16.070312 12.449219 16.277344 12.566406 C 16.480469 12.6875 16.605469 12.90625 16.601562 13.144531 C 16.605469 13.324219 16.753906 13.46875 16.933594 13.46875 C 17.113281 13.46875 17.257812 13.324219 17.261719 13.144531 C 17.265625 12.671875 17.015625 12.234375 16.605469 11.996094 C 16.199219 11.757812 15.691406 11.757812 15.285156 11.996094 C 14.875 12.234375 14.625 12.671875 14.628906 13.144531 C 14.628906 13.328125 14.777344 13.472656 14.957031 13.472656 Z M 14.957031 13.472656 "/>
      <path d="M 10.488281 4.277344 L 12.164062 2.605469 L 13.835938 4.277344 L 14.300781 3.8125 L 12.628906 2.140625 L 14.300781 0.464844 L 13.835938 0 L 12.164062 1.675781 L 10.488281 0 L 10.023438 0.464844 L 11.695312 2.140625 L 10.023438 3.8125 Z M 10.488281 4.277344 "/>
    </SvgIcon>
  )
}

export default ShoppingBagOutline
