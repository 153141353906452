// ReactJS
import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom'

// Components
import Store from './components/Store';
import Product from './components/Product';
import Cart from './components/Cart';
import SendOrder from './components/SendOrder';
import OrderReview from './components/OrderReview';
import Checkout from './components/Checkout';
import NotFound from './components/NotFound';

// Styles
import './App.css';

console.info('init app 1.0');

function App() {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('Step') === '4') {
      localStorage.setItem('Step', 3)
    }
    localStorage.removeItem("ManualPay");
  }, []);

  return (
    <main className="App">
      <Switch>
        <Route exact path="/checkout/:storeId/:orderId" component={Checkout} />
        <Route exact path="/checkout/:storeId/:orderId/result" component={Checkout} />
        <Route exact path="/checkout/:storeId/:orderId/payment-link" component={Checkout} />
        <Route exact path="/order-review/:storeId/:orderId" component={OrderReview} />
        <Route exact path="/:phoneNumber/order/create" component={SendOrder} />
        <Route exact path="/:phoneNumber/order/products" component={Cart} />
        <Route exact path="/:phoneNumber/collections" component={Store} />
        <Route exact path="/:phoneNumber/:productSlug" component={Product} />
        <Route exact path="/:phoneNumber/collections/:nameCollection" component={Store} />
        <Route exact path="/:phoneNumber" component={Store} />
        <Route exact path="/" component={Store} />
        <Route exact path="/404" component={NotFound} />
        <Route path='*' exact>{() => window.location.replace("https://get10x.co/")}</Route>
      </Switch>
    </main>
  );
}

export default App;
