// ReactJS
import React, { useCallback, useEffect, useState } from 'react';

// Images [PNG]
import SuccessPay from '../../../assets/images/successPay.png';
import FailPay from '../../../assets/images/failPay.png';
import ManualPay from '../../../assets/images/manualPayIcon.png';
import BancolombiaQR from '../../../assets/images/QRBancolombia.png';

// External dependencies
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles, Typography, Card, Grid, Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    cardList: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    linkSupport: {
        marginLeft: 5,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        "&:hover": {
            opacity: 0.7
        }
    },
    button: {
        color: '#ffffff',
        background: '#21c59f',
        boxShadow: '5px 5px 9px - 2px rgba(0, 205, 172,0.2), - 5px - 5px 9px 0px rgba(0, 205, 172, 0.14)',
        marginTop: 16,
    }
});

const ResultPayment = ({ queryData, phoneSupport, check, setCheck, isManualPay = false, payerID = '' }) => {
    const classes = useStyles();
    const history = useHistory();
    const { orderId, storeId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [appKey, setAppKey] = useState()
    const [at, setAt] = useState()
    const [loading, setLoading] = useState(false);
    const [orderData, setOrderData] = useState()
    const [transactionState, setTransactionState] = useState();
    const [paymentMethods, setPaymentMethods] = useState([])
    const [extraParameters, setExtraParameters] = useState({})

    const Decrypt = useCallback((data) => {
        var payments = {}
        for (let i = 0; i < data.length; i++) {
            var iv = CryptoJS.enc.Hex.parse(data[i]?.app_key.substr(0, 32))
            var ct = CryptoJS.enc.Hex.parse(data[i]?.app_key.substr(32))
            var key = CryptoJS.enc.Utf8.parse("secret key456789")
            var decrypted = CryptoJS.AES.decrypt({ ciphertext: ct }, key, {
                mode: CryptoJS.mode.CBC,
                iv: iv
            })
            payments = Object.assign(payments, { [data[i]?.payment_channel?.name]: decrypted.toString(CryptoJS.enc.Utf8) })
        }
        return payments;
    }, [])

    const cleanShoppingCart = () => {
        /* Limpiar Carrito */
        /* const cartKey = `${cartPrefix}-${storeUrl}-${phoneNumber}`;   Construir key de localstorage */
        const cartKey = 'wa10xcart'; //  Construir key de localstorage
        const currentCart = localStorage.getItem(cartKey);  //  Obtener datos del storage
        const cCart = JSON.parse(currentCart);  //  Parsear datos actuales
        const storeData = cCart.store;  //  Guardar storeData para el nuevo state
        localStorage.setItem(cartKey, JSON.stringify( // Resetear state
            {
                items: [],
                store: storeData
            }
        ));
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${storeId}/payment-channels-store`)
            .then((response) => {
                setAppKey(Decrypt(response?.data))
            })
            .catch((error) => {
                console.log(error);
            })
    }, [Decrypt, storeId])

    useEffect(() => {
        const lastTypePay = localStorage.getItem("lastTypePay")
        lastTypePay?.toLocaleLowerCase() === 'payu' && queryData?.transactionState && setAt(parseInt(queryData?.transactionState))
        queryData?.status && setAt(String(queryData?.status?.toLocaleLowerCase()))

        if (payerID !== '' && lastTypePay?.toLocaleLowerCase() === 'paypal') {
            setAt('approved')
        } else if (payerID === '' && lastTypePay?.toLocaleLowerCase() === 'paypal') {
            setAt('declined')
        }

        const getDataWompi = () => {
            setLoading(true);
            axios.get(`https://${queryData?.env === 'test' ? 'sandbox' : 'production'}.wompi.co/v1/transactions/${queryData?.id}`)
                .then(response => {
                    setLoading(false);
                    setAt(response?.data?.data?.status?.toLocaleLowerCase())
                    //response?.data?.data?.status === 'APPROVED' ? setTransactionState(true) : setTransactionState(false)
                })
                .catch(error => {
                    setLoading(false);
                    setTransactionState(false)
                    console.log(error);
                    enqueueSnackbar('No se pudo recuperar información de la transacción', {
                        variant: 'error',
                        autoHideDuration: 6000
                    });
                });
        }
        lastTypePay?.toLocaleLowerCase() === 'wompi' && getDataWompi();

        const getDataMP = (at) => {
            setLoading(true);
            axios.get(`https://api.mercadopago.com/v1/payments/search?sort=date_created&criteria=desc&external_reference=${orderId}`, {
                headers: {
                    'Authorization': `Bearer ${at}`
                }
            })
                .then(response => {
                    setLoading(false);
                    setAt(response?.data?.results[0]?.status)
                    //response?.data?.results[0]?.status === 'approved' ? setTransactionState(true) : setTransactionState(false)
                })
                .catch(error => {
                    setLoading(false);
                    setTransactionState(false)
                    console.log(error);
                    enqueueSnackbar('No se pudo recuperar información de la transacción', {
                        variant: 'error',
                        autoHideDuration: 6000
                    });
                });
        }
        lastTypePay === 'Mercado Pago' && appKey && getDataMP(appKey["Mercado Pago"]);
    }, [check, enqueueSnackbar, queryData, payerID, appKey, orderId])

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${storeId}/orders/${orderId}`)
            .then(res => {
                setLoading(false);
                setOrderData(res.data);
            })
            .catch(err => {
                setLoading(false);
                enqueueSnackbar('La orden no existe', {
                    variant: 'error',
                    autoHideDuration: 6000
                });
                history.push('/')
            });
    }, [enqueueSnackbar, history, orderId, storeId])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${storeId}/payment-channels-store`)
            .then((response) => {
                setLoading(true)
                setPaymentMethods(response?.data.filter(item => item?.payment_channel?.name === 'Pago Manual')[0])
                setExtraParameters(JSON.parse(JSON.parse(response?.data.filter(item => item?.payment_channel?.name === 'Pago Manual')[0]?.extra_parameters.trim())))
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
            })
    }, [storeId])

    const [statePayment, setStatePayment] = useState(
        JSON.parse(localStorage?.getItem('StepDelivery')) && Object.assign(
            JSON.parse(localStorage?.getItem('StepDelivery')),
            { "indexPhone": localStorage?.getItem('PaymentIndexPhone') },
            { "totalPay": Number(localStorage?.getItem('TotalPay')) },
            JSON.parse(localStorage?.getItem('StepData'))
        )
    )

    const handleClick = (actionState) => {
        if (actionState === 'approved') {
            history.push('/')
            localStorage.setItem('Step', 1)
        } else {
            history.push(`/${phoneSupport}/order/products`)
        }
    };

    const handleManualPay = () => {
        localStorage.setItem('Step', 1)
        localStorage.removeItem("ManualPay")
        localStorage.removeItem("StepData")
        localStorage.removeItem("StepDelivery")
        cleanShoppingCart()
        history.push('/')
    }

    const messageSupport = () => {
        const messageUrl = `https://wa.me/${phoneSupport}?text=`;
        const message = `¡Hola!

        Soy ${statePayment?.name} y necesito ayuda con mi pedido #${orderData?.sequence}`;
        return `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`
    }

    const STATE_PAYMENTS = {
        approved: {
            status: 'approved',
            icon: SuccessPay,
            message: `¡Gracias ${statePayment.name}!`,
            title: 'Tu pedido está confirmado',
            description: 'Recibirás en breve un correo electrónico de confirmación con tu número de pedido.',
            textButton: 'Seguir comprando'
        },
        4: {
            status: 'approved',
            icon: SuccessPay,
            message: `¡Gracias ${statePayment.name}!`,
            title: 'Tu pedido está confirmado',
            description: 'Recibirás en breve un correo electrónico de confirmación con tu número de pedido.',
            textButton: 'Seguir comprando'
        },
        pending: {
            status: 'pending',
            icon: ManualPay,
            message: 'Pendiente de confirmación',
            title: 'Tu pedido está a la espera de confirmación',
            description: 'Recibirás un correo electrónico de confirmación cuando la entidad nos confirme el pago.',
            textButton: 'Volver a realizar el pedido'
        },
        in_process: {
            status: 'in_process',
            icon: ManualPay,
            message: 'Pendiente de confirmación',
            title: 'Tu pedido está a la espera de confirmación',
            description: 'Recibirás un correo electrónico de confirmación cuando la entidad nos confirme el pago.',
            textButton: 'Volver a realizar el pedido'
        },
        7: {
            status: 'pending',
            icon: ManualPay,
            message: 'Pendiente de confirmación',
            title: 'Tu pedido está a la espera de confirmación',
            description: 'Recibirás un correo electrónico de confirmación cuando la entidad nos confirme el pago.',
            textButton: 'Volver a realizar el pedido'
        },
        rejected: {
            status: 'rejected',
            icon: FailPay,
            message: 'Hubo un problema con tu pedido',
            title: 'Tu pedido fue cancelado/rechazado',
            description: 'Recibirás en breve un correo electrónico para que puedas realizar de nuevo el pedido.',
            textButton: 'Volver a realizar el pedido'
        },
        declined: {
            status: 'declined',
            icon: FailPay,
            message: 'Hubo un problema con tu pedido',
            title: 'Tu pedido fue cancelado/rechazado',
            description: 'Recibirás en breve un correo electrónico para que puedas realizar de nuevo el pedido.',
            textButton: 'Volver a realizar el pedido'
        },
        6: {
            status: 'declined',
            icon: FailPay,
            message: 'Hubo un problema con tu pedido',
            title: 'Tu pedido fue cancelado/rechazado',
            description: 'Recibirás en breve un correo electrónico para que puedas realizar de nuevo el pedido.',
            textButton: 'Volver a realizar el pedido'
        },
        error: {
            status: 'declined',
            icon: FailPay,
            message: 'Hubo un problema con tu pedido',
            title: 'Tu pedido fue cancelado/rechazado',
            description: 'Recibirás en breve un correo electrónico para que puedas realizar de nuevo el pedido.',
            textButton: 'Volver a realizar el pedido'
        }
    }

    const loki = STATE_PAYMENTS[at] || 'rejected'

    useEffect(() => {
        loki?.status === 'approved' && cleanShoppingCart()
    }, [loki])


    return (
        <React.Fragment>
            {isManualPay
                ?
                <React.Fragment>
                    {loading && <CircularProgress style={{ margin: '1rem' }} />}
                    {statePayment && queryData && !loading &&
                        <React.Fragment>
                            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, padding: 8 }}>
                                <Grid style={{ width: '100%', display: 'flex', padding: 8, marginTop: 12, marginBottom: 12 }}>
                                    <Grid item xs={2} sm={2}>
                                        <img src={ManualPay} alt="Estado del pago" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} style={{ textAlign: 'left' }}>
                                        <Typography variant="body1" style={{ fontSize: 16, color: '#000000' }}>
                                            Pedido #{orderData?.sequence}
                                        </Typography>
                                        <Typography variant="body1" style={{ fontSize: 20, color: '#000000', fontWeight: 700 }}>
                                            {`¡Gracias ${statePayment.name}!`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16, marginTop: 8 }}>
                                    <Typography variant="h1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                                        Tu pedido ha sido recibido
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', marginTop: 8, textAlign: 'left' }}>
                                        {extraParameters?.title}
                                    </Typography>
                                </Card>
                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16, marginTop: 8 }}>
                                    <Typography variant="h1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                                        Pago por transferencia
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', marginTop: 8, textAlign: 'left' }}>
                                        {extraParameters?.description}
                                    </Typography>
                                </Card>
                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16, marginTop: 8 }}>
                                    <Typography variant="h1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                                        Instrucciones del pago
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', marginTop: 8, textAlign: 'left' }}>
                                        {extraParameters?.instructions}
                                    </Typography>
                                </Card>
                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16, marginTop: 8, display: 'flex', alignItems: 'center' }}>
                                    <img src={extraParameters?.qrImage} alt="Estado del pago" style={{ width: 327, height: 417 }} />
                                </Card>
                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16, marginTop: 8, marginBottom: 8 }}>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" style={{ fontSize: 16, color: '#53627C' }}>
                                            Contacto
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.name}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.phone}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.email}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.typeDocument} {statePayment.document}</Typography>
                                    <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" style={{ fontSize: 16, color: '#53627C' }}>
                                            Enviar a
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.address}, {statePayment.detailAddress}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.city}, {statePayment.country}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.comments}</Typography>
                                </Card>
                                <div style={{ padding: 16, textAlign: 'center', width: '100%' }}>
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#0F2930', fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        ¿Necesitas ayuda?
                                        <a
                                            className={classes.linkSupport}
                                            target="_blank"
                                            rel='noopener noreferrer'
                                            href={messageSupport()}
                                        >
                                            <span variant="body1" style={{ fontSize: 16, color: '#48AC98', fontWeight: 700, cursor: 'pointer' }}>
                                                Ponte en contacto con la tienda
                                            </span>
                                        </a>
                                    </Typography>
                                </div>
                            </Card>
                            <Button
                                type='button'
                                className={classes.button}
                                variant="contained"
                                fullWidth
                                onClick={handleManualPay}
                            >
                                Seguir comprando
                            </Button>
                        </React.Fragment>
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    {loading && <CircularProgress style={{ margin: '1rem' }} />}
                    {statePayment && queryData && !loading &&
                        <React.Fragment>
                            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, padding: 8 }}>

                                <Grid style={{ width: '100%', display: 'flex', padding: 8, marginTop: 12, marginBottom: 12 }}>
                                    <Grid item xs={2} sm={2}>
                                        <img src={loki.icon} alt="Estado del pago" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} style={{ textAlign: 'left' }}>
                                        <Typography variant="body1" style={{ fontSize: 16, color: '#000000' }}>
                                            Pedido #{orderData?.sequence}
                                        </Typography>
                                        <Typography variant="body1" style={{ fontSize: 20, color: '#000000', fontWeight: 700 }}>
                                            {loki.message}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16 }}>
                                    <Typography variant="h1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                                        {loki.title}
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', marginTop: 8, textAlign: 'left' }}>
                                        {loki.description}
                                    </Typography>
                                </Card>

                                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 16, marginTop: 8, marginBottom: 8 }}>
                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" style={{ fontSize: 16, color: '#53627C' }}>
                                            Contacto
                                        </Typography>
                                    </Grid>

                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.name}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.phone}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.email}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.typeDocument} {statePayment.document}</Typography>

                                    <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />

                                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" style={{ fontSize: 16, color: '#53627C' }}>
                                            Enviar a
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.address}, {statePayment.detailAddress}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.city}, {statePayment.country}</Typography>
                                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>{statePayment.comments}</Typography>
                                </Card>

                                <div style={{ padding: 16, textAlign: 'center', width: '100%' }}>
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#0F2930', fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        ¿Necesitas ayuda?
                                        <a
                                            className={classes.linkSupport}
                                            target="_blank"
                                            rel='noopener noreferrer'
                                            href={messageSupport()}>
                                            <span variant="body1" style={{ fontSize: 16, color: '#48AC98', fontWeight: 700, cursor: 'pointer' }}>
                                                Ponte en contacto con la tienda
                                            </span>
                                        </a>
                                    </Typography>
                                </div>

                            </Card>
                            <Button
                                type='button'
                                className={classes.button}
                                variant="contained"
                                fullWidth
                                onClick={() => handleClick(loki.status)}
                            >
                                {loki.textButton}
                            </Button>
                        </React.Fragment>
                    }
                </React.Fragment>
            }
        </React.Fragment >
    );
}

export default ResultPayment;