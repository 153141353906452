import React from 'react';
import CryptoJS from 'crypto-js';
import { makeStyles } from "@material-ui/core/styles";
import PayU from './PayU';
import MercadoPago from './MercadoPago';
import Wompi from './Wompi';

const useStyles = makeStyles({
	divGhost: {
		display: 'none',
	}
});

const PaymentGateway = (props) => {
	const classes = useStyles();
	const firma = CryptoJS.MD5(`${props?.payApiKey}~${props?.merchantId}~${props?.refSale}~${props?.valueToPay}~COP`).toString();
	const location = window.location
	const BASE_URL = `https://${location.host}/checkout/${props.storeId}/${props.refSale}/result`
	localStorage.setItem("lastTypePay", props?.typePay);

	return (
		<div className={classes.divGhost}>
			{props.typePay === 'PayU' &&
				<PayU
					email={props.email}
					extra1={props.extra1}
					firma={firma || ''}
					idAccount={props.idAccount}
					merchantId={props.merchantId}
					name={props.name}
					address={props.address}
					city={props.city}
					country={props.country}
					newTab={props.newTabPayU}
					numberPhone={props.numberPhone}
					payDescripcion={props.payDescripcion}
					valueToPay={props.valueToPay}
					testPayU={props.testPayU}
					urlConfirmationPayU={props.urlConfirmationPayU}
					urlResponse={BASE_URL}
					refSale={props.refSale}
				//planId={props.planId}
				//typePay={props.typePay}
				/>
			}
			{props.typePay === 'Mercado Pago' &&
				<MercadoPago
					email={props.email}
					extra1={props.extra1}
					extra2={props.extra2}
					testMP={props.testMP}
					mpApiKey={props.mpApiKey}
					name={props.name}
					typeDocument={props.typeDocument}
					document={props.document}
					numberPhone={props.numberPhone}
					address={props.address}
					city={props.city}
					country={props.country}
					payDescripcion={props.payDescripcion}
					valueToPay={props.valueToPay}
					logoShop={props.logoShop}
					nameShop={props.nameShop}
					urlConfirmationMP={props.urlConfirmationMP}
					storeId={props.storeId}
					refSale={props.refSale}
				//payApiKey={props.payApiKey}
				//payReferencia={props.payReferencia}
				//planId={props.planId}
				//typePay={props.typePay}
				//urlResponse={BASE_URL}
				/>
			}
			{props.typePay === 'Wompi' &&
				<Wompi
					email={props.email}
					wompiApiKey={props.wompiApiKey}
					testWompi={props.testWompi}
					name={props.name}
					typeDocument={props.typeDocument}
					document={props.document}
					numberPhone={props.numberPhone}
					address={props.address}
					city={props.city}
					country={props.country}
					payDescripcion={props.payDescripcion}
					valueToPay={props.valueToPay}
					logoShop={props.logoShop}
					nameShop={props.nameShop}
					urlConfirmationWompi={props.urlConfirmationWompi}
					urlResponse={BASE_URL}
					refSale={props.refSale}
					signature={props.signature}
				/>
			}
		</div>
	);
}

export default PaymentGateway;