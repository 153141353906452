import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ChevronLeft = props => {
  return (
    <SvgIcon {...props}>
      <path d="M17,20.15l-7.7-8.1,7.6-8.1a1.2215,1.2215,0,0,0,.4-.9,1.7969,1.7969,0,0,0-.4-.9,1.7969,1.7969,0,0,0-.9-.4,1.0858,1.0858,0,0,0-.9.4l-8,8.5a2.3893,2.3893,0,0,0-.5,1.4,1.6125,1.6125,0,0,0,.6,1.3l8,8.5a1.4893,1.4893,0,0,0,.9.4,1.3718,1.3718,0,0,0,1.3-1.3A2.01,2.01,0,0,0,17,20.15Z"/>
    </SvgIcon>
  )
}

export default ChevronLeft
