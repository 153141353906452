// ReactJS
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

// Components
import PaymentGateway from '../PaymentGateway';

import { cleanShoppingCart } from '../../../utils/constants';

// External dependencies
import { makeStyles, Typography, Card, Grid, Radio, Button } from '@material-ui/core';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Stripe from 'stripe';
import { getQueryStringParams } from '../../../utils/constants';
import { getProductInventory } from '../../../services/inventory';
import { useSnackbar } from 'notistack'

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'

const useStyles = makeStyles({
    cardList: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    button: {
        color: '#ffffff',
        background: '#21c59f',
        boxShadow: '5px 5px 9px - 2px rgba(0, 205, 172,0.2), - 5px - 5px 9px 0px rgba(0, 205, 172, 0.14)',
        marginTop: 16,
    }
});

const StepPayment = ({ setStep, check, setCheck, store, setResultPayment, buyCart }) => {
    const { search } = useLocation();
    const query = getQueryStringParams(search)
    const classes = useStyles()
    const { storeId, orderId } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const location = window.location
    const BASE_URL = `${location.protocol}//${location.host}`

    const [appKey, setAppKey] = useState()
    const [currencyCOP, setCurrencyCOP] = useState(0)
    const [isPaymentGateway, setPaymentGateway] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentId, setPaymentId] = useState(null)
    const [isValidToBuy, setIsValidToBuy] = useState(null)
    const [signature, setSignature] = useState(null)
    const [statePayment, setStatePayment] = useState(
        Object.assign(
            JSON.parse(localStorage.getItem('StepDelivery')),
            { "totalPay": Number(localStorage.getItem('TotalPay')) },
            JSON.parse(localStorage.getItem('StepData'))
        )
    )

    const VALUE_COP = (statePayment.totalPay / currencyCOP).toFixed(2).toString()

    const handelChange = (e) => {
        setCheck(e.target.value)
        const methods = paymentMethods.map( p => p.payment_channel)
        const findMethod = methods.find( m => m.name === e.target.value )
        setPaymentId(parseInt(findMethod.id))
    }

    const validateStock = () => {
        buyCart && buyCart.forEach(item => {
            const { product_variant_id, product_id } = item;
            const selectedId = product_variant_id !== 0 ? product_variant_id : product_id;

            getProductInventory(store.url, selectedId).then(({ data }) => {
                // console.log('data', data);
                if (data.total < item.amount) {
                    enqueueSnackbar(`No hay suficiente stock para el producto ${item.name}`, {
                        variant: 'warning',
                        autoHideDuration: 4000
                    });
                    setIsValidToBuy(false);
                } else {
                    setIsValidToBuy(true);
                }
            });
        });

        isValidToBuy ? console.log('todo ok') : console.log('no hay stock');
        return isValidToBuy ? true : false;
        
    }

    useEffect(() => {
        validateStock();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyCart]);

    const onSubmit = () => {
        if (validateStock()) {
            axios.put(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store.id}/orders/${orderId}/shipping-info`, { 
                comments: statePayment.comments, 
                shipping_information: {
                    address: statePayment.address,
                    address_detail: statePayment.detailAddress,
                    city: statePayment.city,
                    city_id: statePayment.city_id,
                    country_code: 'CO',
                },
                customer: {
                    first_name: statePayment?.name.split(' ').shift(),
                    last_name: statePayment?.name.split(' ').pop(),
                    phone_number: `${parseInt(statePayment?.code)}${statePayment?.phone}`,
                    dni: statePayment?.document,
                    email: statePayment?.email,
                    document_type: statePayment?.typeDocument,
                },
                payment_channel_id: paymentId,
                order_method_id: 2
            }).then((response) => {
                //console.log('Response', response);
            }).catch((error) => {
                console.log('Error on update order', error);
            })
            

            switch (check) {
                case 'PayU':
                    setPaymentGateway(true)
                    localStorage.setItem('Step', 1)
                    break;
                case 'Mercado Pago':
                    setPaymentGateway(true)
                    localStorage.setItem('Step', 1)
                    break;
                case 'Wompi':
                    setPaymentGateway(true)
                    localStorage.setItem('Step', 1)
                    break;
                case 'Paypal':
                    createPaypalPayment()
                    localStorage.setItem('Step', 1)
                    break;
                case 'Stripe':
                    createStripePayment()
                    localStorage.setItem('Step', 1)
                    break;
                case 'Pago Manual':
                    localStorage.setItem('ManualPay', true)
                    localStorage.setItem('Step', 4)
                    setStep(4)
                    break;
                default:
                    // localStorage.setItem('ManualPay', true)
                    // localStorage.setItem('Step', 4)
                    // setStep(4)
                    break;
            }
            axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/orders/${orderId}/order-notification`)
              .then(res => console.log(res))
              .catch(err => console.log(err))
            ReactPixel.track('Purchase', {
                content_type: 'checkout',
              })
            cleanShoppingCart()
        } else {
            enqueueSnackbar('No hay suficiente stock para alguno de los productos', {
                variant: 'warning',
                autoHideDuration: 4000
            });
        }
    }
    const createPaypalPayment = () => {
        const URL_NOTIFICATION = paymentMethods.filter(method => method?.payment_channel?.name === 'Paypal')[0]?.notification_url

        localStorage.setItem('lastTypePay', 'PayPal')

        const body = {
            ipnNotificationUrl: `https://dev-api.wa10x.me${URL_NOTIFICATION}`,
            custom: orderId,
            intent: 'CAPTURE',
            purchase_units: [{
                reference_id: orderId,
                amount: {
                    currency_code: 'USD',
                    value: store?.currency === 'COP' ? VALUE_COP : statePayment.totalPay
                }
            }],
            application_context: {
                brand_name: store.name,
                landing_page: 'NO_PREFERENCE',
                user_action: 'PAY_NOW',
                return_url: `${BASE_URL}/checkout/${storeId}/${orderId}/`,
                cancel_url: `${BASE_URL}/checkout/${storeId}/${orderId}/`,
            },
            payment_instruction: {
                disbursement_mode: "INSTANT",
            }
        }

        const CLIENT_PAYPAL = paymentMethods.filter(method => method?.payment_channel?.name === 'Paypal')[0]?.client_id
        const TEST_MODE = paymentMethods.filter(method => method?.payment_channel?.name === 'Paypal')[0]?.test_mode

        axios.post(`${TEST_MODE ? 'https://api-m.sandbox.paypal.com' : 'https://api-m.paypal.com'}/v2/checkout/orders`, body, {
            auth: {
                username: CLIENT_PAYPAL,
                password: appKey?.Paypal
            },
            headers: {
                'Prefer': 'return=representation'
            }
        }).then((response) => {
            window.location.href = response?.data?.links[1]?.href;
        }).catch((error) => {
            console.log('Error on Payment', error);
        })
    }

    const createStripePayment = () => {
        const stripe = Stripe(appKey?.Stripe)

        const body = {
            success_url: "https://freespirit.wa10x.me/573186137341",
            cancel_url: "https://freespirit.wa10x.me/573186137341",
            line_items: [
                {
                    price_data: {
                        currency: 'USD',
                        product_data: {
                            name: 'Product2',
                            images: ['https: //i.imgur.com/EHyR2nP.png'],
                        },
                        unit_amount: 1899,
                    },
                    quantity: 1,
                }
            ],
            mode: "payment"
        }

        /* const session = await stripe.checkout.sessions.create(body);

        console.log(session); */

        //const CLIENT_STRIPE = paymentMethods.filter(method => method?.payment_channel?.name === 'Stripe')[0].client_id

        axios.post('https://api.stripe.com/v1/checkout/sessions', body, {
            headers: {
                //'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${appKey?.Stripe}`,
                'Content-Type': 'multipart/form-data',
                //'Authorization': `Bearer ${CLIENT_STRIPE}`,
            }
        })
            .then((response) => {
                console.log('Response', response)
            })
            .catch((error) => {
                console.log('Error on Payment', error)
            })
    }

    const handleStep = (number) => {
        localStorage.setItem("Step", number)
        setStep(number)
    }

    const Decrypt = useCallback((data) => {
        var payments = {}
        for (let i = 0; i < data.length; i++) {
            var iv = CryptoJS.enc.Hex.parse(data[i]?.app_key.substr(0, 32))
            var ct = CryptoJS.enc.Hex.parse(data[i]?.app_key.substr(32))
            var key = CryptoJS.enc.Utf8.parse("secret key456789")
            var decrypted = CryptoJS.AES.decrypt({ ciphertext: ct }, key, {
                mode: CryptoJS.mode.CBC,
                iv: iv
            })
            payments = Object.assign(payments, { [data[i]?.payment_channel?.name]: decrypted.toString(CryptoJS.enc.Utf8) })
        }
        return payments;
    }, [])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${store?.id}/payment-channels-store`)
            .then((response) => {
                setPaymentMethods(response?.data)
                setAppKey(Decrypt(response.data))
            })
            .catch((error) => {
                console.log(error);
            })
    }, [store])

    useEffect(() => {
        const CLIENT_PAYPAL = paymentMethods.filter(method => method?.payment_channel?.name === 'Paypal')[0]?.client_id
        const TEST_MODE = paymentMethods.filter(method => method?.payment_channel?.name === 'Paypal')[0]?.test_mode

        axios.get(`https://${TEST_MODE ? 'api-m.sandbox.paypal.com' : 'https://api-m.paypal.com'}/v2/checkout/orders/${query?.token ? query?.token : ''}`, {
            auth: {
                username: CLIENT_PAYPAL,
                password: appKey?.Paypal
            }
        })
            .then((response) => {
                setResultPayment(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [paymentMethods, appKey])

    let dataPayU = paymentMethods.find(d => d?.payment_channel?.name.toLocaleLowerCase() === "payu");
    let merchantPayU = dataPayU?.extra_parameters && JSON.parse(JSON.parse(dataPayU?.extra_parameters));
    let dataMercadoPago = paymentMethods.find(d => d?.payment_channel?.name.toLocaleLowerCase() === "mercado pago")
    let dataWompi = paymentMethods.find(d => d?.payment_channel?.name.toLocaleLowerCase() === "wompi")

    const urlMercadoPago = dataMercadoPago?.payment_channel?.payment_notification_url    
    const lastIndex = urlMercadoPago?.lastIndexOf('/')
    const newUrl = urlMercadoPago?.substring(0, lastIndex)
    const urlConfirmationMerPago = `${newUrl}/${store?.id}`
// console.log(`${process.env.REACT_APP_API_URL}${urlConfirmationMerPago}`)
    useEffect(() => {
        axios.get('https://api.exchangerate-api.com/v4/latest/USD')
            .then((response) => {
                setCurrencyCOP(response?.data?.rates?.COP);
            }).catch((error) => {
                console.log('Error on Payment', error);
            })
    }, [])

    //Facebook pixel
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
            store?.facebookPixelCode && ReactPixel.track('InitiateCheckout', {
                content_type: 'step payment',
            })
        }
        axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/payments/order/${orderId}/wompi/hash?price=${statePayment.totalPay}`)
        .then(res => setSignature(res.data))
    }, [store])

    return (
        <React.Fragment>
            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, padding: 8 }}>
                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6', padding: 8 }}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" style={{ fontSize: 16, color: '#53627C' }}>
                            Contacto
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{ fontSize: 16, color: '#48AC98', fontWeight: 700, cursor: 'pointer' }}
                            onClick={() => handleStep(1)}
                        >
                            Cambiar
                        </Typography>
                    </Grid>
                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                        {localStorage.getItem('StepData')
                            ? `+${localStorage.getItem('PaymentIndexPhone') ?? '57'} ${JSON.parse(localStorage.getItem('StepData'))?.phone}`
                            : '+57 0000000000'}
                    </Typography>
                    <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" style={{ fontSize: 16, color: '#53627C' }}>
                            Enviar a
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{ fontSize: 16, color: '#48AC98', fontWeight: 700, cursor: 'pointer' }}
                            onClick={() => handleStep(2)}
                        >
                            Cambiar
                        </Typography>
                    </Grid>
                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', textAlign: 'initial' }}>
                        {localStorage.getItem('StepDelivery')
                            ? `${JSON.parse(localStorage.getItem('StepDelivery'))?.address}, ${JSON.parse(localStorage.getItem('StepDelivery'))?.detailAddress ? JSON.parse(localStorage.getItem('StepDelivery'))?.detailAddress + ', ' : ''} ${JSON.parse(localStorage.getItem('StepDelivery'))?.departament ? JSON.parse(localStorage.getItem('StepDelivery'))?.departament + ', ' : ''} ${JSON.parse(localStorage.getItem('StepDelivery'))?.city ? JSON.parse(localStorage.getItem('StepDelivery'))?.city + ', ' : ''}  ${JSON.parse(localStorage.getItem('StepDelivery'))?.country}`
                            : 'Calle 100 # 43-34, Apartamento 1401, Medellín, Antioquia, Colombia'}
                    </Typography>
                </Card>
                <Typography variant="h1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700, marginTop: 20 }}>
                    Selecciona un método de pago
                </Typography>
                <Typography variant="body1" style={{ fontSize: 16, color: '#53627C', marginBottom: 20, marginTop: 8 }}>
                    Todas las transacciones son seguras y están encriptadas.
                </Typography>
                <Card elevation={0} className={classes.cardList} style={{ border: '1px solid #E6E6E6' }}>
                    {paymentMethods?.filter(m => m?.payment_channel?.name?.toLocaleLowerCase() !== 'whatsapp' ).map((paymentMethod, index) => (
                        <React.Fragment key={index}>
                            <Grid container style={{ display: 'flex', justifyContent: 'space-between', padding: 8, flexWrap: 'nowrap', alignItems: 'flex-start' }}>
                                <Radio
                                    checked={check === paymentMethod.payment_channel.name}
                                    value={paymentMethod.payment_channel.name}
                                    style={{ margin: '28px 0px 0px 0px' }}
                                    onChange={handelChange}
                                />
                                <Grid container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 10 }}>
                                    <img src={paymentMethod.payment_channel.icon} alt={paymentMethod.payment_channel.name} />
                                    <Typography variant="body1" style={{ fontSize: 14, color: '#0F2930', textAlign: 'initial' }}>
                                        {paymentMethod.payment_channel.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {index + 1 !== paymentMethods.length && <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />}
                        </React.Fragment>
                    ))}
                </Card>
            </Card>
            <Button
                type='button'
                className={classes.button}
                variant="contained"
                fullWidth
                disabled={!check}
                onClick={onSubmit}
            >
                Finalizar el pedido
            </Button>

            {isPaymentGateway &&
                <PaymentGateway
                    typePay={check}
                    refSale={orderId || ''}
                    storeId={storeId || ''}
                    /* PayU */
                    newTabPayU={false}
                    /* merchantId={dataPayU?.extra_parameters.replace(/['"]+/g, '')} */
                    merchantId={merchantPayU?.instructions}
                    idAccount={dataPayU?.client_id}
                    extra1={`PAGO a ${store?.name} en 10x`}
                    testPayU={dataPayU?.test_mode}
                    payApiKey={appKey?.PayU}
                    urlConfirmationPayU={dataPayU?.notification_url}
                    /* Mercado Pago */
                    urlConfirmationMP={urlConfirmationMerPago}
                    mpApiKey={appKey["Mercado Pago"]}
                    testMP={dataMercadoPago?.test_mode}
                    /* Wompi */
                    wompiApiKey={appKey?.Wompi}
                    urlConfirmationWompi={dataWompi?.notification_url}
                    testWompi={dataMercadoPago?.test_mode}
                    signature={signature?.signature}

                    email={statePayment.email || ''}
                    address={statePayment.address}
                    city={statePayment.city}
                    country={statePayment.country}
                    name={statePayment.name || ''}
                    typeDocument={statePayment.typeDocument || ''}
                    document={statePayment.document || ''}
                    numberPhone={String(statePayment.phone)}
                    payDescripcion={statePayment.comments || "No hay decripción"}
                    urlResponse={`${BASE_URL}/checkout/${storeId}/${orderId}/result`}
                    urlConfirmation={paymentMethods.notification_url}
                    logoShop={store.profilePicture}
                    nameShop={store.name}
                    valueToPay={statePayment.totalPay}
                />
            }
        </React.Fragment>
    );
}

export default StepPayment;