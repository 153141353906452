import React from 'react'
import { Typography, Container } from '@material-ui/core'

import ProductContentStyles from './ProductContent.module.scss'

const ProductContent = ({ children })  => {
  return (
    <section className={ProductContentStyles.productSection}>
      <Container>
        {children}
      </Container>
    </section>
  )
}

export default ProductContent
