import React, { useEffect } from 'react'

function Wompi(props) {
    const submitBtn = React.useRef(null);

    const WOMPI_URL = "https://checkout.wompi.co/p/";

    useEffect(() => {
        submitBtn.current.click()
    }, [props.typePay]);
  
    return (
        <>
            <form style={{ width: '0px', height: '0px' }} action={WOMPI_URL} method='GET'>
                {/* <!-- OBLIGATORIOS --> */}
                <input type="hidden" name="public-key" value={props.wompiApiKey} />
                <input type="hidden" name="currency" value="COP" />
                <input type="hidden" name="amount-in-cents" value={`${String(props.valueToPay)}00`} />
                <input type="hidden" name="reference" value={props.refSale} />
                <input type="hidden" name="signature:integrity" value={props.signature} />
                {/* <!-- OPCIONALES --> */}
                {/*  <input type="hidden" name="signature:integrity" value="FIRMA_DE_INTEGRIDAD" /> */}
                <input type="hidden" name="redirect-url" value={props.urlResponse} />
                <input type="hidden" name="customer-data:email" value={props.email} />
                <input type="hidden" name="customer-data:full-name" value={props.name} />
                <input type="hidden" name="customer-data:phone-number" value={props.numberPhone} />
                <input type="hidden" name="customer-data:legal-id" value={props.document} />
                <input type="hidden" name="customer-data:legal-id-type" value={props.typeDocument} />
                {/* <input type="hidden" name="tax-in-cents:vat" value="IVA_EN_CENTAVOS" /> */}
                {/* <input type="hidden" name="tax-in-cents:consumption" value="IMPOCONSUMO_EN_CENTAVOS" /> */}
                {/* <input type="hidden" name="shipping-address:address-line-1" value={props.address} /> */}
                {/* <input type="hidden" name="shipping-address:country" value="PAIS_DE_ENVIO" /> */}
                {/* <input type="hidden" name="shipping-address:phone-number" value={props.numberPhone} /> */}
                {/* <input type="hidden" name="shipping-address:city" value={props.city} /> */}
                {/* <input type="hidden" name="shipping-address:region" value="REGION_DE_ENVIO" /> */}
                <button ref={submitBtn} type="submit" style={{ opacity: '0' }}>Pagar con Wompi</button>
            </form>
        </>
    );
}

export default Wompi;
