/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory, useParams } from 'react-router-dom';

// External dependencies
import classNames from 'classnames/bind';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Carousel } from 'react-responsive-carousel';
import difference from 'lodash/difference';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { hotjar } from 'react-hotjar';

// Material UI dependencies
import {
  Button,
  Backdrop,
  CircularProgress,
  Container,
  Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Link from '@material-ui/core/Link';

// Internal components
import ProductContent from '../ProductContent'
import ProductExpansionPanel from '../ProductExpansionPanel'
import ProductMedia from '../ProductMedia'
import NavbarAlt from '../NavbarAlt'
import OptionsDrawer from '../OptionsDrawer'
import ShareButton from '../ShareButton'
import ShoppingBag from '../Icons/ShoppingBag';

// Internal dependencies
import { setCartState, setStoreState } from '../../modules/main';
import { PRODUCT_BY_SLUG_AND_PHONE_QUERY } from '../../qgl-queries/product';
import { STORE_BY_URL_QUERY } from '../../qgl-queries/store';
import { findValidUrl, formatNumber } from '../../utils/constants';

//Assets 
import Donwload from '../Icons/Donwload';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel';

//Google Analitycs
import ReactGA from "react-ga4";

// Component styles
import ProductStyles from './Product.module.scss'

/**
 * Renders the product page
 * @param {Function} setCartState setCartState from store
 * @param {Array} items cart items from store
 * @param {Object} store store object from store
 */
const Product = ({ setStoreState, setCartState, items, store }) => {
  const { /* storeUrl, */ phoneNumber, productSlug } = useParams();
  const storeUrl = window.location.hostname.split(".")[0]
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  
  // State variables
  const [beforeCartLoading, setBeforeCartLoading] = useState(false)
  const [hideProductOptions, setHideProductOptions] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [onlyOneOption, setOnlyOneOption] = useState(false);
  const [optionsForDrawer, setOptionsForDrawer] = useState(null)
  const [outOfStock, setOutOfStock] = useState(false)
  const [productAdditonalData, setProductAdditonalData] = useState([])
  const [productExpandInfo, setProductExpandInfo] = useState([])
  const [productFiles, setProductFiles] = useState(null)
  const [productOptions, setProductOptions] = useState([])
  const [productOptionsOpen, setProductOptionsOpen] = useState(false)
  const [productPrice, setProductPrice] = useState(0)
  const [selVariant, setSelVariant] = useState(undefined)
  const [singleProductAdded, setSingleProductAdded] = useState(false)

  const [productData, setProductData] = useState({
    name: '',
    price: '',
    description: '',
    productAttachments: [],
    productImages: [],
    productOptions: [],
    productVarianst: [],
    active: 1,
  });

  /* EFFECTS HERE ⬇️ */
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1702922, 6);

      /* Google Analytics */
      if (store?.googleAnalitycsTrackingId) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId
          },
          // {
          //   trackingId: 'UA-163783687-1'
          // }
        ]);
      }
      // else {
      //   ReactGA.initialize([
      //     {
      //       trackingId: 'UA-163783687-1'
      //     }
      //   ])
      // }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
      
      /* Pixel de Facebook */
      if(store?.facebookPixelCode) {
        store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode)
        store?.facebookPixelCode && ReactPixel.track('ViewContent', {
          content_name: productData.name,
          value: productData.price,
          currency: store.currency
        })
      }
    }
  }, []);
  
  // Product query
  const { loading, error, data } = useQuery(PRODUCT_BY_SLUG_AND_PHONE_QUERY, {
    variables: {
      slug: productSlug,
      phoneNumber: phoneNumber
    }
  });

  // Store query
  const {
    data: storeData
  } = useQuery(STORE_BY_URL_QUERY, {
    variables: { url: storeUrl },
    skip: store.id ? true : false
  });

  /**
   * Adds the current product to the cart
   */
  const addProductToCart = () => {
    setBeforeCartLoading(true);

    const productInCart = items.findIndex(item => item.id === productData.id);
    // If the product is already in the cart update the product's product options

    // Find the variant with the given options
    const optionsIds = productOptions.map(option => option.selected);
    const selectedVariant = productData.variants.find(variant => {
      return difference(optionsIds, variant.optionValues.map(value => value.id)).length === 0;
    });

    const variantPrice = selectedVariant ? selectedVariant.discountPrice || selectedVariant.price : null;
    const productPrice = productData.discountPrice || productData.price;
    let newCartData;

    if (productInCart >= 0) {
      newCartData = items.map((item, index) => {
        if (productInCart === index) {
          // check if original item match the selected variant
          const isOriginalVariant = selectedVariant ? item.variantId === selectedVariant.id : false;
          // chek if variant is inside the extraVariants array
          let extraVariants = item.extraVariants.map(variant => {
            let amount = variant.amount;
            if (selectedVariant) {
              amount = variant.variantId === selectedVariant.id ? variant.amount + 1 : variant.amount;
            }
            return {
              ...variant,
              amount: amount,
              active: 1,
            }
          });

          const extraVariantExists = item.extraVariants.find(variant => variant.variantId === selectedVariant.id);

          if (!extraVariantExists && selectedVariant && !isOriginalVariant) {
            extraVariants = [...extraVariants, {
              amount: 1,
              variantId: selectedVariant.id,
              sku: selectedVariant.sku,
              options: optionsIds,
              price: selectedVariant ? selectedVariant.discountPrice || selectedVariant.price || productData.discountPrice || productData.originalPrice : productData.discountPrice || productData.originalPrice,
              active: 1,
            }]
          }

          return {
            ...item,
            amount: isOriginalVariant ? item.amount + 1 : item.amount,
            active: 1,
            extraVariants
          }

        } else {
          return {
            ...item
          }
        }
      })
    } else {
      newCartData = [...items, {
        amount: 1,
        extraVariants: [],
        id: productData.id,
        image: productData.productImages.length > 0
          ? process.env.REACT_APP_IMAGES_API_URL + '/fit-in/96x96/' + productData.productImages[0].pictureUrl
          : '',
        imageSrcSet: productData.productImages > 0
          ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/96x96/${productData.productImages[0].pictureUrl} 1x, 
            ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/192x192/${productData.productImages[0].pictureUrl} 2x, 
            ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/288x288/${productData.productImages[0].pictureUrl} 3x`)
          : '',
        name: productData.name,
        originalPrice: productData.price,
        price: productData.price > 0 ? variantPrice || productPrice : 0,
        productOptions: productOptions,
        showMoreOptions: false,
        sku: productData.sku,
        variantId: selectedVariant ? selectedVariant.id : null,
        variants: productData.variants,
        variantsPrice: 0,
        active: 1,
      }];
    }
    
    //setVariantIdSelected(selectedVariant ? selectedVariant.id : null);
    setCartState(storeUrl, phoneNumber, newCartData, store);

    // Redirect to the cart page
    setTimeout(() => history.push(`/${phoneNumber}/order/products?ref=${nameCollection ? nameCollection : ''}`), 100);
  }

  /**
   * Adds the product to cart from the drawer
   * only used if the product has only one option
   * @param {number} optionId option value id 
   */
  const addProductToCartFromDrawer = optionId => {
    setSelectedOption(0, optionId, true);
  }

  const handleHaveStore = () => {
    const hasOptions = data.product.variants.length > 0;

    let hasAvailability

    if (hasOptions) {
      // Find the variant with the given options
      const optionsIds = productOptions.map(option => option.selected);
      const selectedVariant = productData.variants.find(variant => {
        return difference(optionsIds, variant.optionValues.map(value => value.id)).length === 0;
      });

      hasAvailability = data.product.variants.find(opt => opt.id === selectedVariant?.id)?.quantity === 0 ? false : true;
    } else {
      hasAvailability = data.product.quantityAvailable === 0 ? false : true;
    }

    /* if (data.product.quantityAvailable === 0 || !data.product.active) {
      setOutOfStock(true);
      enqueueSnackbar('Producto agotado', {
        variant: 'info',
        autoHideDuration: 6000
      });
    }
    else {
      setOutOfStock(false);
      addProductToCart();
    } */

    if (hasAvailability === false || !data.product.active) {
      setOutOfStock(true);
      enqueueSnackbar('Producto agotado', {
        variant: 'info',
        autoHideDuration: 6000
      });
      
      setTimeout(() => {
        setOutOfStock(false);
      }, 1500);
    }
    else {
      setOutOfStock(false);
      addProductToCart();
    }
  }

  const handleButtonClick = () => {
    onlyOneOption
      ? setProductOptionsOpen(true)
      : handleHaveStore();
      ReactPixel.track('AddToCart', {
        content_type: productData.name,
        value: productData.price,
        currency: store.currency
      })
    //: addProductToCart();
  }

  const handleButtonDisabledScroll = () => {
    if (!onlyOneOption && !isValid) {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Handle a product option selection
   * @param {number} optionIndex index of the options in the array
   * @param {number} value new value
   */
  const setSelectedOption = (optionIndex, value, singleAdded) => {
    setProductOptions(productOptions.map((option, index) => ({
      ...option,
      selected: optionIndex === index ? value || '' : option.selected
    })))
    if (singleAdded) setSingleProductAdded(true);
  }

  /**
   * closes options drawer
   */
  const handleOptionsDrawerClose = () => setProductOptionsOpen(false);

  /* EFFECTS HERE ⬇️ */

  // The form is valid only if all the productOptions are selected
  useEffect(() => {
    const allOptionsSelected = productOptions.filter(option => option.selected.length > 0).length === productOptions.length;
    const isOnlyOneOption = productOptions.length === 1 && productOptions[0].productOptionValues.length >= 2;
    setOnlyOneOption(isOnlyOneOption);
    let formIsValid = productOptions.length > 0 ? allOptionsSelected : true;
    if (isOnlyOneOption) formIsValid = true;
    setIsValid(formIsValid);
    setHideProductOptions(productOptions.length === 1 && productOptions[0].productOptionValues.length === 1);

    if (productOptions && productData.variants) {
      // Find the variant with the given options
      const optionsIds = productOptions.map(option => option.selected);
      const selectedVariant = productData.variants.find(variant => {
        return difference(optionsIds, variant.optionValues.map(value => value.id)).length === 0;
      })
      setSelVariant(selectedVariant)
      setProductPrice((selectedVariant && selectedVariant.price) ? selectedVariant.price : productData.price);
    }

    if (singleProductAdded) addProductToCart();
  }, [productOptions, singleProductAdded])

  // Set product data and options after graphql response
  useEffect(() => {
    if (data) {
      if (data.product) {
        document.title = data.product.name;
        setProductData({ ...data.product });
        setProductAdditonalData(data.product.productAttachments.length > 0 ? [data.product.productAttachments[0]].concat(data.product.productAdditionalInfo) : data.product.productAdditionalInfo)
        setProductPrice(data.product.price);
        const haveStockInVariants = data.product.variants.filter( v => v.quantity === null || v.quantity >= 1)
        const variantsWithStock = haveStockInVariants?.map( e => e.optionValues).flat()
        
        if (data.product.productOptions.length > 0) {
          setProductOptions(data.product.productOptions.map(option => ({
            ...option,
            selected: option.productOptionValues.length === 1 ? option.productOptionValues[0].id : ''
          })));
        }
        if (data.product.variants.length > 0) {
          setOptionsForDrawer(variantsWithStock)
          if (haveStockInVariants?.length === 0) {
            setOutOfStock(true);
            enqueueSnackbar('Producto agotado', {
              variant: 'info',
              autoHideDuration: 3000
            });
          }
        }
        //retirar cuando el endPoint del buscador este correcto
        if (!IsActive) {
          enqueueSnackbar(`El producto ${data.product.name} no está disponible en este momento`, {
            variant: 'error',
            autoHideDuration: 6000
          });
        }
      }
    }
  }, [data])
  // Save store data in the state and local storage using the setStoreState function
  useEffect(() => {
    if (!store.id && storeData) {
      if (storeData.store) {
        setStoreState(storeUrl, phoneNumber, items, {
          ...storeData.store,
          storeCollections: storeData?.store?.storeCatalogs[0]?.collections,
        });
      }
    }
    if (store.id) {
      // If the url is not valid redirect the user to the 404 page
      findValidUrl(store, phoneNumber, () => history.push('/404'));
    }
  }, [store, storeData])

  // Handle graphql error
  useEffect(() => {
    if (error) {
      enqueueSnackbar('No pudimos recuperar los datos del producto, inténtalo mas tarde', {
        variant: 'error',
        autoHideDuration: 6000
      })
    }
  }, [error])

  useEffect(() => {
    const files = productAdditonalData.find( fl => fl.fileUrl )
    const expand = productAdditonalData.filter( fl => !fl.fileUrl )
    setProductFiles(files)
    setProductExpandInfo(expand)
  }, [productAdditonalData])

  useEffect(() => {
    setTimeout(window.scrollTo(0, 0), 2500);
  }, [])
  
  /* EFFECTS HERE ⬆️ */

  const carouselSectionClass = classNames({
    [ProductStyles.carouselSection]: true,
    [ProductStyles.loading]: loading || error
  });

  const priceClass = classNames({
    [ProductStyles.price]: true,
    [ProductStyles.withDiscount]: (productData.discountPrice && !selVariant) || selVariant?.discountPrice,
    [ProductStyles.filesContainer]: true,
    [ProductStyles.filesSection]: true
  });
  const nameCollection = history.location.search.slice(5)
  const IsActive = data?.product?.active //retirar cuando el endPoint del buscador este correcto

  return (
    <Container
      style={{ textAlign: 'left' }}
      maxWidth="sm"
      disableGutters>
      <NavbarAlt
        cartItems={0}
        enableBackButton
        unableCart //desabled cart button
        cartUrl={`/${phoneNumber}/order/products`}
        goBackToStoreUrl={`/${phoneNumber}/collections/${nameCollection && nameCollection}`} />
      <section className={carouselSectionClass}>
        <Carousel
          showArrows={true}
          showStatus={false}
          showIndicators={true}
          showThumbs={true}
          emulateTouch={true}
          dynamicHeight={false}
          swipeable={true}>
          {productData.productImages.length > 0
            ?
            productData.productImages.map(productImage => (
              <ProductMedia
                key={productImage.id}
                image={productImage.pictureUrl}
                imageSrcSet={productImage.imageSrcSet}
                alt={productData.name} />
            ))
            :
            <ProductMedia
              alt={store.name} />
          }
        </Carousel>
      </section>
      <ProductContent>
        <div className={ProductStyles.productNameContainer} translate='no'>
          <Typography
            variant="h5"
            component="h1" >
            {productData.name}
          </Typography>
          <div className={ProductStyles.productShareBtn}>
            <ShareButton
              title={store.name}
              text={productData.name} />
          </div>
        </div>
        <Typography
          variant="caption"
          display="block"
          gutterBottom >
          {productData.sku}
        </Typography>
        {(productData.price || selVariant?.price > 0) &&
          <div className={ProductStyles.priceContainer}>
            {((productData.discountPrice && !selVariant) || (selVariant?.discountPrice !== null && selVariant?.discountPrice > 0 )) 
            ? (
              <Typography
                className={ProductStyles.discountPrice}
                variant="h4"
              >
                {formatNumber( selVariant?.discountPrice ? selVariant.discountPrice : productData.discountPrice , store.currency)}
              </Typography>
            ) 
            : null}
            <Typography
              className={priceClass}
              variant="h4">
              {formatNumber(productPrice, store.currency)}
            </Typography>
          </div>
        }
        {productData.description && (
          <Typography style={{ marginTop: 15, whiteSpace: 'pre-line' }} variant="body2" dangerouslySetInnerHTML={{ __html: productData.description }}></Typography>
        )}

        { productExpandInfo && productExpandInfo[0]?.title &&
          <div style={{ borderRadius: 8, marginTop: 0 }}>
            {productExpandInfo.map((item, index) => {
              return <React.Fragment key={index}>
                  <ProductExpansionPanel
                    summary={item.description}
                    title={item.title} 
                    />
                </React.Fragment>
                } )
              }
          </div>
        }
        { productFiles &&
          <div style={{ borderRadius: 8, marginTop: 15 }}>
            <div className={ProductStyles.filesSection}>
                <Typography><b>Archivos de este producto</b></Typography>
                  <div className={ProductStyles.filesContainer}>
                    <Donwload />
                    <Link style={{ margin: 5 }} target="_blank" href={process.env.REACT_APP_S3_URL + productFiles.fileUrl}>Ficha técnica</Link>
                  </div>
              </div>
          </div>
        }
        {(productOptions.length > 0 && !hideProductOptions) &&
          <div translate='no'>
            {!onlyOneOption &&
              <Typography
                display="block"
                variant="overline"
                gutterBottom>
                Opciones
              </Typography>
            }
            {!onlyOneOption && productOptions.map((option, optionIndex) =>
              <React.Fragment key={option.id}>
                <Typography
                  style={{ fontWeight: '600' }}
                  display="block"
                  variant="overline"
                  gutterBottom>
                  Selecciona {option.name}
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  value={option.selected}
                  onChange={(e, value) => setSelectedOption(optionIndex, value)}
                  className={ProductStyles.optionList}>
                  {option.productOptionValues.map(value =>
                    <ToggleButton
                      key={value.id}
                      value={value.id}
                      aria-label="left aligned">
                      {value.name}
                    </ToggleButton>
                  )}
                </ToggleButtonGroup>
              </React.Fragment>
            )}
          </div>
        }
        <Button
          disabled={!isValid || loading || outOfStock || !IsActive}
          className={ProductStyles.addToCartButton}
          variant="contained"
          color="primary"
          onClick={handleButtonClick}
          startIcon={<ShoppingBag fontSize="small" />}>
          <span onClick={handleButtonDisabledScroll}>Agregar al carrito</span>
        </Button>
        {(productOptions.length === 1 && productOptions[0].productOptionValues.length >= 2) && (
          <OptionsDrawer
            openDrawer={productOptionsOpen}
            closeHandler={handleOptionsDrawerClose}
            optionsName={productOptions[0].name}
            onProductAdded={addProductToCartFromDrawer}
            productOptionValues={optionsForDrawer}
          />
        )}
      </ProductContent>
      <Backdrop style={{ zIndex: 1400 }} open={beforeCartLoading}>
        <CircularProgress />
      </Backdrop>
    </Container>
  )
}

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store
});

const mapDispatchToProps = dispatch => bindActionCreators({ setCartState, setStoreState }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);