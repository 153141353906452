// ReactJS
import React from "react";

// MUI Components
import { Box, CircularProgress } from "@material-ui/core";

const Loader = () => (
    <Box
        style={{
            alignItems: 'center',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
    >
        <CircularProgress />
    </Box>
)

export default Loader;