import React from 'react'
import { useHistory, useParams } from 'react-router-dom';

import {
  AppBar,
  Badge,
  IconButton,
  Toolbar
} from '@material-ui/core'

import ChevronLeft from '../Icons/ChevronLeft';
import ShoppingBag from '../Icons/ShoppingBag';

import NavbarAltStyles from './NavbarAlt.module.scss'

export const isWindowLoaded = typeof window !== 'undefined' && window.window

const NavbarAlt = ({ cartItems, enableBackButton, enableCart, goBackToStoreUrl }) => {
  const { storeUrl, phoneNumber } = useParams();
  const history = useHistory();

  const goBack = () => history.push(goBackToStoreUrl);
  const goToCart = () => history.push(`/${storeUrl}/${phoneNumber}/order/products`);

  return (
    <AppBar
      color="transparent"
      position="fixed">
      <Toolbar className={NavbarAltStyles.navbarToolbar}>
        {enableBackButton ? (
        <IconButton
          className={NavbarAltStyles.navbarButton}
          onClick={goBack}
          aria-label="regresar"
          disableRipple>
          <ChevronLeft fontSize="small" />
        </IconButton>
        ) : null}
        {enableCart ? (
        <IconButton
          className={NavbarAltStyles.navbarButton}
          onClick={goToCart}
          color="primary"
          aria-label="carrito"
          disableRipple>
          <Badge
            color="primary"
            badgeContent={cartItems}>
            <ShoppingBag fontSize="small" />
          </Badge>
        </IconButton>
        ) : null}
      </Toolbar>
    </AppBar>
  )
}

export default NavbarAlt
