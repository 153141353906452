import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import {
  AppBar,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Grid,
  Toolbar,
  Typography,
  Button
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import OrderReviewPageStyles from './OrderReviewPage.module.scss'
import { STORE_CURRENCY_BY_ID } from '../../qgl-queries/storeCurrency';
import { formatNumber } from '../../utils/constants';

export const isWindowLoaded = typeof window !== 'undefined' && window.window

const OrderReviewPage = () => {
  const { orderId, storeId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [order, setOrder] = useState({ customer: {}, order_details: [] });
  const [loading, setLoading] = useState(false);

  // Store query
  const { data } = useQuery(STORE_CURRENCY_BY_ID, {
    variables: { id: storeId }
  });

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${storeId}/orders/${orderId}`)
      .then(res => {
        setLoading(false);
        setOrder(res.data);
      })
      .catch(err => {
        setLoading(false);
        enqueueSnackbar('La orden no existe', {
          variant: 'error',
          autoHideDuration: 6000
        });
        history.push('/404')
      });
  }, [])

  useEffect(() => {
    const recoverOrder = window.location.search.split('recoverOrder=')[1]
    if (recoverOrder && order.customer.phone_number) {
      recoverOrderWhatsApp();
    }
  }, [order])

  const recoverOrderWhatsApp = () => {
    const messageUrl = `https://wa.me/${order.customer.phone_number}?text=`
    const products = order.order_details.map(item => {
      return `${item.quantity} - *${item.product.name} ${item.product.sku}* ${item.order_item_option.lenght > 0 ? item.order_item_option[0].product_option_value.name : ''}/  ${`_${formatNumber(item.price, data ? data.currency : false)}_`} \n`
    });

    const message = `Hola, ${order.customer.first_name},
Este es tu pedido:
========================
${products.map(product => product.trim()).join('\n')}
========================
*TOTAL:* _${formatNumber(order.order_details.reduce((acc, current) => acc + (current.price * current.quantity), 0), data ? data.currency : false)}_
========================
Tu pedido: https://wa10x.me/order-review/${storeId}/${orderId}`

    //console.log(`${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`)
    window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`
  }

  /*   const confirmOrder = () => {
      if (isWindowLoaded) {
        const messageUrl = `https://wa.me/${order.phone_number}?text=`
        const message = `
  Hola, ${order.customer.first_name}, tu pedido ha sido confirmado.
  ========================
  Para pagar tu orden ingresa aquí:
  Tu pedido: http://wa10x.me/checkout/?o=${order.id}`
        window.location.href = `${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`
      }
    } */

  return (
    <div>
      {loading && <CircularProgress style={{ margin: '1rem' }} />}
      {!loading &&
        <Container
          className={OrderReviewPageStyles.orderReviewContainer}
          maxWidth="sm"
          disableGutters>
          <AppBar
            color="inherit"
            position="fixed"
          >
            <Toolbar className={OrderReviewPageStyles.topBarToolbar}>
              <Typography
                className={OrderReviewPageStyles.topBarTitle}
                align="center"
                display="block"
              >
                Resumen de tu orden
              </Typography>
              {/* <IconButton
                className={OrderReviewPageStyles.confirmOrderBtn}
                onClick={confirmOrder}
                edge="start"
                color="inherit"
                aria-label="confirmar"
                size="small"
              >
                <Check />
              </IconButton> */}
            </Toolbar>
          </AppBar>
          <Container>
            <section>
              <Typography variant="h5">Detalles del comprador:</Typography>
              <Card className={OrderReviewPageStyles.card}>
                <CardContent className={OrderReviewPageStyles.cardContent}>
                  <div>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      Nombre:&nbsp;
                    </Typography>
                    <Typography
                      variant="body1"
                      display="inline"
                      color="textSecondary"
                    >
                      {order.customer.first_name} {order.customer.last_name}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      Teléfono:&nbsp;
                    </Typography>
                    <Typography
                      variant="body1"
                      display="inline"
                      color="textSecondary"
                    >
                      {order.customer.phone_number}
                    </Typography>
                  </div>
                  {/*<div>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      Ciudad/Municipio:&nbsp;
                  </Typography>
                    <Typography
                      variant="body1"
                      display="inline"
                      color="textSecondary"
                    >
                      ciudad de destino
                    </Typography>
                  </div> */}
                  {/* <div>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      Dirección:&nbsp;
                  </Typography>
                    <Typography
                      variant="body1"
                      display="inline"
                      color="textSecondary"
                    >
                      Direccion de destino
                    </Typography>
                  </div> */}
                  {/* <div>
                    <Typography
                      variant="body1"
                      display="inline"
                    >
                      Detalles de la dirección:&nbsp;
                  </Typography>
                    <Typography
                      variant="body1"
                      display="inline"
                      color="textSecondary"
                    >
                      detalles de la direccion
                    </Typography>
                  </div> */}
                </CardContent>
              </Card>
              <Typography variant="h5">Ítems:</Typography>
              <div className={OrderReviewPageStyles.productList}>
                {order.order_details.map(item => (
                  <Card
                    key={item.id}
                    className={OrderReviewPageStyles.card}
                  >
                    <CardContent className={OrderReviewPageStyles.cardContent}>
                      <Grid
                        container
                        wrap="nowrap"
                        spacing={1}
                      >
                        {item.product.product_images && <Grid item>
                          <div className={OrderReviewPageStyles.productImage}>
                            <img
                              src={`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/96x96/${item.product.product_images.length ? item.product.product_images[0].picture_url : ''}`}
                              srcSet={(`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/96x96/${item.product.product_images.length ? item.product.product_images[0].picture_url : ''} 1x, 
                            ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/192x192/${item.product.product_images.length ? item.product.product_images[0].picture_url : ''} 2x, 
                            ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/288x288/${item.product.product_images.length ? item.product.product_images[0].picture_url : ''} 3x`)}
                              alt={item.product.name}
                            />
                          </div>
                        </Grid>}

                        <Grid item>
                          <div>
                            <Typography color="textSecondary" variant="h4">
                              {item.product.name}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              variant="caption"
                              dangerouslySetInnerHTML={{ __html: item.product.description }} />
                            {item.product.price > 0 ?
                              <>
                                <Typography>
                                  {formatNumber(item.price, data ? data.currency : false)}
                                  {` (${item.quantity})`}
                                </Typography>
                                <Typography color="textSecondary">
                                  {formatNumber(item.price * item.quantity, data ? data.currency : false)}
                                </Typography>
                              </>
                              :
                              <Typography>
                                {` (${item.quantity})`}
                              </Typography>
                            }

                            <ul className={OrderReviewPageStyles.options}>
                              {item.order_item_option.map(option => <li>
                                <strong>{option.product_option_value.name}</strong>
                              </li>)}
                            </ul>

                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </div>
              {order.order_details.reduce((prev, current) => current.product.price + prev, 0) > 0 &&
                <Card className={OrderReviewPageStyles.card}>
                  <CardContent className={OrderReviewPageStyles.cardContent}>
                    {order.order_details.reduce((prev, current) => current.product.price + prev, 0) > 0 &&
                      <>
                        <Typography color="textSecondary" gutterBottom>
                          SubTotal
                        </Typography>
                        <Typography variant="h4" component="p">
                          {formatNumber(order.order_details.reduce((acc, current) => acc + (current.price * current.quantity), 0), data ? data.currency : false)}
                        </Typography>
                      </>
                    }
                    {/*<Typography color="textSecondary" gutterBottom>
                      {`+Costos de envío`}
                    </Typography>*/}
                    {/*<Grid
                    container
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography color="textSecondary">
                        Entrega estimada
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {`Lunes 9 de marzo`}
                      </Typography>
                    </Grid>
                  </Grid>*/}
                  </CardContent>
                </Card>
              }
              {order.comments ? (
                <>
                  <br />
                  <Typography variant="h5" gutterBottom>Comentarios:</Typography>
                  <Card className={OrderReviewPageStyles.card}>
                    <CardContent className={OrderReviewPageStyles.cardContent}>

                      <div>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                        >
                          {order.comments}
                        </Typography>
                      </div>

                    </CardContent>
                  </Card>
                </>
              ) : null}
              {
                order.direct_sale &&
                <Button
                  color="primary"
                  variant="contained"
                  className={OrderReviewPageStyles.checkoutBtn}
                  component={RouterLink}
                  to={`/checkout/${storeId}/${orderId}`}>
                  Comprar en línea
                </Button>
              }

            </section>
          </Container>
        </Container>}

    </div>
  )
}

export default OrderReviewPage
