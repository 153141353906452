import React from 'react';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Chip, Typography } from '@material-ui/core'

import GalleryItemStyles from './GalleryItem.module.scss'
import { formatNumber } from '../../utils/constants';

const GalleryItem = ({ url, alt, thumbnail, productName, productPrice, productDiscountPrice, loading, currency }) => {
  const image_src = JSON.stringify({
    "bucket": "wa10x.v2",
    "key": `${thumbnail}`,
    "edits": {
      "resize": {
        "width": 300,
        "height": 300,
        "fit": "cover"
      },
      "rotate": null,
    }
  })
  const image_src2 = JSON.stringify({
    "bucket": "wa10x.v2",
    "key": `${thumbnail}`,
    "edits": {
      "resize": {
        "width": 500,
        "height": 500,
        "fit": "cover"
      },
      "rotate": null,
    }
  })
  const image_src3 = JSON.stringify({
    "bucket": "wa10x.v2",
    "key": `${thumbnail}`,
    "edits": {
      "resize": {
        "width": 700,
        "height": 700,
        "fit": "cover"
      },
      "rotate": null,
    }
  })

  const productThumbnail = thumbnail ? `${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(image_src).toString('base64')}` : `/10x-product.jpg`
  const productThumbnail2 = thumbnail ? `${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(image_src2).toString('base64')}` : `/10x-product.jpg`
  const productThumbnail3 = thumbnail ? `${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(image_src3).toString('base64')}` : `/10x-product.jpg`
  const cardClass = classNames({
    [GalleryItemStyles.card]: true,
    [GalleryItemStyles.loading]: loading
  });

  const priceClass = classNames({
    [GalleryItemStyles.price]: true,
    [GalleryItemStyles.withDiscount]: productDiscountPrice
  });

  return (
    <Link
      component={RouterLink}
      to={url}
      className={GalleryItemStyles.item}
    >
      <div className={cardClass} translate='no'>
        <figure className={GalleryItemStyles.image}>
          <img
            srcSet={`${productThumbnail} 1x,
            ${productThumbnail2} 2x,
            ${productThumbnail3} 3x`}
            src={`${productThumbnail}`}
            alt={alt}
          />
          {productDiscountPrice ? (
            <Chip
              className={GalleryItemStyles.discountChip}
              label="¡PROMO!"
            />
          ) : null}
        </figure>
        <Typography
          className={GalleryItemStyles.caption}
          display="block"
          variant="overline"
        >
          {productName}
        </Typography>
        {productPrice > 0 &&
          <div className={GalleryItemStyles.priceContainer}>
            {productDiscountPrice ? (
              <Typography
                className={GalleryItemStyles.discountPrice}
                variant="caption"
              >
                {formatNumber(productDiscountPrice, currency)}
              </Typography>
            ) : null}
            <Typography
              className={priceClass}
              variant="caption"
            >
              {formatNumber(productPrice, currency)}
            </Typography>
          </div>
        }
      </div>
    </Link>
  )
}

export default GalleryItem
