import React from 'react';
import ReactDOM from 'react-dom';
import Provider from './utils/Provider';

import store, { history } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';

//React cache buster 
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json'; 

const isProduction = process.env.NODE_ENV === 'production';

ReactDOM.render(
  <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false} 
    >
    <Provider store={store} history={history}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </CacheBuster>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
