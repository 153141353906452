import gql from 'graphql-tag';

export const STORE_BY_URL_QUERY = gql`
query Store($url: String!){
  store(url: $url){
      id
      url
      name
      description
      profilePicture
      bannerPicture
      shippingDetails
      shippingTimeEnabled
      deliveryCostEnabled
      deliveryCostDetails
      verified
      branding
      currency
      callToActionButton
      facebookPixelCode
      googleAnalitycsTrackingId
      wholesaleRules{
        id,
        minimumCartValue,
        userTags,
        wholesaleRuleType{
          id,
          name
        },
        wholesaleRuleProducts{
          id,
          type,
          product{
            id,
            name,
            price
          },
          min,
          max,
          value,
          percentage
        }
      }
      storePlanStatus{
        id
        name
      }
      userStores {
        id
        mobilePhoneNumber
        default
        directSaleEnabled
      }
      storeCatalogs {
          collections {
              id
              name
              public
              pictureUrl
              slug
          }
      }
  }
}
`;