// ReactJS
import React from "react";

// MUI Components
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";

const LoaderRedirect = () => (
    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>

        <Box
            style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress />
        </Box>
        <Typography variant="body1" style={{ fontSize: 22, color: '#0F2930', fontWeight: 700, margin: '16px 0px' }}>
            Redirigiendo...
        </Typography>
        <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
            Espera mientras de redirigimos
        </Typography>
    </Grid>
)

export default LoaderRedirect;