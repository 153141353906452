import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'

import OptionsDrawerStyles from './OptionsDrawer.module.scss'

const OptionsDrawer = ({ openDrawer, closeHandler, optionsName, productOptionValues, onProductAdded })  => {
  return (
    <Drawer
      anchor="bottom"
      open={openDrawer}
      onClose={closeHandler}
    >
      <div className={OptionsDrawerStyles.optionsWrapper}>
        <Typography
          variant="h5"
          align="center"
        >
          Seleccionar {optionsName}:
        </Typography>
        <List
          className={OptionsDrawerStyles.list}
          component="nav"
          aria-label="opciones"
        >
          {productOptionValues && productOptionValues.length > 0 ? (productOptionValues.map(productOptionValue => (
            <ListItem
              key={productOptionValue.id}
              className={OptionsDrawerStyles.listItem}
              onClick={() => onProductAdded(productOptionValue.id)}
              button
            >
              <ListItemText
                align="center"
                primary={productOptionValue.name}
              />
            </ListItem>
          ))) : null}
        </List>
      </div>
    </Drawer>
  )
}

export default OptionsDrawer
