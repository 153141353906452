import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';


import {
  Badge,
  CircularProgress,
  Drawer,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  CheckCircle,
  Close,
  Magnify,
  Whatsapp
} from 'mdi-material-ui';

import ShoppingBag from '../Icons/ShoppingBag';

import HeaderStyles from './Header.module.scss';

import SearchDialog from '../SearchDialog/SearchDialog'

const Header = ({ storeId, shopTitle, shopLogo, shopDescription, shopCover, verified, cartUrl, productSubUrl ,cartItems, shopType, loading }) => {
  const shopLogoImageSrc = shopLogo 
                             ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/500x500/${shopLogo}`)
                             : (`https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=90&background=266678&color=fff`);
  const shopLogoImageSrcSet = shopLogo 
                             ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/500x500/${shopLogo} 1x, 
                                 ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/1000x1000/${shopLogo} 2x, 
                                 ${process.env.REACT_APP_IMAGES_API_URL}/fit-in/1500x1500/${shopLogo} 3x`) 
                             : (`https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=90&background=266678&color=fff 1x,
                                 https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=180&background=266678&color=fff 2x,
                                 https://ui-avatars.com/api/?name=${shopTitle || `10X&length=3`}&size=270&background=266678&color=fff 3x`);

  const shopCoverImage = shopCover ? `${process.env.REACT_APP_IMAGES_API_URL}/fit-in/1200x1200/${shopCover})` : ``;

  const bannerClass = classNames({
    [HeaderStyles.coverWrapper]: true,
    [HeaderStyles.loading]: loading
  });

  const avatarClass = classNames({
    [HeaderStyles.avatar]: true,
    [HeaderStyles.loading]: loading
  });

  const titleClass = classNames({
    [HeaderStyles.shopTitle]: true,
    [HeaderStyles.loading]: loading
  });

  const searchClass = classNames({
    [HeaderStyles.headerSearchBtn]: true,
    [HeaderStyles.headerSearchBtnCart]: cartItems
  });

  // const [searchDrawerState, setSearchDrawerState] = useState(false);
  const [value, setValue] = useState('')
  
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loadingResults = open && options.length === 0;

  const handleSearchDrawerOpen = () => setOpen(prev => !prev);

  // const handleSearchDrawerClose = () => setSearchDrawerState(false);

  // const handleOpen = () => {
  //   if (inputValue.length >= 3) {
  //     setOpen(true);
  //   }
  // };

  // const handleInputChange = (event, newInputValue) => {
  //   setInputValue(newInputValue);
  //   if (newInputValue.length >= 3) {
  //     setOpen(true);
  //   } else {
  //     setOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   let active = true;

  //   if (!loadingResults) {
  //     return undefined;
  //   }

  //   //if (inputValue.length >= 3) {
  //     axios.get(`${process.env.REACT_APP_API_URL}/stores/${storeId}/products/public-search?term=${inputValue}`).then(res => {
  //       const products = res.data;
  //       if (active && products) {
  //         setOptions(Object.keys(products).map((key) => products[key]));
  //       }
  //     });
  //   //}

  //   return () => {
  //     active = false;
  //   };
  // }, [loadingResults]);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <header className={HeaderStyles.header} translate='no'>
      <div className={bannerClass} style={{ backgroundImage: `url(${shopCoverImage}` }}>
      {
        !cartItems &&
        <Link
          className={HeaderStyles.headerCartBtnLink}
          component={RouterLink}
          to={cartUrl}
        >
          <Badge
            color="secondary"
            variant="dot"
            invisible={cartItems}
            overlap="circle"
          >
            <IconButton
              className={HeaderStyles.headerCartBtn}
              color="primary"
              aria-label="Carrito"
            >
              <ShoppingBag fontSize="small" />
            </IconButton>
          </Badge>
        </Link>
      }
        <IconButton
          className={searchClass}
          color="primary"
          aria-label="Buscar"
          onClick={handleSearchDrawerOpen}
        >
          <Magnify fontSize="small" />
        </IconButton>
      </div>
      <div className={HeaderStyles.content}>
        <div className={HeaderStyles.shopBranding}>
          <div className={avatarClass}>
            {!loading && <img
              srcSet={shopLogoImageSrcSet}
              src={shopLogoImageSrc}
              alt={shopTitle}
            />}
          </div>
          <div className={HeaderStyles.titleContainer}>
            <Typography
              className={titleClass}
              component="h1"
              variant="h2"
            >
              {shopTitle}
              {!loading && verified && <VerifiedTooltip
                title="Tienda verificada"
                aria-label="Tienda verificada"
                placement="right"
                enterTouchDelay={0}
                arrow
                >
                <div className={HeaderStyles.verifiedBadge}>
                  <CheckCircle
                    className={HeaderStyles.verifiedBadgeIcon}
                    color="secondary"
                    fontSize="small"
                  />
                </div>
              </VerifiedTooltip>}
            </Typography>
            <div className={HeaderStyles.whatsappBadge}>
              <Whatsapp className={HeaderStyles.whatsappBadgeIcon} /> <span className={HeaderStyles.whatsappBadgeCaption}>{!shopType ? `Tienda WhatsApp` : `Tienda WhatsApp Mayorista`}</span>
            </div>
          </div>
        </div>
        <Typography
          variant="body1"
          gutterBottom
        >
          {shopDescription}
        </Typography>
      </div>
      { open &&
        <SearchDialog 
          close={handleSearchDrawerOpen} 
          storeId={storeId} 
          image={shopLogoImageSrc} 
          productSubUrl={productSubUrl}
        />
      }
      {/* <Drawer
        anchor="top"
        open={searchDrawerState}
        onClose={handleSearchDrawerClose}
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'row',
          }
        }}
      >
        <Autocomplete
          id="search-store-autocomplete"
          disableClearable
          value={value || ''}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          style={{ width: '100%' }}
          open={open}
          onOpen={handleOpen}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => (
            option ? option.name : ''
          )}
          options={options}
          loading={loadingResults}
          loadingText="Cargando..."
          noOptionsText="No hay resultados"
          renderOption={option => (
            <RouterLink
              to={`${productSubUrl}/${option.url}`}
              style={{
                textDecoration: 'none',
                color: 'rgba(15, 41, 48, 0.75)',
                lineHeight: 'inherit',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: '0',
                lineHeight: '18px',
              }}
            >
              {option.picture_url ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/48x48/${option.picture_url}`}
                  alt={option.name}
                  style={{
                    marginRight: '16px',
                    width: '48px',
                    minWidth: '48px',
                    height: '48px',
                    overflow: 'hidden',
                    borderRadius: '4px'
                  }}
                />
              ) : (
                <img
                  src={`/10x-product.jpg`}
                  alt={option.name}
                  style={{
                    marginRight: '16px',
                    width: '48px',
                    minWidth: '48px',
                    height: '48px',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    verticalAlign: 'middle',
                  }}
                />
              )}
              <span
                style={{
                  display: 'inlineBlock',
                }}
              >
                {option.name}
              </span>
            </RouterLink>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Buscar"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingResults ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                style: {
                  paddingBottom: 16,
                  paddingTop: 16,
                  boxShadow: 'none',
                  background: 'none'
                }
              }}
            />
          )}
        />
        <IconButton
          aria-label="close"
          onClick={handleSearchDrawerClose}ç
        >
          <Close />
        </IconButton>
      </Drawer> */}
    </header>
  )
}

// Customize the VerifiedTooltip Component
function VerifiedTooltip(props) {
  const classes = verifiedTooltipStyles()
  return (
    <Tooltip arrow classes={classes} {...props} />
  )
}

// Material-UI Styles
const verifiedTooltipStyles = makeStyles(theme => ({
  popper: {
    marginLeft: '-20px',
  },
}));

export default Header
