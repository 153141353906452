/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { useState, useEffect, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import debounce from "just-debounce-it";

// External dependencies
import { useQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";

// Material UI dependencies
import {
  Container,
  Grid,
  Typography,
  Link,
  Box,
  CircularProgress,
} from "@material-ui/core";

// Internal components
import Category from "../Category";
import CategoryGroup from "../CategoryGroup";
import Gallery from "../Gallery";
import GalleryItem from "../GalleryItem";
import Header from "../Header";
import WhatsAppWidget from "../../components/WhatsAppWidget/WhatsAppWidget.js";
import Loader from "../Loader";

// Internal dependencies
// import { setStoreState,  } from '../../modules/main';
import {
  setHomeScrollPosition,
  setStoreCategoryId,
  setStoreLimit,
  setStoreProduct,
  setStoreReset,
  setStoreState,
  setStoreTotalProducts,
} from "../../modules/main";
import { STORE_BY_URL_QUERY } from "../../qgl-queries/store";
import { findValidUrl, getStoreHeader } from "../../utils/constants";

//Pixel FACEBOOK
import ReactPixel from "react-facebook-pixel";

//Google Analitycs
import ReactGA from "react-ga4";

// styles
import StoreStyles from "./Store.module.scss";
import NotFound from "../NotFound";

/**
 * Renders the Store page
 * @param {Array} items cart items from store
 * @param {Object} store store object from store
 */
const Store = ({
  items,
  store,
  limit,
  products,
  currentCategoryId,
  homeScrollPosition,
  totalProducts,
  setStoreState,
  setStoreLimit,
  setStoreReset,
  setStoreProduct,
  setStoreCategoryId,
  setHomeScrollPosition,
  setStoreTotalProducts,
}) => {
  // const visorRef = useRef()
  const { phoneNumber, nameCollection } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const storeHeader = getStoreHeader();
  const history = useHistory();
  const storeLocation = window.location.hostname.split(".")[0];
  const storeUrl = storeHeader ? storeHeader : storeLocation;

  // State variables
  const [showingCollection, setShowingCollection] = useState(false);
  const [storeData, setStoreData] = useState({
    userMobileNumber: "",
    storeName: "",
    profilePicture: "",
    storeDescription: "",
    bannerPicture: "",
    url: "",
    minimumCartValue: null,
    verified: false,
    storeCollections: [],
  });
  const [cartItems, setCartItems] = useState(0);
  const [id, setID] = useState("");
  const [isEmpty, setEmpty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [wa, setWa] = useState(true);
  const [publicCategories, setPublicCategories] = useState([]);

  // Store query
  const { loading, error, data } = useQuery(STORE_BY_URL_QUERY, {
    variables: { url: storeUrl },
  });

  const debounceLimit = useCallback(
    debounce(() => setStoreLimit(30), 1000),
    []
  );

  const getStoreWithInfinityScroll = (currentCategory) => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/store/${storeUrl}/products`,
      params: {
        collection: currentCategory,
        limit: 30,
        offset: limit,
      },
    })
      .then((res) => {
        setID(res.data.id);
        setStoreProduct(res.data.products);
        currentCategory !== "" &&
          setStoreTotalProducts(res.data.total_products);
        setLoading(false);
        debounceLimit();
      })
      .catch((err) => {
        console.log(err);
        setEmpty(true);
        setLoading(false);
      });
  };
  // const lastProduct = useCallback(node => {
  //   if (!node) return
  //   if (visorRef.current) visorRef.current.disconnect()

  //   const options = {
  //     root: null,
  //     rootMargin: '-100px 0px 0px 0px',
  //     threshold: 0.1,
  //   }

  //   visorRef.current = new IntersectionObserver(entries => {
  //     if (entries[0].isIntersecting) {
  //       debounceLimit()
  //       getStoreWithInfinityScroll(currentCategory)
  //     }
  //   }, options)
  //   visorRef.current.observe(node)
  // }, [limits, currentCategory])

  // Create empty array to populate it with products
  let allProducts = [];

  // Filter all products by collection
  if (products?.length !== 0) {
    id && allProducts.push(...products);
  }

  // Remove duplicated products
  const filteredProducts = uniqBy(products, "id").filter(
    (product) => product?.quantityAvailable !== 0 && product?.active
  );

  // Filter all products by date
  const orderedProducts = orderBy(filteredProducts, ["created_at"], ["asc"]);

  const updateCurrentCategory = (id) => {
    const nameCategory = data?.store?.storeCatalogs[0]?.collections.find(
      (col) => col?.id === id
    )?.slug;

    let phone;
    const currentPhone = () => {
      if (phoneNumber === "undefined" || phoneNumber === "404") {
        phone = data?.store?.userStores[0]?.mobilePhoneNumber;
      } else {
        phone = phoneNumber;
      }
    };

    currentPhone();
    if (id !== currentCategoryId) {
      setStoreReset();
    }
    setStoreCategoryId(id);
    setShowingCollection(true);
    setTimeout(() => setShowingCollection(false), 250);

    history.push(`/${phone}/collections/${nameCategory}`);
  };

  const showLoader = store.id ? false : loading;
  const saveScrollPosition = () => setHomeScrollPosition(window.scrollY);

  /* EFFECTS HERE ⬇️ */
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // hotjar.initialize(1702922, 6);

      /* Google Analytics */
      if (store?.googleAnalitycsTrackingId) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId,
          },
          // {
          //   trackingId: 'UA-163783687-1'
          // }
        ]);
      }
      // else{
      //   ReactGA.initialize([
      //     {
      //       trackingId: 'UA-163783687-1'
      //     }
      //   ])
      // }
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });

      /* Facebook Pixel */
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.pageView();
    }
    const cartItems = items.map((item) => ({ ...item }));
    setCartItems(cartItems.reduce((prev, current) => prev + current.amount, 0));
  }, []);

  useEffect(() => {
    window.document.addEventListener("scroll", saveScrollPosition);

    setTimeout(() => {
      window.scroll({
        top: homeScrollPosition,
        behavior: "auto",
      });
    });

    return () =>
      window.document.removeEventListener("scroll", saveScrollPosition);
  }, []);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        "No pudimos recuperar los datos de la tienda, inténtalo mas tarde",
        {
          variant: "error",
          autoHideDuration: 6000,
        }
      );
    }
    if (
      (storeData &&
        storeData.storePlanStatus &&
        storeData.storePlanStatus.name === "Inhabilitado") ||
      (storeData.storePlanStatus &&
        storeData.storePlanStatus.name === "Prueba Fallida")
    ) {
      enqueueSnackbar("Esta tienda 10X requiere atención.", {
        variant: "error",
        persist: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      setWa(false);
    }
  }, [error, store]);

  useEffect(() => {
    if (data) {
      if (data.store === null || data.store.id === "") {
        console.log("Shop not found");
      } else {
        document.title = data.store.name;

        setStoreData({
          ...data.store,
          storeCollections: data.store.storeCatalogs[0].collections.filter(
            (collection) => collection.public
          ),
        });
        setStoreState(storeUrl, phoneNumber, items, data.store);
      }
    }
    if (store.id) {
      const getValidUrl = () => {
        const defaultUserStore = store.userStores.find((us) => us.default);
        const firstUserStore = store.userStores[0];

        return defaultUserStore || firstUserStore;
      };
      const setHistory = () =>
        history.push(`/${getValidUrl().mobilePhoneNumber}`);
      if (phoneNumber === undefined) {
        setHistory();
      }
      findValidUrl(store, phoneNumber, setHistory);
    }
  }, [data, store]);

  useEffect(() => {
    let category = [];
    if (data) {
      const pub =
        data?.store?.storeCatalogs[0]?.collections?.filter(
          (collection) => collection.public
        ).length > 0
          ? data?.store?.storeCatalogs[0]?.collections?.filter(
              (collection) => collection.public
            )
          : [];
      setPublicCategories(pub ? pub : []);
      if (nameCollection) {
        if (pub.length > 0) {
          const validate = pub.filter(
            (collection) =>
              collection?.slug?.toUpperCase() === nameCollection?.toUpperCase()
          );

          if (validate.length === 0) {
            category = [pub[0]];
          } else {
            category = [validate[0]];
          }
        } else {
          category = "";
        }
      } else {
        category = pub && [pub[0]];
        if (category.length === 0 && nameCollection !== "undefined") {
          category = data?.store?.storeCatalogs[0]?.collections.filter(
            (collection) => collection?.slug?.toUpperCase() === "TODO"
          );
        }
      }
    }
    category?.length > 0 && updateCurrentCategory(category[0]?.id);
  }, [data]);

  useEffect(() => {
    if (limit === 0 && currentCategoryId) {
      getStoreWithInfinityScroll(currentCategoryId);
      isEmpty && setEmpty(false);
    }
  }, [limit, currentCategoryId]);

  /* EFFECTS HERE ⬆️ */

  return data?.store === null || data?.store?.id === "" || error ? (
    <NotFound storeName={storeUrl} />
  ) : loading ? (
    <Loader />
  ) : (
    <Container maxWidth="sm" disableGutters>
      <Header
        loading={showLoader}
        storeId={storeData.id}
        storeNumber={storeData.userMobileNumber}
        shopTitle={storeData.name}
        shopLogo={storeData.profilePicture}
        shopDescription={storeData.description}
        shopCover={storeData.bannerPicture}
        verified={storeData.verified}
        cartUrl={`/${phoneNumber}/order/products`}
        productSubUrl={`/${phoneNumber}`}
        cartItems={cartItems === 0}
        shopType={storeData.minimumCartValue}
      />
      {publicCategories.length > 1 && (
        <CategoryGroup
          isInactive={
            (storeData &&
              storeData.storePlanStatus &&
              storeData.storePlanStatus.name === "Inhabilitado") ||
            (storeData.storePlanStatus &&
              storeData.storePlanStatus.name === "Prueba Fallida")
              ? true
              : false
          }
          categoriesQty={storeData.storeCollections.length}
        >
          {showLoader && (
            <Category
              loading={showLoader}
              key={"all"}
              id={null}
              current={currentCategoryId}
              setActive={updateCurrentCategory}
              image={storeData.profilePicture}
              caption={"Todo"}
            />
          )}
          {storeData.storeCollections.length !== 0
            ? storeData.storeCollections.map((collection, index) => (
                <Category
                  index={index}
                  loading={showLoader}
                  key={collection.id}
                  id={collection.id}
                  current={currentCategoryId}
                  setActive={updateCurrentCategory}
                  image={collection.pictureUrl}
                  caption={collection.name}
                />
              ))
            : null}
        </CategoryGroup>
      )}
      <Gallery
        isInactive={
          (storeData &&
            storeData.storePlanStatus &&
            storeData.storePlanStatus.name === "Inhabilitado") ||
          (storeData.storePlanStatus &&
            storeData.storePlanStatus.name === "Prueba Fallida")
            ? true
            : false
        }
        fadeCollection={showingCollection}
      >
        {showLoader &&
          [1, 2, 3, 4].map((product) => (
            <GalleryItem
              loading={showLoader}
              key={product}
              url=""
              alt=""
              thumbnail=""
              productName=""
              productPrice=""
              currency={storeData.currency}
            />
          ))}
        {!showLoader &&
          orderedProducts.map((product, index) => (
            <GalleryItem
              currency={storeData.currency}
              loading={showLoader}
              key={product.id}
              url={`/${phoneNumber}/${product.slug}?ref=${nameCollection}`}
              alt={product.name}
              thumbnail={
                product?.product_images?.length > 0
                  ? product.product_images[0].picture_url
                  : ``
              }
              productName={product.name}
              productPrice={product.price}
              productDiscountPrice={product.discount_price}
            />
          ))}
      </Gallery>
      {isLoading ? (
        <Box
          style={{ height: "100%", marginBottom: "40px" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : null}

      {!isEmpty && !isLoading && totalProducts > orderedProducts.length && (
        <p
          className={StoreStyles.load}
          onClick={() => getStoreWithInfinityScroll(currentCategoryId)}
        >
          Cargar más productos
        </p>
      )}
      {storeData.branding && (
        <Grid container className={StoreStyles.get10X}>
          <Link
            href="https://ecometri.com/"
            target="_blank"
          >
            {" "}
            <img
              src="/logo_horizontal.png"
              alt="ecometri"
              style={{ width: "100px", marginBottom: "8px" }}
            />
          </Link>
          <Typography style={{ fontSize: "14px" }}>
            ¿Quieres tener tu propio catálogo de productos?
          </Typography>
          <Link
            href="https://ecometri.com/"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            {" "}
            Click aquí
          </Link>
        </Grid>
      )}
      {window.scrollY > 450 && (
        <div className={StoreStyles.main}>
          <div
            className={StoreStyles.root}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14.4 9.60001L14.4 10.6667C14.4 10.8725 14.2811 11.0603 14.0949 11.1488C13.9088 11.2373 13.6885 11.2101 13.5285 11.0795L7.99999 6.55575L2.47093 11.0795C2.31146 11.2101 2.09119 11.2368 1.90453 11.1488C1.71786 11.0608 1.59999 10.8725 1.59999 10.6667L1.59999 9.60001C1.59999 9.44001 1.672 9.28855 1.79573 9.18721L7.66239 4.38721C7.85919 4.22668 8.14133 4.22668 8.33813 4.38721L14.2048 9.18721C14.328 9.28855 14.4 9.44001 14.4 9.60001Z"
                fill="#53627C"
              />
            </svg>
          </div>
        </div>
      )}
      {wa && (
        <WhatsAppWidget
          phoneNumber={phoneNumber}
          textReplyTime={false}
          message="¡Hola! 👋🏼  ¿En qué te puedo ayudar?"
          companyName={storeData.name}
          placeholder="Escribe un mensaje"
          sendButton="Enviar"
          shopLogo={storeData.profilePicture}
        />
      )}

      {/* <BrandingCard /> */}
    </Container>
  );
};

const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store,
  limit: main.limit,
  products: main.products,
  currentCategoryId: main.currentCategoryId,
  homeScrollPosition: main.homeScrollPosition,
  totalProducts: main.totalProducts,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setStoreState,
      setStoreLimit,
      setStoreReset,
      setStoreProduct,
      setStoreCategoryId,
      setHomeScrollPosition,
      setStoreTotalProducts,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Store);
