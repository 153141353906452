import gql from 'graphql-tag';

export const PRODUCT_BY_SLUG_AND_PHONE_QUERY = gql`
  query GetProduct ($slug: String, $phoneNumber: String) {
    product (slug: $slug, phoneNumber: $phoneNumber ) {
      id
      name
      price
      description
      sku
      discountPrice
      quantityAvailable
      active
      productAttachments{
        fileUrl
      }
      productAdditionalInfo{
        id,
        title,
        description
      }
      productOptions {
        id
        name
        productOptionValues {
          id
          name
          value
          order
        }
      }
      variants {
        id,
        price,
        discountPrice,
        sku,
        quantity,
        optionValues {
          id,
          name
        }
      }
      productImages {
        id
        name
        pictureUrl
        order
      }
    }
  }
`;