import React, { useState } from 'react'


const LazyImage = ({ image, alt }) => {
    // const viewportWidth = window.innerWidth;
    const [loaded, setLoaded] = useState(false);

    const showImage = () => {
        setLoaded(true);
    }


    return (
        <>
            <img
                alt={alt}
                src={`${image}`}
                style={loaded ? { display: 'none' } : {}}
            />
            <img
                srcSet={`${image} 1x,
                ${image} 2x,
                ${image} 3x`}
                src={`${image}`}
                alt={alt}
                onLoad={showImage}
                style={loaded ? {} : { display: 'none' }}
            />
        </>
    )
}

export default LazyImage
