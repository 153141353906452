import gql from 'graphql-tag';

export const STORE_ONLINE_PAYMENTS_BY_URL_QUERY = gql`
query StoreOnlinePayments($url: String!){
  store(url: $url){
    id
    onlinePayments
  }
}
`;
