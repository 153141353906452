// ReactJS
import React, { useEffect, useState } from 'react';

// Icons [SVG]
import Lock from '../../assets/icons/lock.svg';
import ShoppingBag from '../../assets/icons/shoppingBag.svg';
import ArrowDown from '../../assets/icons/arrowDown.svg';
import ArrowUp from '../../assets/icons/arrowUp.svg';

// Icons [PNG]
import Datos from '../../assets/images/datos.png';
import Envios from '../../assets/images/envios.png';
import Pagos from '../../assets/images/pagos.png';

// Icons [SVG]
import Arrowback from '../../assets/icons/arrowBack.svg';

// External dependencies
import { connect } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, Typography, Card, Container, Grid } from '@material-ui/core';
import { hotjar } from 'react-hotjar';

// Services
import { STORE_BY_URL_QUERY } from '../../qgl-queries/store';

//Pixel FACEBOOK
import ReactPixel from 'react-facebook-pixel'

//Google Analitycs
import ReactGA from "react-ga4";

// Utilities
import { formatNumber, getQueryStringParams, getStoreHeader } from '../../utils/constants';

// Components
import StepData from './StepData';
import StepDelivery from './StepDelivery';
import StepPayment from './StepPayment';
import LoaderRedirect from './LoaderRedirect';
import Loader from '../Loader';
import ResultPayment from './ResultPayment';
import Axios from 'axios';

const useStyles = makeStyles({
  cardList: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 8,
    width: '100%',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1rem',
    width: '100%',
  },
  typography: {
    fontSize: 22,
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalPay: {
    fontSize: 20,
    fontWeight: 700,
  },
  labels: {
    fontSize: 16,
    fontWeight: 400,
    color: '#0F2930',
    marginTop: 16,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    color: '#ffffff',
    background: '#21c59f',
    boxShadow: '5px 5px 9px - 2px rgba(0, 205, 172,0.2), - 5px - 5px 9px 0px rgba(0, 205, 172, 0.14)',
    marginTop: 16,
  },
  imgWrapper: {
    alignItems: 'center',
    backgroundColor: '#53627C',
    color: '#FFFFFF',
    borderRadius: 100,
    content: "'1'",
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    position: 'sticky',
    left: 44,
    top: 10,
    width: 20,
  }
});

const CART = JSON.parse(localStorage.getItem('wa10xcart'))

/**
 * 
 * @param {Array} items cart items from redux
 * @param {Object} store store data
 */
const Checkout = ({ items, store }) => {
  const classes = useStyles()
  const history = useHistory()
  const storeHeader = getStoreHeader()
  const { search, pathname } = useLocation()

  const storeLocation = window.location.hostname.split(".")[0]
  const storeUrl = storeHeader ? storeHeader : storeLocation

  const [step, setStep] = useState(localStorage.getItem("Step") ? Number(localStorage.getItem("Step")) : 1);
  const [check, setCheck] = useState()
  const [cart, setCart] = useState(true);
  const [isLoading, setLoadig] = useState(false);
  const [buyCart, setBuyCart] = useState();
  const [orderShippingCost, setOrderShippingCost] = useState();
  const [resultPayment, setResultPayment] = useState(getQueryStringParams(search));

  const { loading, data } = useQuery(STORE_BY_URL_QUERY, {
    variables: { url: storeUrl }
  });

  const LOGO_URL = data?.store?.profilePicture
    ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/80x80/${data?.store?.profilePicture}`)
    : (`https://ui-avatars.com/api/?name=${data?.store?.name || `10X&length=3`}&size=80&background=266678&color=fff`)


  const handleClick = () => {
    if (step === 2) {
      setStep(1);
      localStorage.setItem("Step", 1)
    } else {
      setStep(2);
      localStorage.setItem("Step", 2)
    }
  };

  const sumTotal = () => {
    let total = 0;
    for (let i = 0; i < CART?.items?.length; i++) {
      let product = CART?.items[i]?.price * CART?.items[i]?.amount;

      if (CART?.items[i]?.extraVariants.length > 0) {
        for (let j = 0; j < CART?.items[i]?.extraVariants.length; j++) {
          product += CART?.items[i]?.extraVariants[j]?.price * CART?.items[i]?.extraVariants[j]?.amount;
        }
      }

      total += product;
    }
    return total;
  };

  const totalPay = () => {
    let total = 0;
    let delivery = (isNaN(data?.store?.deliveryCostDetails) || !data?.store?.deliveryCostEnabled) ? 0 : parseInt(data?.store?.deliveryCostDetails);
    total = sumTotal() + delivery
    localStorage.setItem("TotalPay", total);
    return total;
  };

  const sumTotalPaymentLink = () => {
    let total = 0;
    for (let i = 0; i < buyCart?.length; i++) {
      let product = buyCart[i]?.price * buyCart[i]?.quantity;
      total += product;
    }
    return total;
  };

  const totalPayPaymentLink = () => {
    let total = 0;
    //let delivery = (isNaN(data?.store?.deliveryCostDetails) || !data?.store?.deliveryCostEnabled) ? 0 : parseInt(data?.store?.deliveryCostDetails);
    let delivery = orderShippingCost
      ? (isNaN(orderShippingCost)
        ? ((isNaN(data?.store?.deliveryCostDetails) || !data?.store?.deliveryCostEnabled) ? 0 : parseInt(data?.store?.deliveryCostDetails))
        : orderShippingCost
      )
      : (isNaN(data?.store?.deliveryCostDetails) || !data?.store?.deliveryCostEnabled) ? 0 : parseInt(data?.store?.deliveryCostDetails);;

    total = sumTotalPaymentLink() + delivery
    localStorage.setItem("TotalPay", total);
    return total;
  };

  const base64image = (image, isThumbnail) => {
    var image_src = ''
    if (isThumbnail) {
      image_src = JSON.stringify({
        "bucket": "wa10x.v2",
        "key": `${image.split('/')[6]}`,
        "edits": {
          "resize": {
            "width": 96,
            "height": 96,
            "fit": "cover"
          },
          "rotate": null,
        }
      })
    } else {
      image_src = JSON.stringify({
        "bucket": "wa10x.v2",
        "key": `${image}`,
        "edits": {
          "resize": {
            "width": 96,
            "height": 96,
            "fit": "cover"
          },
          "rotate": null,
        }
      })

    }

    return image_src;
  }

  const base64image2 = (image, isThumbnail) => {
    var image_src = ''
    if (isThumbnail) {
      image_src = JSON.stringify({
        "bucket": "wa10x.v2",
        "key": `${image.split('/')[6]}`,
        "edits": {
          "resize": {
            "width": 192,
            "height": 192,
            "fit": "cover"
          },
          "rotate": null,
        }
      })
    } else {
      image_src = JSON.stringify({
        "bucket": "wa10x.v2",
        "key": `${image}`,
        "edits": {
          "resize": {
            "width": 192,
            "height": 192,
            "fit": "cover"
          },
          "rotate": null,
        }
      })

    }

    return image_src;
  }

  const base64image3 = (image, isThumbnail) => {
    var image_src = ''
    if (isThumbnail) {
      image_src = JSON.stringify({
        "bucket": "wa10x.v2",
        "key": `${image.split('/')[6]}`,
        "edits": {
          "resize": {
            "width": 288,
            "height": 288,
            "fit": "cover"
          },
          "rotate": null,
        }
      })
    } else {
      image_src = JSON.stringify({
        "bucket": "wa10x.v2",
        "key": `${image}`,
        "edits": {
          "resize": {
            "width": 288,
            "height": 288,
            "fit": "cover"
          },
          "rotate": null,
        }
      })

    }

    return image_src;
  }

  useEffect(() => {
    const query = getQueryStringParams(search)
    setResultPayment(query)
    const isResult = pathname.split('/')
    resultPayment?.transactionState && setStep(4)
    resultPayment?.status && setStep(4)
    resultPayment?.id && setStep(4)
    resultPayment?.PayerID && setStep(4)
    resultPayment?.token && setStep(4)
    isResult[4] === 'result' && setStep(4)
  }, [])

  
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1702922, 6);

      /* Google Analytics */
      if (store?.googleAnalitycsTrackingId) {
        ReactGA.initialize([
          {
            trackingId: store?.googleAnalitycsTrackingId
          },
          // {
          //   trackingId: 'UA-163783687-1'
          // }
        ]);
      }
      // else {
      //   ReactGA.initialize([
      //     {
      //       trackingId: 'UA-163783687-1'
      //     }
      //   ])
      // }
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search })
      
      /* Facebook Pixel */
      store?.facebookPixelCode && ReactPixel.init(store?.facebookPixelCode);
      store?.facebookPixelCode && ReactPixel.pageView();
    }
  }, []);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL}/wa10x/stores/${pathname.split('/')[2]}/orders/${pathname.split('/')[3]}`)
      .then((response) => {
        setLoadig(true);
        setBuyCart(response?.data?.order_details);
        setOrderShippingCost(response?.data?.shipping_cost)
        setLoadig(false);
      })
      .catch((error) => {
        console.log(error);
      })
  }, [store, pathname])

  return (
    pathname.split('/')[4] === undefined
      ?
      loading
        ? <Loader />
        :
        <Container className={classes.header}>
          <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0 }}>
            {(step > 1 && step < 4) && <img src={Arrowback} alt="arrowBack" style={{ cursor: 'pointer' }} onClick={handleClick} />}
            {step < 4 && <Typography variant="body1" className={classes.typography}>
              <img src={Lock} alt="shop" style={{ marginRight: 6 }} />
              Finalizar compra
            </Typography>}
            <img src={LOGO_URL} alt="shop" style={{ borderRadius: '0.5rem' }} />
          </Card>
          <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0 }}>
            <Typography
              variant="body1"
              className={classes.typography}
              style={{ fontSize: 16, color: '#53627C', cursor: 'pointer' }}
              onClick={() => setCart(!cart)}
            >
              <img src={ShoppingBag} alt="shop" style={{ marginRight: 6 }} />
              {!cart ? 'Mostrar resumen del pedido' : 'Ocultar resumen del pedido'}
              {cart && <img src={ArrowUp} alt="shop" style={{ marginLeft: 6 }} />}
              {!cart && <img src={ArrowDown} alt="shop" style={{ marginLeft: 6 }} />}
            </Typography>
            <Typography variant="body1" className={classes.totalPay}>
              {formatNumber(totalPay())}
            </Typography>
          </Card>
          {cart &&
            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column' }}>
              {CART?.items?.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img
                      xs={2}
                      src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item?.image, true)).toString('base64')}`}
                      srcSet={(`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item?.image, true)).toString('base64')} 1x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image2(item?.image, true)).toString('base64')} 2x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image3(item?.image, true)).toString('base64')} 3x`)}
                      alt={item?.name}
                      style={{ borderRadius: '0.5rem', width: 64, height: 64, border: '1px solid #E1E4E8' }}
                    />
                    <div className={classes.imgWrapper}>{item?.amount}</div>
                    <Grid item xs={8} style={{ textAlign: 'initial' }}>
                      <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                        {item?.name}
                      </Typography>
                      <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                        {item?.productOptions.map((productOption, index) => (
                          <React.Fragment key={index}>
                            {productOption.name} &nbsp;
                            {productOption.productOptionValues
                              .find(option => option.id === productOption.selected)
                              .name
                            }
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    </Grid>
                    <Typography xs={2} variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                      {formatNumber(item?.price * item?.amount)}
                    </Typography>
                  </Grid>
                  <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />
                  {item?.extraVariants?.length > 0 ? (
                    item?.extraVariants?.map((extraVariant, index) => (
                      <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={index}>
                        <img
                          xs={2}
                          src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item?.image, true)).toString('base64')}`}
                          srcSet={(`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item?.image, true)).toString('base64')} 1x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image2(item?.image, true)).toString('base64')} 2x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image3(item?.image, true)).toString('base64')} 3x`)}
                          alt={item?.name}
                          style={{ borderRadius: '0.5rem', width: 64, height: 64, border: '1px solid #E1E4E8' }}
                        />
                        <div className={classes.imgWrapper}>{extraVariant?.amount}</div>
                        <Grid item xs={8} style={{ textAlign: 'initial' }}>
                          <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                            {extraVariant.sku}
                          </Typography>
                          <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                            {extraVariant?.options.map((productOption, index) => (
                              <React.Fragment key={index}>
                                {item?.productOptions[index]?.name} &nbsp;
                                {item?.productOptions[index].productOptionValues.find(option => option.id === productOption)?.name}
                                <br />
                              </React.Fragment>
                            ))}
                          </Typography>
                        </Grid>
                        <Typography xs={2} variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                          {formatNumber(extraVariant?.price * extraVariant?.amount)}
                        </Typography>
                      </Grid>
                    ))
                  ) : null}
                </React.Fragment>
              ))}
              <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                  Subtotal
                </Typography>
                <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                  {formatNumber(sumTotal())}
                </Typography>
              </Grid>
              <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                {data?.store?.deliveryCostEnabled &&
                  <>
                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                      Envios
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                      {formatNumber(data?.store?.deliveryCostDetails)}
                    </Typography>
                  </>
                }
              </Grid>
              <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />
              <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                  Total
                </Typography>
                <Typography variant="body1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                  {formatNumber(totalPay())}
                </Typography>
              </Grid>
            </Card>
          }
          {step !== 4 ? (
            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, backgroundColor: 'transparent', flexDirection: 'column' }}>
              <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 24 }}>
                <Grid container style={{ borderRadius: 100, backgroundColor: '#FFF', padding: 8, width: 'auto', border: step === 1 ? '1px solid #48AC98' : '1px solid #E1E4E8' }}>
                  <img src={Datos} alt="Tus Datos" />
                </Grid>
                <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '20%' }} />
                <Grid container style={{ borderRadius: 100, backgroundColor: '#FFF', padding: 8, width: 'auto', border: step === 2 ? '1px solid #48AC98' : '1px solid #E1E4E8' }}>
                  <img src={Envios} alt="Envios" />
                </Grid>
                <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '20%' }} />
                <Grid container style={{ borderRadius: 100, backgroundColor: '#FFF', padding: 8, width: 'auto', border: step === 3 ? '1px solid #48AC98' : '1px solid #E1E4E8' }}>
                  <img src={Pagos} alt="Pagos" />
                </Grid>
              </Grid>
              <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container style={{ width: 'auto', marginBottom: 24 }}>
                  <Typography variant="body1" style={{ color: step === 1 ? '#48AC98' : '#8A94A6' }}>
                    Tus Datos
                  </Typography>
                </Grid>
                <div style={{ backgroundColor: 'transparent', height: 1, width: '20%' }} />
                <Grid container style={{ width: 'auto', marginBottom: 24 }}>
                  <Typography variant="body1" style={{ color: step === 2 ? '#48AC98' : '#8A94A6' }}>
                    Envios
                  </Typography>
                </Grid>
                <div style={{ backgroundColor: 'transparent', height: 1, width: '20%' }} />
                <Grid container style={{ width: 'auto', marginBottom: 24 }}>
                  <Typography variant="body1" style={{ color: step === 3 ? '#48AC98' : '#8A94A6' }}>
                    Pagos
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          ) : null}
          {step === 1 && <StepData setStep={setStep} store={store} buyCart={buyCart}/>}
          {step === 2 && <StepDelivery setStep={setStep} store={store}/>}
          {step === 3 && <StepPayment 
            setStep={setStep} 
            check={check} 
            setCheck={setCheck} 
            setResultPayment={setResultPayment} 
            store={store} 
            buyCart={buyCart}
          />}
          {
            step === 4 &&
            <ResultPayment
              queryData={resultPayment}
              check={check}
              payerID={resultPayment?.PayerID}
              setCheck={setCheck}
              phoneSupport={store?.userStores[0]?.mobilePhoneNumber}
              isManualPay={localStorage?.getItem("ManualPay")}
              store={store}
              buyCart={buyCart}
            />
          }
        </Container >
      :
      isLoading
        ? <Loader />
        :
        <Container className={classes.header}>
          <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0 }}>
            {(step > 1 && step < 4) && <img src={Arrowback} alt="arrowBack" style={{ cursor: 'pointer' }} onClick={handleClick} />}
            {step < 4 && <Typography variant="body1" className={classes.typography}>
              <img src={Lock} alt="shop" style={{ marginRight: 6 }} />
              Finalizar compra
            </Typography>}
            <img src={LOGO_URL} alt="shop" style={{ borderRadius: '0.5rem' }} />
          </Card>
          <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0 }}>
            <Typography
              variant="body1"
              className={classes.typography}
              style={{ fontSize: 16, color: '#53627C', cursor: 'pointer' }}
              onClick={() => setCart(!cart)}
            >
              <img src={ShoppingBag} alt="shop" style={{ marginRight: 6 }} />
              {!cart ? 'Mostrar resumen del pedido' : 'Ocultar resumen del pedido'}
              {cart && <img src={ArrowDown} alt="shop" style={{ marginLeft: 6 }} />}
              {!cart && <img src={ArrowUp} alt="shop" style={{ marginLeft: 6 }} />}
            </Typography>
            <Typography variant="body1" className={classes.totalPay}>
              {formatNumber(totalPayPaymentLink())}
            </Typography>
          </Card>
          {cart &&
            <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column' }}>
              {buyCart?.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img
                      xs={2}
                      src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item?.product?.product_images[0]?.picture_url, false)).toString('base64')}`}
                      srcSet={(`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image(item?.product?.product_images[0]?.picture_url, false)).toString('base64')} 1x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image2(item?.product?.product_images[0]?.picture_url, false)).toString('base64')} 2x,
                            ${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(base64image3(item?.product?.product_images[0]?.picture_url, false)).toString('base64')} 3x`)}
                      alt={item?.product?.name}
                      style={{ borderRadius: '0.5rem', width: 64, height: 64, border: '1px solid #E1E4E8' }}
                    />
                    <div className={classes.imgWrapper}>{item?.quantity}</div>
                    <Grid item xs={8} style={{ textAlign: 'initial' }}>
                      <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                        {item?.product?.name}
                      </Typography>
                      <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                        {item?.order_item_option.map((productOption, index) => (
                          <React.Fragment key={index}>
                            {productOption?.product_option_value?.name} &nbsp;
                          </React.Fragment>
                        ))}
                      </Typography>
                    </Grid>
                    <Typography xs={2} variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                      {formatNumber(item?.price * item?.quantity)}
                    </Typography>
                  </Grid>
                  <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />
                </React.Fragment>
              ))}
              <Grid container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                  Subtotal
                </Typography>
                <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                  {formatNumber(sumTotalPaymentLink())}
                </Typography>
              </Grid>
              <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                {data?.store?.deliveryCostEnabled &&
                  <>
                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930' }}>
                      Envios
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 16, color: '#0F2930', fontWeight: 700 }}>
                      {formatNumber(orderShippingCost || data?.store?.deliveryCostDetails)}
                    </Typography>
                  </>
                }
              </Grid>
              <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '100%', margin: '16px 0px' }} />
              <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                  Total
                </Typography>
                <Typography variant="body1" style={{ fontSize: 18, color: '#0F2930', fontWeight: 700 }}>
                  {formatNumber(totalPayPaymentLink())}
                </Typography>
              </Grid>
            </Card>
          }
          {step !== 4 && <Card elevation={1} className={classes.cardList} style={{ borderRadius: 0, backgroundColor: 'transparent', flexDirection: 'column' }}>
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 24 }}>
              <Grid container style={{ borderRadius: 100, backgroundColor: '#FFF', padding: 8, width: 'auto', border: step === 1 ? '1px solid #48AC98' : '1px solid #E1E4E8' }}>
                <img src={Datos} alt="Tus Datos" />
              </Grid>
              <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '20%' }} />
              <Grid container style={{ borderRadius: 100, backgroundColor: '#FFF', padding: 8, width: 'auto', border: step === 2 ? '1px solid #48AC98' : '1px solid #E1E4E8' }}>
                <img src={Envios} alt="Envios" />
              </Grid>
              <div style={{ backgroundColor: '#E1E4E8', height: 1, width: '20%' }} />
              <Grid container style={{ borderRadius: 100, backgroundColor: '#FFF', padding: 8, width: 'auto', border: step === 3 ? '1px solid #48AC98' : '1px solid #E1E4E8' }}>
                <img src={Pagos} alt="Pagos" />
              </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Grid container style={{ width: 'auto', marginBottom: 24 }}>
                <Typography variant="body1" style={{ color: step === 1 ? '#48AC98' : '#8A94A6' }}>
                  Tus Datos
                </Typography>
              </Grid>
              <div style={{ backgroundColor: 'transparent', height: 1, width: '20%' }} />
              <Grid container style={{ width: 'auto', marginBottom: 24 }}>
                <Typography variant="body1" style={{ color: step === 2 ? '#48AC98' : '#8A94A6' }}>
                  Envios
                </Typography>
              </Grid>
              <div style={{ backgroundColor: 'transparent', height: 1, width: '20%' }} />
              <Grid container style={{ width: 'auto', marginBottom: 24 }}>
                <Typography variant="body1" style={{ color: step === 3 ? '#48AC98' : '#8A94A6' }}>
                  Pagos
                </Typography>
              </Grid>
            </Grid>
          </Card>}
          {step === 1 && <StepData setStep={setStep}  store={store}/>}
          {step === 2 && <StepDelivery setStep={setStep}  store={store}/>}
          {step === 3 && 
            <StepPayment 
              setStep={setStep} 
              check={check} 
              setCheck={setCheck} 
              setResultPayment={setResultPayment} 
              store={store} 
              buyCart={buyCart}
            />
          }
          {step === 4 &&
            <ResultPayment
              queryData={resultPayment}
              check={check}
              payerID={resultPayment?.PayerID}
              setCheck={setCheck}
              phoneSupport={store?.userStores[0]?.mobilePhoneNumber}
              isManualPay={localStorage?.getItem("ManualPay")}
              store={store} 
            />
          }
        </Container >
  )
}
const mapStateToProps = ({ main }) => ({
  items: main.items,
  store: main.store
});

export default connect(
  mapStateToProps,
)(Checkout);
