import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Plus = props => {
  return (
    <SvgIcon {...props}>
      <path d="M 22.914062 10.988281 L 12.976562 10.988281 L 12.976562 1.011719 C 12.976562 0.414062 12.5625 0 11.960938 0 C 11.363281 0 10.949219 0.414062 10.949219 1.011719 L 10.949219 11.023438 L 1.011719 11.023438 C 0.414062 10.988281 0 11.398438 0 12 C 0 12.601562 0.414062 13.011719 1.011719 13.011719 L 10.988281 13.011719 L 10.988281 23.023438 C 10.988281 23.625 11.398438 24.039062 12 24.039062 C 12.601562 24.039062 13.011719 23.625 13.011719 23.023438 L 13.011719 12.976562 L 22.988281 12.976562 C 23.585938 12.976562 24 12.5625 24 11.960938 C 23.925781 11.398438 23.511719 10.988281 22.914062 10.988281 Z M 22.914062 10.988281 "/>
    </SvgIcon>
  )
}

export default Plus
