// React dependencies
import React from 'react'

// external dependencies
import { useSnackbar } from 'notistack';

// Material UI dependencies
import { IconButton } from '@material-ui/core';

// internal dependencies
import Share from '../Icons/Share';

// styles
import ShareButtonStyles from './ShareButton.module.scss'

const ShareButton = () => {
  const { enqueueSnackbar } = useSnackbar();

  const shareThis = ({title, text}) => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: text,
        url: window.location.href
      }).then(() => {
        enqueueSnackbar('¡El producto ha sido compartido!', {
          variant: 'success',
          autoHideDuration: 2500
        });
      })
      .catch(err => {
        enqueueSnackbar('El producto no pudo ser compartido', {
          variant: 'error',
          autoHideDuration: 2500
        });
      })
    } else {
      enqueueSnackbar('Hubo un error al compartir este producto', {
        variant: 'error',
        autoHideDuration: 2500
      });
    }
  }

  return (
    <IconButton 
      className={ShareButtonStyles.shareBtn}
      onClick={shareThis}
      aria-label="compartir">
        <Share fontSize="small" />
    </IconButton>
  )
}

export default ShareButton
