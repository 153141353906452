export const cartPrefix = "wa10xcart";

export const cartSkeleton = {
  items: [],
  store: {
    name: "",
  },
};

export const cleanShoppingCart = () => {
  /* Limpiar Carrito */
  const cartKey = `${cartPrefix}`; //  Construir key de localstorage
  const currentCart = localStorage.getItem(cartKey); //  Obtener datos del storage
  const cCart = JSON.parse(currentCart); //  Parsear datos actuales
  const storeData = cCart.store; //  Guardar storeData para el nuevo state
  localStorage.setItem(
    cartKey,
    JSON.stringify(
      // Resetear state
      {
        items: [],
        store: storeData,
      }
    )
  );
};

export const findValidUrl = (store, phoneNumber, onError) => {
  const userStore = store.userStores.find(
    (userStore) => userStore.mobilePhoneNumber === phoneNumber
  );
  if (userStore) return true;
  onError();
};

/**
 * Gets the cart data by store url and phone number
 * @param {string} storeUrl store url
 * @param {number} phoneNumber user store phone number
 */
export const getLocalData = (storeUrl, phoneNumber) => {
  /* const cartKey = `${cartPrefix}-${storeUrl}-${phoneNumber}`;  Old cartKey */
  const cartKey = `${cartPrefix}`;
  const currentCart = localStorage.getItem(cartKey);
  if (currentCart) {
    return JSON.parse(currentCart);
  } else {
    localStorage.setItem(cartKey, JSON.stringify(cartSkeleton));
    return cartSkeleton;
  }
};

/**
 * Sets the cart data by store url and phone number
 * @param {string} storeUrl store url
 * @param {number} phoneNumber user store phone number
 * @param {Object} cartData new cart data
 */
export const setLocalData = (storeUrl, phoneNumber, cartData) => {
  /* const cartKey = `${cartPrefix}-${storeUrl}-${phoneNumber}`;  Old cartKey*/
  const cartKey = `${cartPrefix}`;

  /*if (localStorage.getItem(cartKey)) {
        localStorage.removeItem(cartKey);
    }*/
  localStorage.setItem(cartKey, JSON.stringify(cartData));
  return cartData;
};

export const formatNumber = (value, currency = false, toParts = false) => {
  const formatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: currency || "COP",
  });

  if (toParts) return formatter.formatToParts(value);
  if (!currency || currency === "COP") {
    return formatter.format(value).replace(",00", "");
  }
  return formatter.format(value);
};

export const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getStoreHeader = () => {
  var req = new XMLHttpRequest();
  req.open("GET", document.location, false);
  req.send(null);
  var headers = req.getAllResponseHeaders().toLowerCase();

  let headersArray = headers.split("\r\n");
  let headerContent = {};

  headersArray.forEach((element) => {
    let headerItem = element.split(":");
    headerContent[headerItem[0]] = headerItem[1];
  });

  return headerContent?.store?.trim();
};

/**
 * Parse query
 * @param {string} query query string params
 */
export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const colombianCities = [
  { department: "Cundinamarca", city: "Bogotá D.C." },
  { department: "Antioquia", city: "Medellín" },
  { department: "Valle del Cauca", city: "Cali" },
  { department: "Atlántico", city: "Barranquilla" },
  { department: "Bolívar", city: "Cartagena" },
  { department: "Atlántico", city: "Soledad" },
  { department: "Norte de Santander", city: "Cúcuta" },
  { department: "Tolima", city: "Ibagué" },
  { department: "Cundinamarca", city: "Soacha" },
  { department: "Meta", city: "Villavicencio" },
  { department: "Santander", city: "Bucaramanga" },
  { department: "Magdalena", city: "Santa Marta" },
  { department: "Cesar", city: "Valledupar" },
  { department: "Antioquia", city: "Bello" },
  { department: "Risaralda", city: "Pereira" },
  { department: "Córdoba", city: "Montería" },
  { department: "Nariño", city: "Pasto" },
  { department: "Valle del Cauca", city: "Buenaventura" },
  { department: "Caldas", city: "Manizales" },
  { department: "Huila", city: "Neiva" },
  { department: "Amazonas", city: "Miriti Paraná" },
  { department: "Amazonas", city: "Leticia" },
  { department: "Amazonas", city: "El Encanto" },
  { department: "Amazonas", city: "La Chorrera" },
  { department: "Amazonas", city: "La Pedrera" },
  { department: "Amazonas", city: "La Victoria" },
  { department: "Amazonas", city: "Puerto Arica" },
  { department: "Amazonas", city: "Puerto Nariño" },
  { department: "Amazonas", city: "Puerto Santander" },
  { department: "Amazonas", city: "Tarapacá" },
  { department: "Amazonas", city: "Puerto Alegría" },
  { department: "Antioquia", city: "Abejorral" },
  { department: "Antioquia", city: "Abriaquí" },
  { department: "Antioquia", city: "Alejandría" },
  { department: "Antioquia", city: "Amagá" },
  { department: "Antioquia", city: "Amalfi" },
  { department: "Antioquia", city: "Andes" },
  { department: "Antioquia", city: "Angelópolis" },
  { department: "Antioquia", city: "Angostura" },
  { department: "Antioquia", city: "Anorí" },
  { department: "Antioquia", city: "Anza" },
  { department: "Antioquia", city: "Apartadó" },
  { department: "Antioquia", city: "Arboletes" },
  { department: "Antioquia", city: "Argelia" },
  { department: "Antioquia", city: "Armenia" },
  { department: "Antioquia", city: "Barbosa" },
  { department: "Antioquia", city: "Betania" },
  { department: "Antioquia", city: "Betulia" },
  { department: "Antioquia", city: "Ciudad Bolívar" },
  { department: "Antioquia", city: "Briceño" },
  { department: "Antioquia", city: "Buriticá" },
  { department: "Antioquia", city: "Cáceres" },
  { department: "Antioquia", city: "Caicedo" },
  { department: "Antioquia", city: "Caldas" },
  { department: "Antioquia", city: "Campamento" },
  { department: "Antioquia", city: "Cañasgordas" },
  { department: "Antioquia", city: "Caracolí" },
  { department: "Antioquia", city: "Caramanta" },
  { department: "Antioquia", city: "Carepa" },
  { department: "Antioquia", city: "Carolina" },
  { department: "Antioquia", city: "Caucasia" },
  { department: "Antioquia", city: "Chigorodó" },
  { department: "Antioquia", city: "Cisneros" },
  { department: "Antioquia", city: "Cocorná" },
  { department: "Antioquia", city: "Concepción" },
  { department: "Antioquia", city: "Concordia" },
  { department: "Antioquia", city: "Copacabana" },
  { department: "Antioquia", city: "Dabeiba" },
  { department: "Antioquia", city: "Don Matías" },
  { department: "Antioquia", city: "Ebéjico" },
  { department: "Antioquia", city: "El Bagre" },
  { department: "Antioquia", city: "Entrerrios" },
  { department: "Antioquia", city: "Envigado" },
  { department: "Antioquia", city: "Fredonia" },
  { department: "Antioquia", city: "Giraldo" },
  { department: "Antioquia", city: "Girardota" },
  { department: "Antioquia", city: "Gómez Plata" },
  { department: "Antioquia", city: "Guadalupe" },
  { department: "Antioquia", city: "Guarne" },
  { department: "Antioquia", city: "Guatapé" },
  { department: "Antioquia", city: "Heliconia" },
  { department: "Antioquia", city: "Hispania" },
  { department: "Antioquia", city: "Itagui" },
  { department: "Antioquia", city: "Ituango" },
  { department: "Antioquia", city: "Jericó" },
  { department: "Antioquia", city: "La Ceja" },
  { department: "Antioquia", city: "La Estrella" },
  { department: "Antioquia", city: "La Pintada" },
  { department: "Antioquia", city: "La Unión" },
  { department: "Antioquia", city: "Liborina" },
  { department: "Antioquia", city: "Maceo" },
  { department: "Antioquia", city: "Marinilla" },
  { department: "Antioquia", city: "Montebello" },
  { department: "Antioquia", city: "Murindó" },
  { department: "Antioquia", city: "Mutatá" },
  { department: "Antioquia", city: "Nariño" },
  { department: "Antioquia", city: "Necoclí" },
  { department: "Antioquia", city: "Nechí" },
  { department: "Antioquia", city: "Olaya" },
  { department: "Antioquia", city: "Peñol" },
  { department: "Antioquia", city: "Peque" },
  { department: "Antioquia", city: "Pueblorrico" },
  { department: "Antioquia", city: "Puerto Berrío" },
  { department: "Antioquia", city: "Puerto Nare" },
  { department: "Antioquia", city: "Puerto Triunfo" },
  { department: "Antioquia", city: "Remedios" },
  { department: "Antioquia", city: "Retiro" },
  { department: "Antioquia", city: "Rionegro" },
  { department: "Antioquia", city: "Sabanalarga" },
  { department: "Antioquia", city: "Sabaneta" },
  { department: "Antioquia", city: "Salgar" },
  { department: "Antioquia", city: "San Francisco" },
  { department: "Antioquia", city: "San Jerónimo" },
  { department: "Antioquia", city: "San Luis" },
  { department: "Antioquia", city: "San Pedro" },
  { department: "Antioquia", city: "San Rafael" },
  { department: "Antioquia", city: "San Roque" },
  { department: "Antioquia", city: "San Vicente" },
  { department: "Antioquia", city: "Santa Bárbara" },
  { department: "Antioquia", city: "Santo Domingo" },
  { department: "Antioquia", city: "El Santuario" },
  { department: "Antioquia", city: "Segovia" },
  { department: "Antioquia", city: "Sopetrán" },
  { department: "Antioquia", city: "Támesis" },
  { department: "Antioquia", city: "Tarazá" },
  { department: "Antioquia", city: "Tarso" },
  { department: "Antioquia", city: "Titiribí" },
  { department: "Antioquia", city: "Toledo" },
  { department: "Antioquia", city: "Turbo" },
  { department: "Antioquia", city: "Uramita" },
  { department: "Antioquia", city: "Urrao" },
  { department: "Antioquia", city: "Valdivia" },
  { department: "Antioquia", city: "Valparaíso" },
  { department: "Antioquia", city: "Vegachí" },
  { department: "Antioquia", city: "Venecia" },
  { department: "Antioquia", city: "Yalí" },
  { department: "Antioquia", city: "Yarumal" },
  { department: "Antioquia", city: "Yolombó" },
  { department: "Antioquia", city: "Yondó" },
  { department: "Antioquia", city: "Zaragoza" },
  { department: "Antioquia", city: "Frontino" },
  { department: "Antioquia", city: "Granada" },
  { department: "Antioquia", city: "Jardín" },
  { department: "Antioquia", city: "Sonsón" },
  { department: "Antioquia", city: "Belmira" },
  { department: "Antioquia", city: "San Pedro de Uraba" },
  { department: "Antioquia", city: "Santafé de Antioquia" },
  { department: "Antioquia", city: "Santa Rosa de Osos" },
  { department: "Antioquia", city: "San Andrés de Cuerquía" },
  { department: "Antioquia", city: "Vigía del Fuerte" },
  { department: "Antioquia", city: "San José de La Montaña" },
  { department: "Antioquia", city: "San Juan de Urabá" },
  { department: "Antioquia", city: "El Carmen de Viboral" },
  { department: "Antioquia", city: "San Carlos" },
  { department: "Arauca", city: "Arauquita" },
  { department: "Arauca", city: "Cravo Norte" },
  { department: "Arauca", city: "Fortul" },
  { department: "Arauca", city: "Puerto Rondón" },
  { department: "Arauca", city: "Saravena" },
  { department: "Arauca", city: "Tame" },
  { department: "Arauca", city: "Arauca" },
  {
    department: "Archipiélago de San Andrés, Providencia y Santa Catalina",
    city: "Providencia",
  },
  {
    department: "Archipiélago de San Andrés, Providencia y Santa Catalina",
    city: "San Andrés",
  },
  { department: "Atlántico", city: "Baranoa" },
  { department: "Atlántico", city: "Candelaria" },
  { department: "Atlántico", city: "Galapa" },
  { department: "Atlántico", city: "Luruaco" },
  { department: "Atlántico", city: "Malambo" },
  { department: "Atlántico", city: "Manatí" },
  { department: "Atlántico", city: "Piojó" },
  { department: "Atlántico", city: "Polonuevo" },
  { department: "Atlántico", city: "Sabanagrande" },
  { department: "Atlántico", city: "Sabanalarga" },
  { department: "Atlántico", city: "Santa Lucía" },
  { department: "Atlántico", city: "Santo Tomás" },
  { department: "Atlántico", city: "Suan" },
  { department: "Atlántico", city: "Tubará" },
  { department: "Atlántico", city: "Usiacurí" },
  { department: "Atlántico", city: "Repelón" },
  { department: "Atlántico", city: "Puerto Colombia" },
  { department: "Atlántico", city: "Ponedera" },
  { department: "Atlántico", city: "Juan de Acosta" },
  { department: "Atlántico", city: "Palmar de Varela" },
  { department: "Atlántico", city: "Campo de La Cruz" },
  { department: "Bolívar", city: "El Peñón" },
  { department: "Bolívar", city: "Achí" },
  { department: "Bolívar", city: "Arenal" },
  { department: "Bolívar", city: "Arjona" },
  { department: "Bolívar", city: "Arroyohondo" },
  { department: "Bolívar", city: "Calamar" },
  { department: "Bolívar", city: "Cantagallo" },
  { department: "Bolívar", city: "Cicuco" },
  { department: "Bolívar", city: "Córdoba" },
  { department: "Bolívar", city: "Clemencia" },
  { department: "Bolívar", city: "El Guamo" },
  { department: "Bolívar", city: "Magangué" },
  { department: "Bolívar", city: "Mahates" },
  { department: "Bolívar", city: "Margarita" },
  { department: "Bolívar", city: "Montecristo" },
  { department: "Bolívar", city: "Mompós" },
  { department: "Bolívar", city: "Morales" },
  { department: "Bolívar", city: "Norosí" },
  { department: "Bolívar", city: "Pinillos" },
  { department: "Bolívar", city: "Regidor" },
  { department: "Bolívar", city: "Río Viejo" },
  { department: "Bolívar", city: "San Estanislao" },
  { department: "Bolívar", city: "San Fernando" },
  { department: "Bolívar", city: "San Juan Nepomuceno" },
  { department: "Bolívar", city: "Santa Catalina" },
  { department: "Bolívar", city: "Santa Rosa" },
  { department: "Bolívar", city: "Simití" },
  { department: "Bolívar", city: "Soplaviento" },
  { department: "Bolívar", city: "Talaigua Nuevo" },
  { department: "Bolívar", city: "Tiquisio" },
  { department: "Bolívar", city: "Turbaco" },
  { department: "Bolívar", city: "Turbaná" },
  { department: "Bolívar", city: "Villanueva" },
  { department: "Bolívar", city: "María la Baja" },
  { department: "Bolívar", city: "San Cristóbal" },
  { department: "Bolívar", city: "Zambrano" },
  { department: "Bolívar", city: "Barranco de Loba" },
  { department: "Bolívar", city: "Santa Rosa del Sur" },
  { department: "Bolívar", city: "Hatillo de Loba" },
  { department: "Bolívar", city: "El Carmen de Bolívar" },
  { department: "Bolívar", city: "San Martín de Loba" },
  { department: "Bolívar", city: "Altos del Rosario" },
  { department: "Bolívar", city: "San Jacinto del Cauca" },
  { department: "Bolívar", city: "San Pablo de Borbur" },
  { department: "Bolívar", city: "San Jacinto" },
  { department: "Boyacá", city: "Tibasosa" },
  { department: "Boyacá", city: "Tunja" },
  { department: "Boyacá", city: "Almeida" },
  { department: "Boyacá", city: "Aquitania" },
  { department: "Boyacá", city: "Arcabuco" },
  { department: "Boyacá", city: "Berbeo" },
  { department: "Boyacá", city: "Betéitiva" },
  { department: "Boyacá", city: "Boavita" },
  { department: "Boyacá", city: "Boyacá" },
  { department: "Boyacá", city: "Briceño" },
  { department: "Boyacá", city: "Buena Vista" },
  { department: "Boyacá", city: "Busbanzá" },
  { department: "Boyacá", city: "Caldas" },
  { department: "Boyacá", city: "Campohermoso" },
  { department: "Boyacá", city: "Cerinza" },
  { department: "Boyacá", city: "Chinavita" },
  { department: "Boyacá", city: "Chiquinquirá" },
  { department: "Boyacá", city: "Chiscas" },
  { department: "Boyacá", city: "Chita" },
  { department: "Boyacá", city: "Chitaraque" },
  { department: "Boyacá", city: "Chivatá" },
  { department: "Boyacá", city: "Cómbita" },
  { department: "Boyacá", city: "Coper" },
  { department: "Boyacá", city: "Corrales" },
  { department: "Boyacá", city: "Covarachía" },
  { department: "Boyacá", city: "Cubará" },
  { department: "Boyacá", city: "Cucaita" },
  { department: "Boyacá", city: "Cuítiva" },
  { department: "Boyacá", city: "Chíquiza" },
  { department: "Boyacá", city: "Chivor" },
  { department: "Boyacá", city: "Duitama" },
  { department: "Boyacá", city: "El Cocuy" },
  { department: "Boyacá", city: "El Espino" },
  { department: "Boyacá", city: "Firavitoba" },
  { department: "Boyacá", city: "Floresta" },
  { department: "Boyacá", city: "Gachantivá" },
  { department: "Boyacá", city: "Gameza" },
  { department: "Boyacá", city: "Garagoa" },
  { department: "Boyacá", city: "Guacamayas" },
  { department: "Boyacá", city: "Guateque" },
  { department: "Boyacá", city: "Guayatá" },
  { department: "Boyacá", city: "Güicán" },
  { department: "Boyacá", city: "Iza" },
  { department: "Boyacá", city: "Jenesano" },
  { department: "Boyacá", city: "Jericó" },
  { department: "Boyacá", city: "Labranzagrande" },
  { department: "Boyacá", city: "La Capilla" },
  { department: "Boyacá", city: "La Victoria" },
  { department: "Boyacá", city: "Macanal" },
  { department: "Boyacá", city: "Maripí" },
  { department: "Boyacá", city: "Miraflores" },
  { department: "Boyacá", city: "Mongua" },
  { department: "Boyacá", city: "Monguí" },
  { department: "Boyacá", city: "Moniquirá" },
  { department: "Boyacá", city: "Muzo" },
  { department: "Boyacá", city: "Nobsa" },
  { department: "Boyacá", city: "Nuevo Colón" },
  { department: "Boyacá", city: "Oicatá" },
  { department: "Boyacá", city: "Otanche" },
  { department: "Boyacá", city: "Pachavita" },
  { department: "Boyacá", city: "Páez" },
  { department: "Boyacá", city: "Paipa" },
  { department: "Boyacá", city: "Pajarito" },
  { department: "Boyacá", city: "Panqueba" },
  { department: "Boyacá", city: "Pauna" },
  { department: "Boyacá", city: "Paya" },
  { department: "Boyacá", city: "Pesca" },
  { department: "Boyacá", city: "Pisba" },
  { department: "Boyacá", city: "Puerto Boyacá" },
  { department: "Boyacá", city: "Quípama" },
  { department: "Boyacá", city: "Ramiriquí" },
  { department: "Boyacá", city: "Ráquira" },
  { department: "Boyacá", city: "Rondón" },
  { department: "Boyacá", city: "Saboyá" },
  { department: "Boyacá", city: "Sáchica" },
  { department: "Boyacá", city: "Samacá" },
  { department: "Boyacá", city: "San Eduardo" },
  { department: "Boyacá", city: "San Mateo" },
  { department: "Boyacá", city: "Santana" },
  { department: "Boyacá", city: "Santa María" },
  { department: "Boyacá", city: "Santa Sofía" },
  { department: "Boyacá", city: "Sativanorte" },
  { department: "Boyacá", city: "Sativasur" },
  { department: "Boyacá", city: "Siachoque" },
  { department: "Boyacá", city: "Soatá" },
  { department: "Boyacá", city: "Socotá" },
  { department: "Boyacá", city: "Socha" },
  { department: "Boyacá", city: "Sogamoso" },
  { department: "Boyacá", city: "Somondoco" },
  { department: "Boyacá", city: "Sora" },
  { department: "Boyacá", city: "Sotaquirá" },
  { department: "Boyacá", city: "Soracá" },
  { department: "Boyacá", city: "Susacón" },
  { department: "Boyacá", city: "Sutamarchán" },
  { department: "Boyacá", city: "Sutatenza" },
  { department: "Boyacá", city: "Tasco" },
  { department: "Boyacá", city: "Tenza" },
  { department: "Boyacá", city: "Tibaná" },
  { department: "Boyacá", city: "Tinjacá" },
  { department: "Boyacá", city: "Tipacoque" },
  { department: "Boyacá", city: "Toca" },
  { department: "Boyacá", city: "Tópaga" },
  { department: "Boyacá", city: "Tota" },
  { department: "Boyacá", city: "Turmequé" },
  { department: "Boyacá", city: "Tutazá" },
  { department: "Boyacá", city: "Umbita" },
  { department: "Boyacá", city: "Ventaquemada" },
  { department: "Boyacá", city: "Viracachá" },
  { department: "Boyacá", city: "Zetaquira" },
  { department: "Boyacá", city: "La Uvita" },
  { department: "Boyacá", city: "Belén" },
  { department: "Boyacá", city: "Tununguá" },
  { department: "Boyacá", city: "Motavita" },
  { department: "Boyacá", city: "Ciénega" },
  { department: "Boyacá", city: "Togüí" },
  { department: "Boyacá", city: "Villa de Leyva" },
  { department: "Boyacá", city: "Paz de Río" },
  { department: "Boyacá", city: "Santa Rosa de Viterbo" },
  { department: "Boyacá", city: "San Pablo de Borbur" },
  { department: "Boyacá", city: "San Luis de Gaceno" },
  { department: "Boyacá", city: "San José de Pare" },
  { department: "Boyacá", city: "San Miguel de Sema" },
  { department: "Boyacá", city: "Tuta" },
  { department: "Caldas", city: "Aguadas" },
  { department: "Caldas", city: "Anserma" },
  { department: "Caldas", city: "Aranzazu" },
  { department: "Caldas", city: "Belalcázar" },
  { department: "Caldas", city: "Chinchiná" },
  { department: "Caldas", city: "Filadelfia" },
  { department: "Caldas", city: "La Dorada" },
  { department: "Caldas", city: "La Merced" },
  { department: "Caldas", city: "Manzanares" },
  { department: "Caldas", city: "Marmato" },
  { department: "Caldas", city: "Marulanda" },
  { department: "Caldas", city: "Neira" },
  { department: "Caldas", city: "Norcasia" },
  { department: "Caldas", city: "Pácora" },
  { department: "Caldas", city: "Palestina" },
  { department: "Caldas", city: "Pensilvania" },
  { department: "Caldas", city: "Riosucio" },
  { department: "Caldas", city: "Risaralda" },
  { department: "Caldas", city: "Salamina" },
  { department: "Caldas", city: "Samaná" },
  { department: "Caldas", city: "San José" },
  { department: "Caldas", city: "Supía" },
  { department: "Caldas", city: "Victoria" },
  { department: "Caldas", city: "Villamaría" },
  { department: "Caldas", city: "Viterbo" },
  { department: "Caldas", city: "Marquetalia" },
  { department: "Caquetá", city: "Milán" },
  { department: "Caquetá", city: "Florencia" },
  { department: "Caquetá", city: "Albania" },
  { department: "Caquetá", city: "Curillo" },
  { department: "Caquetá", city: "El Doncello" },
  { department: "Caquetá", city: "El Paujil" },
  { department: "Caquetá", city: "Morelia" },
  { department: "Caquetá", city: "Puerto Rico" },
  { department: "Caquetá", city: "La Montañita" },
  { department: "Caquetá", city: "San Vicente del Caguán" },
  { department: "Caquetá", city: "Solano" },
  { department: "Caquetá", city: "Solita" },
  { department: "Caquetá", city: "Valparaíso" },
  { department: "Caquetá", city: "San José del Fragua" },
  { department: "Caquetá", city: "Belén de Los Andaquies" },
  { department: "Caquetá", city: "Cartagena del Chairá" },
  { department: "Casanare", city: "Nunchía" },
  { department: "Casanare", city: "Maní" },
  { department: "Casanare", city: "Támara" },
  { department: "Casanare", city: "Orocué" },
  { department: "Casanare", city: "Yopal" },
  { department: "Casanare", city: "Aguazul" },
  { department: "Casanare", city: "Chámeza" },
  { department: "Casanare", city: "Hato Corozal" },
  { department: "Casanare", city: "La Salina" },
  { department: "Casanare", city: "Monterrey" },
  { department: "Casanare", city: "Pore" },
  { department: "Casanare", city: "Recetor" },
  { department: "Casanare", city: "Sabanalarga" },
  { department: "Casanare", city: "Sácama" },
  { department: "Casanare", city: "Tauramena" },
  { department: "Casanare", city: "Trinidad" },
  { department: "Casanare", city: "Villanueva" },
  { department: "Casanare", city: "San Luis de Gaceno" },
  { department: "Casanare", city: "Paz de Ariporo" },
  { department: "Cauca", city: "Páez" },
  { department: "Cauca", city: "Popayán" },
  { department: "Cauca", city: "Almaguer" },
  { department: "Cauca", city: "Argelia" },
  { department: "Cauca", city: "Balboa" },
  { department: "Cauca", city: "Bolívar" },
  { department: "Cauca", city: "Buenos Aires" },
  { department: "Cauca", city: "Cajibío" },
  { department: "Cauca", city: "Caldono" },
  { department: "Cauca", city: "Caloto" },
  { department: "Cauca", city: "Corinto" },
  { department: "Cauca", city: "El Tambo" },
  { department: "Cauca", city: "Florencia" },
  { department: "Cauca", city: "Guachené" },
  { department: "Cauca", city: "Guapi" },
  { department: "Cauca", city: "Inzá" },
  { department: "Cauca", city: "Jambaló" },
  { department: "Cauca", city: "La Sierra" },
  { department: "Cauca", city: "La Vega" },
  { department: "Cauca", city: "López" },
  { department: "Cauca", city: "Mercaderes" },
  { department: "Cauca", city: "Miranda" },
  { department: "Cauca", city: "Morales" },
  { department: "Cauca", city: "Padilla" },
  { department: "Cauca", city: "Patía" },
  { department: "Cauca", city: "Piamonte" },
  { department: "Cauca", city: "Piendamó" },
  { department: "Cauca", city: "Puerto Tejada" },
  { department: "Cauca", city: "Puracé" },
  { department: "Cauca", city: "Rosas" },
  { department: "Cauca", city: "Santa Rosa" },
  { department: "Cauca", city: "Silvia" },
  { department: "Cauca", city: "Sotara" },
  { department: "Cauca", city: "Suárez" },
  { department: "Cauca", city: "Sucre" },
  { department: "Cauca", city: "Timbío" },
  { department: "Cauca", city: "Timbiquí" },
  { department: "Cauca", city: "Toribio" },
  { department: "Cauca", city: "Totoró" },
  { department: "Cauca", city: "Villa Rica" },
  { department: "Cauca", city: "Santander de Quilichao" },
  { department: "Cauca", city: "San Sebastián" },
  { department: "Cesar", city: "Aguachica" },
  { department: "Cesar", city: "Agustín Codazzi" },
  { department: "Cesar", city: "Astrea" },
  { department: "Cesar", city: "Becerril" },
  { department: "Cesar", city: "Bosconia" },
  { department: "Cesar", city: "Chimichagua" },
  { department: "Cesar", city: "Chiriguaná" },
  { department: "Cesar", city: "Curumaní" },
  { department: "Cesar", city: "El Copey" },
  { department: "Cesar", city: "El Paso" },
  { department: "Cesar", city: "Gamarra" },
  { department: "Cesar", city: "González" },
  { department: "Cesar", city: "La Gloria" },
  { department: "Cesar", city: "Manaure" },
  { department: "Cesar", city: "Pailitas" },
  { department: "Cesar", city: "Pelaya" },
  { department: "Cesar", city: "Pueblo Bello" },
  { department: "Cesar", city: "La Paz" },
  { department: "Cesar", city: "San Alberto" },
  { department: "Cesar", city: "San Diego" },
  { department: "Cesar", city: "San Martín" },
  { department: "Cesar", city: "Tamalameque" },
  { department: "Cesar", city: "Río de Oro" },
  { department: "Cesar", city: "La Jagua de Ibirico" },
  { department: "Chocó", city: "Carmen del Darien" },
  { department: "Chocó", city: "Tadó" },
  { department: "Chocó", city: "Quibdó" },
  { department: "Chocó", city: "Acandí" },
  { department: "Chocó", city: "Alto Baudo" },
  { department: "Chocó", city: "Atrato" },
  { department: "Chocó", city: "Bagadó" },
  { department: "Chocó", city: "Bahía Solano" },
  { department: "Chocó", city: "Bajo Baudó" },
  { department: "Chocó", city: "Bojaya" },
  { department: "Chocó", city: "Unión Panamericana" },
  { department: "Chocó", city: "Cértegui" },
  { department: "Chocó", city: "Condoto" },
  { department: "Chocó", city: "Juradó" },
  { department: "Chocó", city: "Lloró" },
  { department: "Chocó", city: "Medio Atrato" },
  { department: "Chocó", city: "Medio Baudó" },
  { department: "Chocó", city: "Medio San Juan" },
  { department: "Chocó", city: "Nóvita" },
  { department: "Chocó", city: "Nuquí" },
  { department: "Chocó", city: "Río Iro" },
  { department: "Chocó", city: "Río Quito" },
  { department: "Chocó", city: "Riosucio" },
  { department: "Chocó", city: "Sipí" },
  { department: "Chocó", city: "Unguía" },
  { department: "Chocó", city: "Istmina" },
  { department: "Chocó", city: "El Litoral del San Juan" },
  { department: "Chocó", city: "El Cantón del San Pablo" },
  { department: "Chocó", city: "El Carmen de Atrato" },
  { department: "Chocó", city: "San José del Palmar" },
  { department: "Chocó", city: "Belén de Bajira" },
  { department: "Córdoba", city: "Montelíbano" },
  { department: "Córdoba", city: "Ayapel" },
  { department: "Córdoba", city: "Buenavista" },
  { department: "Córdoba", city: "Canalete" },
  { department: "Córdoba", city: "Cereté" },
  { department: "Córdoba", city: "Chimá" },
  { department: "Córdoba", city: "Chinú" },
  { department: "Córdoba", city: "Cotorra" },
  { department: "Córdoba", city: "Lorica" },
  { department: "Córdoba", city: "Los Córdobas" },
  { department: "Córdoba", city: "Momil" },
  { department: "Córdoba", city: "Moñitos" },
  { department: "Córdoba", city: "Planeta Rica" },
  { department: "Córdoba", city: "Pueblo Nuevo" },
  { department: "Córdoba", city: "Puerto Escondido" },
  { department: "Córdoba", city: "Purísima" },
  { department: "Córdoba", city: "Sahagún" },
  { department: "Córdoba", city: "San Andrés Sotavento" },
  { department: "Córdoba", city: "San Antero" },
  { department: "Córdoba", city: "San Pelayo" },
  { department: "Córdoba", city: "Tierralta" },
  { department: "Córdoba", city: "Tuchín" },
  { department: "Córdoba", city: "Valencia" },
  { department: "Córdoba", city: "La Apartada" },
  { department: "Córdoba", city: "Puerto Libertador" },
  { department: "Córdoba", city: "San Bernardo del Viento" },
  { department: "Córdoba", city: "San José de Uré" },
  { department: "Córdoba", city: "Ciénaga de Oro" },
  { department: "Córdoba", city: "San Carlos" },
  { department: "Cundinamarca", city: "Albán" },
  { department: "Cundinamarca", city: "Anolaima" },
  { department: "Cundinamarca", city: "Chía" },
  { department: "Cundinamarca", city: "El Peñón" },
  { department: "Cundinamarca", city: "Sopó" },
  { department: "Cundinamarca", city: "Gama" },
  { department: "Cundinamarca", city: "Sasaima" },
  { department: "Cundinamarca", city: "Yacopí" },
  { department: "Cundinamarca", city: "Anapoima" },
  { department: "Cundinamarca", city: "Arbeláez" },
  { department: "Cundinamarca", city: "Beltrán" },
  { department: "Cundinamarca", city: "Bituima" },
  { department: "Cundinamarca", city: "Bojacá" },
  { department: "Cundinamarca", city: "Cabrera" },
  { department: "Cundinamarca", city: "Cachipay" },
  { department: "Cundinamarca", city: "Cajicá" },
  { department: "Cundinamarca", city: "Caparrapí" },
  { department: "Cundinamarca", city: "Caqueza" },
  { department: "Cundinamarca", city: "Chaguaní" },
  { department: "Cundinamarca", city: "Chipaque" },
  { department: "Cundinamarca", city: "Choachí" },
  { department: "Cundinamarca", city: "Chocontá" },
  { department: "Cundinamarca", city: "Cogua" },
  { department: "Cundinamarca", city: "Cota" },
  { department: "Cundinamarca", city: "Cucunubá" },
  { department: "Cundinamarca", city: "El Colegio" },
  { department: "Cundinamarca", city: "El Rosal" },
  { department: "Cundinamarca", city: "Fomeque" },
  { department: "Cundinamarca", city: "Fosca" },
  { department: "Cundinamarca", city: "Funza" },
  { department: "Cundinamarca", city: "Fúquene" },
  { department: "Cundinamarca", city: "Gachala" },
  { department: "Cundinamarca", city: "Gachancipá" },
  { department: "Cundinamarca", city: "Gachetá" },
  { department: "Cundinamarca", city: "Girardot" },
  { department: "Cundinamarca", city: "Granada" },
  { department: "Cundinamarca", city: "Guachetá" },
  { department: "Cundinamarca", city: "Guaduas" },
  { department: "Cundinamarca", city: "Guasca" },
  { department: "Cundinamarca", city: "Guataquí" },
  { department: "Cundinamarca", city: "Guatavita" },
  { department: "Cundinamarca", city: "Fusagasugá" },
  { department: "Cundinamarca", city: "Guayabetal" },
  { department: "Cundinamarca", city: "Gutiérrez" },
  { department: "Cundinamarca", city: "Jerusalén" },
  { department: "Cundinamarca", city: "Junín" },
  { department: "Cundinamarca", city: "La Calera" },
  { department: "Cundinamarca", city: "La Mesa" },
  { department: "Cundinamarca", city: "La Palma" },
  { department: "Cundinamarca", city: "La Peña" },
  { department: "Cundinamarca", city: "La Vega" },
  { department: "Cundinamarca", city: "Lenguazaque" },
  { department: "Cundinamarca", city: "Macheta" },
  { department: "Cundinamarca", city: "Madrid" },
  { department: "Cundinamarca", city: "Manta" },
  { department: "Cundinamarca", city: "Medina" },
  { department: "Cundinamarca", city: "Mosquera" },
  { department: "Cundinamarca", city: "Nariño" },
  { department: "Cundinamarca", city: "Nemocón" },
  { department: "Cundinamarca", city: "Nilo" },
  { department: "Cundinamarca", city: "Nimaima" },
  { department: "Cundinamarca", city: "Nocaima" },
  { department: "Cundinamarca", city: "Venecia" },
  { department: "Cundinamarca", city: "Pacho" },
  { department: "Cundinamarca", city: "Paime" },
  { department: "Cundinamarca", city: "Pandi" },
  { department: "Cundinamarca", city: "Paratebueno" },
  { department: "Cundinamarca", city: "Pasca" },
  { department: "Cundinamarca", city: "Puerto Salgar" },
  { department: "Cundinamarca", city: "Pulí" },
  { department: "Cundinamarca", city: "Quebradanegra" },
  { department: "Cundinamarca", city: "Quetame" },
  { department: "Cundinamarca", city: "Quipile" },
  { department: "Cundinamarca", city: "Apulo" },
  { department: "Cundinamarca", city: "Ricaurte" },
  { department: "Cundinamarca", city: "San Bernardo" },
  { department: "Cundinamarca", city: "San Cayetano" },
  { department: "Cundinamarca", city: "San Francisco" },
  { department: "Cundinamarca", city: "Zipaquirá" },
  { department: "Cundinamarca", city: "Sesquilé" },
  { department: "Cundinamarca", city: "Sibaté" },
  { department: "Cundinamarca", city: "Silvania" },
  { department: "Cundinamarca", city: "Simijaca" },
  { department: "Cundinamarca", city: "Subachoque" },
  { department: "Cundinamarca", city: "Suesca" },
  { department: "Cundinamarca", city: "Supatá" },
  { department: "Cundinamarca", city: "Susa" },
  { department: "Cundinamarca", city: "Sutatausa" },
  { department: "Cundinamarca", city: "Tabio" },
  { department: "Cundinamarca", city: "Tausa" },
  { department: "Cundinamarca", city: "Tena" },
  { department: "Cundinamarca", city: "Tenjo" },
  { department: "Cundinamarca", city: "Tibacuy" },
  { department: "Cundinamarca", city: "Tibirita" },
  { department: "Cundinamarca", city: "Tocaima" },
  { department: "Cundinamarca", city: "Tocancipá" },
  { department: "Cundinamarca", city: "Topaipí" },
  { department: "Cundinamarca", city: "Ubalá" },
  { department: "Cundinamarca", city: "Ubaque" },
  { department: "Cundinamarca", city: "Une" },
  { department: "Cundinamarca", city: "Útica" },
  { department: "Cundinamarca", city: "Vianí" },
  { department: "Cundinamarca", city: "Villagómez" },
  { department: "Cundinamarca", city: "Villapinzón" },
  { department: "Cundinamarca", city: "Villeta" },
  { department: "Cundinamarca", city: "Viotá" },
  { department: "Cundinamarca", city: "Zipacón" },
  { department: "Cundinamarca", city: "Facatativá" },
  { department: "Cundinamarca", city: "San Juan de Río Seco" },
  { department: "Cundinamarca", city: "Villa de San Diego de Ubate" },
  { department: "Cundinamarca", city: "Guayabal de Siquima" },
  { department: "Cundinamarca", city: "San Antonio del Tequendama" },
  { department: "Cundinamarca", city: "Agua de Dios" },
  { department: "Cundinamarca", city: "Carmen de Carupa" },
  { department: "Cundinamarca", city: "Vergara" },
  { department: "Guainía", city: "Inírida" },
  { department: "Guainía", city: "Barranco Minas" },
  { department: "Guainía", city: "Mapiripana" },
  { department: "Guainía", city: "San Felipe" },
  { department: "Guainía", city: "Puerto Colombia" },
  { department: "Guainía", city: "La Guadalupe" },
  { department: "Guainía", city: "Cacahual" },
  { department: "Guainía", city: "Pana Pana" },
  { department: "Guainía", city: "Morichal" },
  { department: "Guaviare", city: "Calamar" },
  { department: "Guaviare", city: "San José del Guaviare" },
  { department: "Guaviare", city: "Miraflores" },
  { department: "Guaviare", city: "El Retorno" },
  { department: "Huila", city: "Acevedo" },
  { department: "Huila", city: "Agrado" },
  { department: "Huila", city: "Aipe" },
  { department: "Huila", city: "Algeciras" },
  { department: "Huila", city: "Altamira" },
  { department: "Huila", city: "Baraya" },
  { department: "Huila", city: "Campoalegre" },
  { department: "Huila", city: "Colombia" },
  { department: "Huila", city: "Elías" },
  { department: "Huila", city: "Garzón" },
  { department: "Huila", city: "Gigante" },
  { department: "Huila", city: "Guadalupe" },
  { department: "Huila", city: "Hobo" },
  { department: "Huila", city: "Iquira" },
  { department: "Huila", city: "Isnos" },
  { department: "Huila", city: "La Argentina" },
  { department: "Huila", city: "La Plata" },
  { department: "Huila", city: "Nátaga" },
  { department: "Huila", city: "Oporapa" },
  { department: "Huila", city: "Paicol" },
  { department: "Huila", city: "Palermo" },
  { department: "Huila", city: "Palestina" },
  { department: "Huila", city: "Pital" },
  { department: "Huila", city: "Pitalito" },
  { department: "Huila", city: "Rivera" },
  { department: "Huila", city: "Saladoblanco" },
  { department: "Huila", city: "Santa María" },
  { department: "Huila", city: "Suaza" },
  { department: "Huila", city: "Tarqui" },
  { department: "Huila", city: "Tesalia" },
  { department: "Huila", city: "Tello" },
  { department: "Huila", city: "Teruel" },
  { department: "Huila", city: "Timaná" },
  { department: "Huila", city: "Villavieja" },
  { department: "Huila", city: "Yaguará" },
  { department: "Huila", city: "San Agustín" },
  { department: "La Guajira", city: "Riohacha" },
  { department: "La Guajira", city: "Albania" },
  { department: "La Guajira", city: "Barrancas" },
  { department: "La Guajira", city: "Dibula" },
  { department: "La Guajira", city: "Distracción" },
  { department: "La Guajira", city: "El Molino" },
  { department: "La Guajira", city: "Fonseca" },
  { department: "La Guajira", city: "Hatonuevo" },
  { department: "La Guajira", city: "Maicao" },
  { department: "La Guajira", city: "Manaure" },
  { department: "La Guajira", city: "Uribia" },
  { department: "La Guajira", city: "Urumita" },
  { department: "La Guajira", city: "Villanueva" },
  { department: "La Guajira", city: "La Jagua del Pilar" },
  { department: "La Guajira", city: "San Juan del Cesar" },
  { department: "Magdalena", city: "Santa Bárbara de Pinto" },
  { department: "Magdalena", city: "Pueblo Viejo" },
  { department: "Magdalena", city: "Algarrobo" },
  { department: "Magdalena", city: "Aracataca" },
  { department: "Magdalena", city: "Ariguaní" },
  { department: "Magdalena", city: "Cerro San Antonio" },
  { department: "Magdalena", city: "Chivolo" },
  { department: "Magdalena", city: "Concordia" },
  { department: "Magdalena", city: "El Banco" },
  { department: "Magdalena", city: "El Piñon" },
  { department: "Magdalena", city: "El Retén" },
  { department: "Magdalena", city: "Fundación" },
  { department: "Magdalena", city: "Guamal" },
  { department: "Magdalena", city: "Nueva Granada" },
  { department: "Magdalena", city: "Pedraza" },
  { department: "Magdalena", city: "Pivijay" },
  { department: "Magdalena", city: "Plato" },
  { department: "Magdalena", city: "Remolino" },
  { department: "Magdalena", city: "Salamina" },
  { department: "Magdalena", city: "San Zenón" },
  { department: "Magdalena", city: "Santa Ana" },
  { department: "Magdalena", city: "Sitionuevo" },
  { department: "Magdalena", city: "Tenerife" },
  { department: "Magdalena", city: "Zapayán" },
  { department: "Magdalena", city: "Zona Bananera" },
  { department: "Magdalena", city: "Ciénaga" },
  { department: "Magdalena", city: "San Sebastián de Buenavista" },
  { department: "Magdalena", city: "Sabanas de San Angel" },
  { department: "Magdalena", city: "Pijiño del Carmen" },
  { department: "Meta", city: "Castilla la Nueva" },
  { department: "Meta", city: "Acacias" },
  { department: "Meta", city: "Cabuyaro" },
  { department: "Meta", city: "Cubarral" },
  { department: "Meta", city: "Cumaral" },
  { department: "Meta", city: "El Calvario" },
  { department: "Meta", city: "El Castillo" },
  { department: "Meta", city: "El Dorado" },
  { department: "Meta", city: "Granada" },
  { department: "Meta", city: "Guamal" },
  { department: "Meta", city: "Mapiripán" },
  { department: "Meta", city: "Mesetas" },
  { department: "Meta", city: "La Macarena" },
  { department: "Meta", city: "Uribe" },
  { department: "Meta", city: "Lejanías" },
  { department: "Meta", city: "Puerto Concordia" },
  { department: "Meta", city: "Puerto Gaitán" },
  { department: "Meta", city: "Puerto López" },
  { department: "Meta", city: "Puerto Lleras" },
  { department: "Meta", city: "Puerto Rico" },
  { department: "Meta", city: "Restrepo" },
  { department: "Meta", city: "San Juanito" },
  { department: "Meta", city: "San Martín" },
  { department: "Meta", city: "Vista Hermosa" },
  { department: "Meta", city: "Barranca de Upía" },
  { department: "Meta", city: "Fuente de Oro" },
  { department: "Meta", city: "San Carlos de Guaroa" },
  { department: "Meta", city: "San Juan de Arama" },
  { department: "Nariño", city: "Buesaco" },
  { department: "Nariño", city: "San Andrés de Tumaco" },
  { department: "Nariño", city: "Belén" },
  { department: "Nariño", city: "Chachagüí" },
  { department: "Nariño", city: "Arboleda" },
  { department: "Nariño", city: "Albán" },
  { department: "Nariño", city: "Aldana" },
  { department: "Nariño", city: "Ancuyá" },
  { department: "Nariño", city: "Barbacoas" },
  { department: "Nariño", city: "Colón" },
  { department: "Nariño", city: "Consaca" },
  { department: "Nariño", city: "Contadero" },
  { department: "Nariño", city: "Córdoba" },
  { department: "Nariño", city: "Cuaspud" },
  { department: "Nariño", city: "Cumbal" },
  { department: "Nariño", city: "Cumbitara" },
  { department: "Nariño", city: "El Charco" },
  { department: "Nariño", city: "El Peñol" },
  { department: "Nariño", city: "El Rosario" },
  { department: "Nariño", city: "El Tambo" },
  { department: "Nariño", city: "Funes" },
  { department: "Nariño", city: "Guachucal" },
  { department: "Nariño", city: "Guaitarilla" },
  { department: "Nariño", city: "Gualmatán" },
  { department: "Nariño", city: "Iles" },
  { department: "Nariño", city: "Imués" },
  { department: "Nariño", city: "Ipiales" },
  { department: "Nariño", city: "La Cruz" },
  { department: "Nariño", city: "La Florida" },
  { department: "Nariño", city: "La Llanada" },
  { department: "Nariño", city: "La Tola" },
  { department: "Nariño", city: "La Unión" },
  { department: "Nariño", city: "Leiva" },
  { department: "Nariño", city: "Linares" },
  { department: "Nariño", city: "Los Andes" },
  { department: "Nariño", city: "Magüí" },
  { department: "Nariño", city: "Mallama" },
  { department: "Nariño", city: "Mosquera" },
  { department: "Nariño", city: "Nariño" },
  { department: "Nariño", city: "Olaya Herrera" },
  { department: "Nariño", city: "Ospina" },
  { department: "Nariño", city: "Francisco Pizarro" },
  { department: "Nariño", city: "Policarpa" },
  { department: "Nariño", city: "Potosí" },
  { department: "Nariño", city: "Providencia" },
  { department: "Nariño", city: "Puerres" },
  { department: "Nariño", city: "Pupiales" },
  { department: "Nariño", city: "Ricaurte" },
  { department: "Nariño", city: "Roberto Payán" },
  { department: "Nariño", city: "Samaniego" },
  { department: "Nariño", city: "Sandoná" },
  { department: "Nariño", city: "San Bernardo" },
  { department: "Nariño", city: "San Lorenzo" },
  { department: "Nariño", city: "San Pablo" },
  { department: "Nariño", city: "Santa Bárbara" },
  { department: "Nariño", city: "Sapuyes" },
  { department: "Nariño", city: "Taminango" },
  { department: "Nariño", city: "Tangua" },
  { department: "Nariño", city: "Santacruz" },
  { department: "Nariño", city: "Túquerres" },
  { department: "Nariño", city: "Yacuanquer" },
  { department: "Nariño", city: "San Pedro de Cartago" },
  { department: "Nariño", city: "El Tablón de Gómez" },
  { department: "Norte de Santander", city: "Pamplona" },
  { department: "Norte de Santander", city: "Pamplonita" },
  { department: "Norte de Santander", city: "El Carmen" },
  { department: "Norte de Santander", city: "Silos" },
  { department: "Norte de Santander", city: "Cácota" },
  { department: "Norte de Santander", city: "Toledo" },
  { department: "Norte de Santander", city: "Mutiscua" },
  { department: "Norte de Santander", city: "El Zulia" },
  { department: "Norte de Santander", city: "Salazar" },
  { department: "Norte de Santander", city: "Cucutilla" },
  { department: "Norte de Santander", city: "Puerto Santander" },
  { department: "Norte de Santander", city: "Gramalote" },
  { department: "Norte de Santander", city: "El Tarra" },
  { department: "Norte de Santander", city: "Teorama" },
  { department: "Norte de Santander", city: "Arboledas" },
  { department: "Norte de Santander", city: "Lourdes" },
  { department: "Norte de Santander", city: "Bochalema" },
  { department: "Norte de Santander", city: "Convención" },
  { department: "Norte de Santander", city: "Hacarí" },
  { department: "Norte de Santander", city: "Herrán" },
  { department: "Norte de Santander", city: "Tibú" },
  { department: "Norte de Santander", city: "San Cayetano" },
  { department: "Norte de Santander", city: "San Calixto" },
  { department: "Norte de Santander", city: "La Playa" },
  { department: "Norte de Santander", city: "Chinácota" },
  { department: "Norte de Santander", city: "Ragonvalia" },
  { department: "Norte de Santander", city: "La Esperanza" },
  { department: "Norte de Santander", city: "Villa del Rosario" },
  { department: "Norte de Santander", city: "Chitagá" },
  { department: "Norte de Santander", city: "Sardinata" },
  { department: "Norte de Santander", city: "Abrego" },
  { department: "Norte de Santander", city: "Los Patios" },
  { department: "Norte de Santander", city: "Ocaña" },
  { department: "Norte de Santander", city: "Bucarasica" },
  { department: "Norte de Santander", city: "Santiago" },
  { department: "Norte de Santander", city: "Labateca" },
  { department: "Norte de Santander", city: "Cachirá" },
  { department: "Norte de Santander", city: "Villa Caro" },
  { department: "Norte de Santander", city: "Durania" },
  { department: "Putumayo", city: "Puerto Asís" },
  { department: "Putumayo", city: "Villagarzón" },
  { department: "Putumayo", city: "Mocoa" },
  { department: "Putumayo", city: "Colón" },
  { department: "Putumayo", city: "Orito" },
  { department: "Putumayo", city: "Puerto Caicedo" },
  { department: "Putumayo", city: "Puerto Guzmán" },
  { department: "Putumayo", city: "Leguízamo" },
  { department: "Putumayo", city: "Sibundoy" },
  { department: "Putumayo", city: "San Francisco" },
  { department: "Putumayo", city: "San Miguel" },
  { department: "Putumayo", city: "Santiago" },
  { department: "Putumayo", city: "Valle de Guamez" },
  { department: "Quindío", city: "Calarcá" },
  { department: "Quindío", city: "Génova" },
  { department: "Quindío", city: "Armenia" },
  { department: "Quindío", city: "Buenavista" },
  { department: "Quindío", city: "Circasia" },
  { department: "Quindío", city: "Córdoba" },
  { department: "Quindío", city: "Filandia" },
  { department: "Quindío", city: "La Tebaida" },
  { department: "Quindío", city: "Montenegro" },
  { department: "Quindío", city: "Pijao" },
  { department: "Quindío", city: "Quimbaya" },
  { department: "Quindío", city: "Salento" },
  { department: "Risaralda", city: "Apía" },
  { department: "Risaralda", city: "Balboa" },
  { department: "Risaralda", city: "Dosquebradas" },
  { department: "Risaralda", city: "Guática" },
  { department: "Risaralda", city: "La Celia" },
  { department: "Risaralda", city: "La Virginia" },
  { department: "Risaralda", city: "Marsella" },
  { department: "Risaralda", city: "Mistrató" },
  { department: "Risaralda", city: "Pueblo Rico" },
  { department: "Risaralda", city: "Quinchía" },
  { department: "Risaralda", city: "Santuario" },
  { department: "Risaralda", city: "Santa Rosa de Cabal" },
  { department: "Risaralda", city: "Belén de Umbría" },
  { department: "Santander", city: "Chimá" },
  { department: "Santander", city: "Capitanejo" },
  { department: "Santander", city: "El Peñón" },
  { department: "Santander", city: "Puerto Wilches" },
  { department: "Santander", city: "Puerto Parra" },
  { department: "Santander", city: "Aguada" },
  { department: "Santander", city: "Albania" },
  { department: "Santander", city: "Aratoca" },
  { department: "Santander", city: "Barbosa" },
  { department: "Santander", city: "Barichara" },
  { department: "Santander", city: "Barrancabermeja" },
  { department: "Santander", city: "Betulia" },
  { department: "Santander", city: "Bolívar" },
  { department: "Santander", city: "Cabrera" },
  { department: "Santander", city: "California" },
  { department: "Santander", city: "Carcasí" },
  { department: "Santander", city: "Cepitá" },
  { department: "Santander", city: "Cerrito" },
  { department: "Santander", city: "Charalá" },
  { department: "Santander", city: "Charta" },
  { department: "Santander", city: "Chipatá" },
  { department: "Santander", city: "Cimitarra" },
  { department: "Santander", city: "Concepción" },
  { department: "Santander", city: "Confines" },
  { department: "Santander", city: "Contratación" },
  { department: "Santander", city: "Coromoro" },
  { department: "Santander", city: "Curití" },
  { department: "Santander", city: "El Guacamayo" },
  { department: "Santander", city: "El Playón" },
  { department: "Santander", city: "Encino" },
  { department: "Santander", city: "Enciso" },
  { department: "Santander", city: "Florián" },
  { department: "Santander", city: "Floridablanca" },
  { department: "Santander", city: "Galán" },
  { department: "Santander", city: "Gambita" },
  { department: "Santander", city: "Girón" },
  { department: "Santander", city: "Guaca" },
  { department: "Santander", city: "Guadalupe" },
  { department: "Santander", city: "Guapotá" },
  { department: "Santander", city: "Guavatá" },
  { department: "Santander", city: "Güepsa" },
  { department: "Santander", city: "Jesús María" },
  { department: "Santander", city: "Jordán" },
  { department: "Santander", city: "La Belleza" },
  { department: "Santander", city: "Landázuri" },
  { department: "Santander", city: "La Paz" },
  { department: "Santander", city: "Lebríja" },
  { department: "Santander", city: "Los Santos" },
  { department: "Santander", city: "Macaravita" },
  { department: "Santander", city: "Málaga" },
  { department: "Santander", city: "Matanza" },
  { department: "Santander", city: "Mogotes" },
  { department: "Santander", city: "Molagavita" },
  { department: "Santander", city: "Ocamonte" },
  { department: "Santander", city: "Oiba" },
  { department: "Santander", city: "Onzaga" },
  { department: "Santander", city: "Palmar" },
  { department: "Santander", city: "Páramo" },
  { department: "Santander", city: "Piedecuesta" },
  { department: "Santander", city: "Pinchote" },
  { department: "Santander", city: "Puente Nacional" },
  { department: "Santander", city: "Rionegro" },
  { department: "Santander", city: "San Andrés" },
  { department: "Santander", city: "San Gil" },
  { department: "Santander", city: "San Joaquín" },
  { department: "Santander", city: "San Miguel" },
  { department: "Santander", city: "Santa Bárbara" },
  { department: "Santander", city: "Simacota" },
  { department: "Santander", city: "Socorro" },
  { department: "Santander", city: "Suaita" },
  { department: "Santander", city: "Sucre" },
  { department: "Santander", city: "Suratá" },
  { department: "Santander", city: "Tona" },
  { department: "Santander", city: "Vélez" },
  { department: "Santander", city: "Vetas" },
  { department: "Santander", city: "Villanueva" },
  { department: "Santander", city: "Zapatoca" },
  { department: "Santander", city: "Palmas del Socorro" },
  { department: "Santander", city: "San Vicente de Chucurí" },
  { department: "Santander", city: "San José de Miranda" },
  { department: "Santander", city: "Santa Helena del Opón" },
  { department: "Santander", city: "Sabana de Torres" },
  { department: "Santander", city: "El Carmen de Chucurí" },
  { department: "Santander", city: "Valle de San José" },
  { department: "Santander", city: "San Benito" },
  { department: "Santander", city: "Hato" },
  { department: "Sucre", city: "Sampués" },
  { department: "Sucre", city: "Corozal" },
  { department: "Sucre", city: "Sincelejo" },
  { department: "Sucre", city: "Buenavista" },
  { department: "Sucre", city: "Caimito" },
  { department: "Sucre", city: "Coloso" },
  { department: "Sucre", city: "Coveñas" },
  { department: "Sucre", city: "Chalán" },
  { department: "Sucre", city: "El Roble" },
  { department: "Sucre", city: "Galeras" },
  { department: "Sucre", city: "Guaranda" },
  { department: "Sucre", city: "La Unión" },
  { department: "Sucre", city: "Los Palmitos" },
  { department: "Sucre", city: "Majagual" },
  { department: "Sucre", city: "Morroa" },
  { department: "Sucre", city: "Ovejas" },
  { department: "Sucre", city: "Palmito" },
  { department: "Sucre", city: "San Benito Abad" },
  { department: "Sucre", city: "San Marcos" },
  { department: "Sucre", city: "San Onofre" },
  { department: "Sucre", city: "San Pedro" },
  { department: "Sucre", city: "Sucre" },
  { department: "Sucre", city: "Tolú Viejo" },
  { department: "Sucre", city: "San Luis de Sincé" },
  { department: "Sucre", city: "San Juan de Betulia" },
  { department: "Sucre", city: "Santiago de Tolú" },
  { department: "Tolima", city: "Casabianca" },
  { department: "Tolima", city: "Anzoátegui" },
  { department: "Tolima", city: "Líbano" },
  { department: "Tolima", city: "Lérida" },
  { department: "Tolima", city: "Suárez" },
  { department: "Tolima", city: "Alpujarra" },
  { department: "Tolima", city: "Alvarado" },
  { department: "Tolima", city: "Ambalema" },
  { department: "Tolima", city: "Armero" },
  { department: "Tolima", city: "Ataco" },
  { department: "Tolima", city: "Cajamarca" },
  { department: "Tolima", city: "Chaparral" },
  { department: "Tolima", city: "Coello" },
  { department: "Tolima", city: "Coyaima" },
  { department: "Tolima", city: "Cunday" },
  { department: "Tolima", city: "Dolores" },
  { department: "Tolima", city: "Espinal" },
  { department: "Tolima", city: "Falan" },
  { department: "Tolima", city: "Flandes" },
  { department: "Tolima", city: "Fresno" },
  { department: "Tolima", city: "Guamo" },
  { department: "Tolima", city: "Herveo" },
  { department: "Tolima", city: "Honda" },
  { department: "Tolima", city: "Icononzo" },
  { department: "Tolima", city: "Mariquita" },
  { department: "Tolima", city: "Melgar" },
  { department: "Tolima", city: "Murillo" },
  { department: "Tolima", city: "Natagaima" },
  { department: "Tolima", city: "Ortega" },
  { department: "Tolima", city: "Palocabildo" },
  { department: "Tolima", city: "Piedras" },
  { department: "Tolima", city: "Planadas" },
  { department: "Tolima", city: "Prado" },
  { department: "Tolima", city: "Purificación" },
  { department: "Tolima", city: "Rio Blanco" },
  { department: "Tolima", city: "Roncesvalles" },
  { department: "Tolima", city: "Rovira" },
  { department: "Tolima", city: "Saldaña" },
  { department: "Tolima", city: "Santa Isabel" },
  { department: "Tolima", city: "Venadillo" },
  { department: "Tolima", city: "Villahermosa" },
  { department: "Tolima", city: "Villarrica" },
  { department: "Tolima", city: "Valle de San Juan" },
  { department: "Tolima", city: "Carmen de Apicala" },
  { department: "Tolima", city: "San Luis" },
  { department: "Tolima", city: "San Antonio" },
  { department: "Valle del Cauca", city: "Tuluá" },
  { department: "Valle del Cauca", city: "Florida" },
  { department: "Valle del Cauca", city: "Jamundí" },
  { department: "Valle del Cauca", city: "El Dovio" },
  { department: "Valle del Cauca", city: "Roldanillo" },
  { department: "Valle del Cauca", city: "Argelia" },
  { department: "Valle del Cauca", city: "Sevilla" },
  { department: "Valle del Cauca", city: "Zarzal" },
  { department: "Valle del Cauca", city: "El Cerrito" },
  { department: "Valle del Cauca", city: "Cartago" },
  { department: "Valle del Cauca", city: "Caicedonia" },
  { department: "Valle del Cauca", city: "El Cairo" },
  { department: "Valle del Cauca", city: "La Unión" },
  { department: "Valle del Cauca", city: "Restrepo" },
  { department: "Valle del Cauca", city: "Dagua" },
  { department: "Valle del Cauca", city: "Guacarí" },
  { department: "Valle del Cauca", city: "Ansermanuevo" },
  { department: "Valle del Cauca", city: "Bugalagrande" },
  { department: "Valle del Cauca", city: "La Victoria" },
  { department: "Valle del Cauca", city: "Ginebra" },
  { department: "Valle del Cauca", city: "Yumbo" },
  { department: "Valle del Cauca", city: "Obando" },
  { department: "Valle del Cauca", city: "Bolívar" },
  { department: "Valle del Cauca", city: "San Pedro" },
  { department: "Valle del Cauca", city: "Guadalajara de Buga" },
  { department: "Valle del Cauca", city: "Calima" },
  { department: "Valle del Cauca", city: "Andalucía" },
  { department: "Valle del Cauca", city: "Pradera" },
  { department: "Valle del Cauca", city: "Yotoco" },
  { department: "Valle del Cauca", city: "Palmira" },
  { department: "Valle del Cauca", city: "Riofrío" },
  { department: "Valle del Cauca", city: "Alcalá" },
  { department: "Valle del Cauca", city: "Versalles" },
  { department: "Valle del Cauca", city: "El Águila" },
  { department: "Valle del Cauca", city: "Toro" },
  { department: "Valle del Cauca", city: "Candelaria" },
  { department: "Valle del Cauca", city: "La Cumbre" },
  { department: "Valle del Cauca", city: "Ulloa" },
  { department: "Valle del Cauca", city: "Trujillo" },
  { department: "Valle del Cauca", city: "Vijes" },
  { department: "Vaupés", city: "Yavaraté" },
  { department: "Vaupés", city: "Mitú" },
  { department: "Vaupés", city: "Caruru" },
  { department: "Vaupés", city: "Pacoa" },
  { department: "Vaupés", city: "Taraira" },
  { department: "Vaupés", city: "Papunaua" },
  { department: "Vichada", city: "Puerto Carreño" },
  { department: "Vichada", city: "La Primavera" },
  { department: "Vichada", city: "Santa Rosalía" },
  { department: "Vichada", city: "Cumaribo" },
];

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  { code: "AE", label: "United Arab Emirates", phone: "971" },
  { code: "AF", label: "Afghanistan", phone: "93" },
  { code: "AG", label: "Antigua and Barbuda", phone: "1-268" },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  { code: "AU", label: "Australia", phone: "61", suggested: true },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  { code: "BA", label: "Bosnia and Herzegovina", phone: "387" },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  { code: "CA", label: "Canada", phone: "1", suggested: true },
  { code: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
  { code: "CD", label: "Congo, Republic of the", phone: "242" },
  { code: "CF", label: "Central African Republic", phone: "236" },
  { code: "CG", label: "Congo, Democratic Republic of the", phone: "243" },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  { code: "DE", label: "Germany", phone: "49", suggested: true },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  { code: "DO", label: "Dominican Republic", phone: "1-809" },
  { code: "DO", label: "Dominican Republic", phone: "1-829" },
  { code: "DO", label: "Dominican Republic", phone: "1-849" },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  { code: "FK", label: "Falkland Islands (Malvinas)", phone: "500" },
  { code: "FM", label: "Micronesia, Federated States of", phone: "691" },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  { code: "FR", label: "France", phone: "33", suggested: true },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  { code: "HM", label: "Heard Island and McDonald Islands", phone: "672" },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  { code: "IO", label: "British Indian Ocean Territory", phone: "246" },
  { code: "IQ", label: "Iraq", phone: "964" },
  { code: "IR", label: "Iran, Islamic Republic of", phone: "98" },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  { code: "JP", label: "Japan", phone: "81", suggested: true },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  { code: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
  { code: "KP", label: "Korea, Democratic People's Republic of", phone: "850" },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  { code: "LA", label: "Lao People's Democratic Republic", phone: "856" },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  { code: "MD", label: "Moldova, Republic of", phone: "373" },
  { code: "ME", label: "Montenegro", phone: "382" },
  { code: "MF", label: "Saint Martin (French part)", phone: "590" },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  { code: "MP", label: "Northern Mariana Islands", phone: "1-670" },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  { code: "PM", label: "Saint Pierre and Miquelon", phone: "508" },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  { code: "PS", label: "Palestine, State of", phone: "970" },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  { code: "ST", label: "Sao Tome and Principe", phone: "239" },
  { code: "SV", label: "El Salvador", phone: "503" },
  { code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721" },
  { code: "SY", label: "Syrian Arab Republic", phone: "963" },
  { code: "SZ", label: "Swaziland", phone: "268" },
  { code: "TC", label: "Turks and Caicos Islands", phone: "1-649" },
  { code: "TD", label: "Chad", phone: "235" },
  { code: "TF", label: "French Southern Territories", phone: "262" },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  { code: "TT", label: "Trinidad and Tobago", phone: "1-868" },
  { code: "TV", label: "Tuvalu", phone: "688" },
  { code: "TW", label: "Taiwan, Province of China", phone: "886" },
  { code: "TZ", label: "United Republic of Tanzania", phone: "255" },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  { code: "US", label: "United States", phone: "1", suggested: true },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  { code: "VA", label: "Holy See (Vatican City State)", phone: "379" },
  { code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784" },
  { code: "VE", label: "Venezuela", phone: "58" },
  { code: "VG", label: "British Virgin Islands", phone: "1-284" },
  { code: "VI", label: "US Virgin Islands", phone: "1-340" },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

/**
 * @param {string} phoneNumber user store phone number
 */
export const getIndicative = (number) => {
  for (const country of countries) {
    const indicativo = country.phone;
    if (number.includes("-") && indicativo === number.substring(0, 5)) {
      return indicativo;
    }

    if (number.startsWith(indicativo)) {
      return indicativo;
    }
  }

  return null;
};
