import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';

/* 
    Mercado Pago Preference
*/
// Agrega credenciales
/* mercadopago.configure({
    access_token: "TEST-605139460208124-060912-a121ba0dc3551d8c710919a6c1ef4f4f-387723734",
}); */


const MercadoPago = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const MP_ACCESS_TOKEN = props?.mpApiKey;
    const location = window.location
    const BASE_URL = `https://${location.host}/checkout/${props.storeId}/${props.refSale}/result`

    // Crea un objeto de preferencia
    let preference = {
        external_reference: props?.refSale,
        notification_url: `${process.env.REACT_APP_API_URL}${props.urlConfirmationMP}`,
        items: [
            {
                title: "Compra en 10X",
                description: `Catalogo de ${props?.nameShop}`,
                unit_price: props?.valueToPay,
                quantity: 1,
                picture_url: props?.logoShop
                    ? (`${process.env.REACT_APP_IMAGES_API_URL}/fit-in/90x90/${props?.logoShop}`)
                    : (`https://ui-avatars.com/api/?name=${props?.nameShop || `10X&length=3`}&size=90&background=266678&color=fff`)
            },
        ],
        payer: {
            name: props?.name,
            email: props?.email,
            identification: {
                number: props?.document,
                type: props?.typeDocument,
            }
        },
        back_urls: {
            success: BASE_URL,
            failure: BASE_URL,
            pending: BASE_URL
        },
        auto_return: "approved",
        statement_descriptor: "Pago en 10X",
        marketplace: `${props.nameShop} - 10X`
    };

    axios.post(`https://api.mercadolibre.com/checkout/preferences?access_token=${MP_ACCESS_TOKEN}`, preference)
        .then(response => {
            props.testMP
                ? window.location.href = response.data.sandbox_init_point
                : window.location.href = response.data.init_point
        })
        .catch(error => {
            console.log(error);
            enqueueSnackbar('Se ha presentado un error, intente más tarde', {
                variant: 'error',
                autoHideDuration: 2500
            });
        });

    return (<></>);
}

export default MercadoPago;
