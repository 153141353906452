import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Trash = props => {
  return (
    <SvgIcon {...props}>
      <path d="M 19.914062 3.148438 L 16.164062 3.148438 C 15.9375 1.386719 14.511719 0.0390625 12.636719 0.0390625 L 11.476562 0.0390625 C 9.601562 0.0390625 8.136719 1.386719 7.949219 3.148438 L 4.085938 3.148438 C 3.261719 3.148438 2.625 3.789062 2.625 4.613281 L 3.5625 20.25 C 3.675781 22.351562 5.4375 24 7.539062 24 L 16.386719 24 C 18.488281 24 20.25 22.425781 20.4375 20.25 L 21.375 4.613281 C 21.375 3.789062 20.738281 3.148438 19.914062 3.148438 Z M 11.476562 2.101562 L 12.636719 2.101562 C 13.273438 2.101562 13.800781 2.511719 13.988281 3.148438 L 10.011719 3.148438 C 10.238281 2.511719 10.761719 2.101562 11.476562 2.101562 Z M 18.335938 20.136719 C 18.226562 21.1875 17.398438 21.898438 16.351562 21.898438 L 7.539062 21.898438 C 6.488281 21.898438 5.664062 21.1875 5.664062 20.136719 L 4.835938 5.25 L 19.3125 5.25 Z M 18.335938 20.136719 "/>
      <path d="M 14.101562 10.425781 L 14.101562 15.636719 C 14.101562 16.273438 14.511719 16.6875 15.148438 16.6875 C 15.789062 16.6875 16.199219 16.273438 16.199219 15.636719 L 16.199219 10.425781 C 16.199219 9.789062 15.789062 9.375 15.148438 9.375 C 14.511719 9.375 14.101562 9.824219 14.101562 10.425781 Z M 14.101562 10.425781 "/>
      <path d="M 8.886719 9.414062 C 8.25 9.414062 7.835938 9.824219 7.835938 10.460938 L 7.835938 15.675781 C 7.835938 16.3125 8.25 16.726562 8.886719 16.726562 C 9.523438 16.726562 9.9375 16.3125 9.9375 15.675781 L 9.9375 10.425781 C 9.898438 9.824219 9.488281 9.414062 8.886719 9.414062 Z M 8.886719 9.414062 "/>
      <path d="M 12 8.363281 C 11.363281 8.363281 10.949219 8.773438 10.949219 9.414062 L 10.949219 16.6875 C 10.949219 17.324219 11.363281 17.738281 12 17.738281 C 12.636719 17.738281 13.050781 17.324219 13.050781 16.6875 L 13.050781 9.414062 C 13.050781 8.773438 12.636719 8.363281 12 8.363281 Z M 12 8.363281 "/>
    </SvgIcon>
  )
}

export default Trash
