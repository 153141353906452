import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from './theme';
import '../index.css';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_QL_URL
});

const ProviderWrapper = ({ children, store, history }) => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <ConnectedRouter history={history}>
            <SnackbarProvider maxSnack={3}>
              <CssBaseline />
              { children }
            </SnackbarProvider>
          </ConnectedRouter>
        </CookiesProvider>
      </ThemeProvider>
    </ApolloProvider>
  </Provider>
)

export default ProviderWrapper