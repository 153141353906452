import React from 'react'
import classNames from 'classnames'

import GalleryStyles from './Gallery.module.scss'

const Gallery = ({ children, fadeCollection, isInactive }) => {
  const galleryListClass = classNames({
    [GalleryStyles.list]: true,
    [GalleryStyles.fade]: fadeCollection
  })

  return (
    <section style={isInactive ? {
      opacity: 0.5,
      pointerEvents: 'none'
    } : {}}
      className={GalleryStyles.wrapper}>
      <div className={galleryListClass}>
        {children}
      </div>
    </section>
  )
}

export default Gallery
